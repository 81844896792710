<div class="not-found">
    <h1>Oops! Page Not Found</h1>
    <p>We couldn't find the page you were looking for. It might have been moved or deleted.</p>
    
    <p>Here are some helpful links:</p>
    <ul>
      <li><a routerLink="/home">Go to Home</a></li>
      <li><a routerLink="/aboutUs">About Us</a></li>
      <li><a routerLink="/contactUs">Contact Us</a></li>
    </ul>
  
    <!-- <p>If you're looking for something specific, try using the search bar:</p>
    <input type="text" placeholder="Search..."> -->
  </div>