import { Component, OnInit, ViewChild, HostListener} from '@angular/core';
import { Router } from '@angular/router';
import { ToastService, ModalContainerComponent } from 'ng-uikit-pro-standard';
import { ShippingAddress, PlaceOrderResolved } from '../models/ShippingAddress.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { User } from '../models/user.model';
import { UserCart } from '../models/UserCart.model';
import { UserService } from '../user/user.service';
import { TokenStorageService } from '../services/token-storage.service';
import { EventEmitterService } from '../services/event-emitter.service';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { placeOrder } from '../models/PlaceOrder.model';
import { HttpClient } from '@angular/common/http';
import { UrlComponent } from "../url.component";
import { OrderService } from '../services/order.service';
import { AuthService } from '../services/auth.service';

declare var Razorpay: any;

@Component({
  selector: 'app-PlaceOrder',
  templateUrl: './placeOrder.component.html',
  styleUrls: ['./placeOrder.component.scss'],
})
export class PlaceOrder implements OnInit {

  codOtp: number;

  users: User[];
  userCarts: UserCart[];
  currentUser: User = new User();
  finalOrder: placeOrder = new placeOrder();
  concatProductId: string;
  setConcatProductId: string;
  totalCost: number;
  totalDiscountCost: number;
  afterDiscountTotalCost: number;
  isLoggedIn = false;
  username: string;
  //form: any = {};
  optionsSelectCountry: Array<any>;
  optionsSelectState: Array<any>;
  isSignUpFailed = false;
  element: HTMLElement;
  finalPaymentElement: HTMLElement;
  errorMessage: string;
  toBeDeleteuserCarts: UserCart = new UserCart();
  @ViewChild('frame') frame: ModalContainerComponent;
  //shippingAddress: ShippingAddress = new ShippingAddress();
  shippingAddress: ShippingAddress;
  @ViewChild('shippingState') shippingState;
  @ViewChild('shippingCountry') shippingCountry;
  buyNoProductId: Observable<string>;
  buyNowselectedId: string;
  i: number;
  public payuform: any = {};
  disablePaymentButton: boolean = true;
  enableRozpayButton: boolean =false;
  enableCODpayButton: boolean =false;
  enableOTP: boolean =false;
  private paymentDetailsUrl = UrlComponent.API_URL + '/payment/payment-details';
  stepOne = false;


  constructor(private router: Router, private route: ActivatedRoute, private tokenStorage: TokenStorageService, private toastrService: ToastService,
    private userService: UserService, private eventEmitterService: EventEmitterService, private http: HttpClient, private orderService: OrderService, 
    private authService: AuthService) {

  }

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;

  optionsSelectPeriod = [
    { value: '1', label: '+6 months: 200$' },
    { value: '2', label: '+12 months: 400$' },
    { value: '3', label: '+18 months: 800$' },
    { value: '4', label: '+24 months: 1200$' },
  ];

  //RAZORPAY
  form: any = {}; 
  codform: any = {}; 
	paymentId: string;
  error: string;
  paymentSuceessEmail: string;
  paymentSuccessMobile: string;
  paymentSuccessTotalAmt: number;

  options = {
    "key": "",
    "amount": "", 
    "name": "CraftsHarbour",
    "description": "Web Development",
    "image": "https://craftsharbour.com:8443/user-portal/api/image-manual-response.jpg",
    "order_id":"",
    "handler": function (response){
      var event = new CustomEvent("payment.success", 
        {
          detail: response,
          bubbles: true,
          cancelable: true
        }
      );	  
      window.dispatchEvent(event);
    }
    ,
    "prefill": {
    "name": "",
    "email": "",
    "contact": ""
    },
    "notes": {
    "address": ""
    },
    "theme": {
    "color": "#3399cc"
    }
    };
//RAZORPAY


  ngOnInit() {

    this.optionsSelectCountry = [
      { value: '0', label: 'India' }
      //{ value: '2', label: 'USA' },
      //{ value: '3', label: 'UK' },
    ];

    this.optionsSelectState = [

      { value: '0', label: 'West Bengal' },
      { value: '1', label: 'Andaman and Nicobar' },
      { value: '2', label: 'Andhra Pradesh' },
      { value: '3', label: 'Arunachal Pradesh' },
      { value: '4', label: 'Assam' },
      { value: '5', label: 'Bihar' },
      { value: '6', label: 'Chandigarh' },
      { value: '7', label: 'Chhattisgarh' },
      { value: '8', label: 'Dadra and Nagar Haveli' },
      { value: '9', label: 'Daman and Diu' },
      { value: '10', label: 'Delhi' },
      { value: '11', label: 'Goa' },
      { value: '12', label: 'Gujarat' },
      { value: '13', label: 'Haryana' },
      { value: '14', label: 'Himachal Pradesh' },
      { value: '15', label: 'Jammu and Kashmir' },
      { value: '16', label: 'Jharkhand' },
      { value: '17', label: 'Karnataka' },
      { value: '18', label: 'Kerala' },
      { value: '19', label: 'Lakshadweep' },
      { value: '20', label: 'Madhya Pradesh' },
      { value: '21', label: 'Maharashtra' },
      { value: '22', label: 'Manipur' },
      { value: '23', label: 'Meghalaya' },
      { value: '24', label: 'Mizoram' },
      { value: '25', label: 'Nagaland' },
      { value: '26', label: 'Orissa' },
      { value: '27', label: 'Pondicherry' },
      { value: '28', label: 'Punjab' },
      { value: '29', label: 'Rajasthan' },
      { value: '30', label: 'Sikkim' },
      { value: '31', label: 'Tamil Nadu' },
      { value: '32', label: 'Telangana' },
      { value: '33', label: 'Tripura' },
      { value: '34', label: 'Uttar Pradesh' },
      { value: '35', label: 'Uttarakhand (Uttaranchal)' }
    ];

    this.totalCost = 0;
    this.totalDiscountCost = 0;
    this.afterDiscountTotalCost = 0;

    this.isLoggedIn = !!this.tokenStorage.getToken();

    if (this.isLoggedIn) {
      const user = this.tokenStorage.getUser();
      this.username = user.username;
    }
    this.buyNoProductId = this.route.paramMap.pipe(map(params => params.get('buyNowId')));
    this.buyNoProductId.subscribe(value => {
      //do something with the value 
      this.buyNowselectedId = value;
    })

    if (this.buyNowselectedId != null || this.buyNowselectedId != undefined) {

      this.userService.getBuyNowShoppingCart(this.username, this.buyNowselectedId)
        .subscribe(data => {
          this.userCarts = data;

          for (let a of this.userCarts) {
            this.totalCost = this.totalCost + a.discountCost;
          }

          for (let a of this.userCarts) {
            this.afterDiscountTotalCost = this.afterDiscountTotalCost + a.unitPrice;
          }

          this.totalDiscountCost = this.totalCost - this.afterDiscountTotalCost;
        });

    }
    else {
      this.userService.getShoppingCart(this.username)
        .subscribe(data => {
          this.userCarts = data;

          for (let a of this.userCarts) {
            this.totalCost = this.totalCost + a.discountCost;
          }

          for (let a of this.userCarts) {
            this.afterDiscountTotalCost = this.afterDiscountTotalCost + a.unitPrice;
          }

          this.totalDiscountCost = this.totalCost - this.afterDiscountTotalCost;
        });
    }



    this.firstFormGroup = new FormGroup({
      check: new FormControl('false', Validators.requiredTrue)
    });


    const resolvedData: PlaceOrderResolved =
      this.route.snapshot.data['resolvedData'];
    this.shippingAddress = resolvedData.shippingAddress;

  }

  get check() { return this.firstFormGroup.get('check'); }

  onSubmit() {
    // do something here
  }


  onSubmitShippingAddress() {
    const user = this.tokenStorage.getUser();
    this.shippingAddress.username = user.username;
    this.userService.addShippingAddress(this.shippingAddress).subscribe(
      data => {
        //console.log(data);
        this.isSignUpFailed = false;
        this.firstFormGroup.controls.check.setValue(true);
        this.element = document.getElementById('nextId') as HTMLElement;
        this.element.click();

        const options = { opacity: 1 };
        this.toastrService.success(data.message, '', options);

      },
      err => {
        this.errorMessage = err.error.message;
        this.isSignUpFailed = true;
        this.firstFormGroup.controls.check.setValue(false);
        const options = { opacity: 1 };
        this.toastrService.error(this.errorMessage, '', options);
      }
    );
  }

  selectedOptionCountry: any = { value: '', label: '' };
  selectedOptionState: any = { value: '', label: '' };

  optionSelectCountry(event: any) {
    //console.log(event);    
    this.selectedOptionCountry = event;
    this.firstFormGroup.controls.check.setValue(false);
  }

  optionSelectState(event: any) {
    //console.log(event);    
    this.selectedOptionState = event;
    this.firstFormGroup.controls.check.setValue(false);
  }

  deleteFromCart(userCarts: UserCart): void {
    this.userService.deleteFromCart(userCarts)
      .subscribe(data => {
        this.userCarts = this.userCarts.filter(u => u !== userCarts);
      })
  };


  deleteFromCartsss() {

    this.totalCost = 0;
    this.totalDiscountCost = 0;
    this.afterDiscountTotalCost = 0;

    this.userService.deleteFromCartPopup(this.toBeDeleteuserCarts)
      .subscribe(data => {
        this.userCarts = this.userCarts.filter(u => u !== this.toBeDeleteuserCarts);

        for (let a of this.userCarts) {
          this.totalCost = this.totalCost + a.discountCost;
        }
        for (let a of this.userCarts) {
          this.afterDiscountTotalCost = this.afterDiscountTotalCost + a.unitPrice;
        }
        this.totalDiscountCost = this.totalCost - this.afterDiscountTotalCost;
        this.eventEmitterService.onFirstComponentButtonClick();
        this.frame.hide();
        const options = { opacity: 1 };
        this.toastrService.info("Item Deleted Successfully", '', options);
      }, err => {
        console.log(err);
        this.errorMessage = "Try After Sometime";
        const options = { opacity: 1 };
        this.toastrService.error('Try after sometime', '', options);
      })
  }

  setProdId(userCarts: UserCart) {
    this.toBeDeleteuserCarts = userCarts;
  }


  placeOrder() {
    this.i = 0;
    for (let a of this.userCarts) {
      if (this.i == 0) {
        this.concatProductId = a.productId;
        this.i++;
      } else {
        this.concatProductId = this.concatProductId + "," + a.productId;
      }
    }

    this.finalOrder.productId = this.concatProductId;
    this.finalOrder.totalAmount = this.totalCost;
    this.finalOrder.discountAmount = this.totalDiscountCost;
    this.finalOrder.paybaleAmount = this.afterDiscountTotalCost;
    this.finalOrder.cratedBy = this.username;
    this.finalOrder.shippingFirstName = this.shippingAddress.shippingName;
    this.finalOrder.shippingLastName = this.shippingAddress.shippingLastname;
    this.finalOrder.shippingAddress = this.shippingAddress.shippingStreet;
    this.finalOrder.shippingCountry = this.shippingAddress.shippingCountry;
    this.finalOrder.shippingState = this.shippingAddress.shippingState;
    this.finalOrder.shippingPin = this.shippingAddress.shippingPinCode;
    this.finalOrder.shippingMobileNo = this.shippingAddress.shippingMobileNo;
    this.finalOrder.txnType = "COD";
    this.userService.placeOrder(this.finalOrder).subscribe(
      data => {
        //console.log(data);
        const options = { opacity: 1 };
        this.toastrService.success(data.message, '', options);
      },
      err => {
        this.errorMessage = err.error.message;
        const options = { opacity: 1 };
        this.toastrService.error(this.errorMessage, '', options);
      });
  }


  confirmPayment() {
    const paymentPayload = {
      email: this.payuform.email,
      name: this.payuform.firstname,
      phone: this.payuform.phone,
      productInfo: this.payuform.productinfo,
      amount: this.payuform.amount
    }
    
    return this.http.post<any>(this.paymentDetailsUrl, paymentPayload).subscribe(
      data => {
        //console.log(data);
        this.payuform.txnid = data.txnId;
        this.payuform.surl = data.sUrl;
        this.payuform.furl = data.fUrl;
        this.payuform.key = data.key;
        this.payuform.hash = data.hash;
        this.payuform.txnid = data.txnId;
        this.payuform.serviceProvider = "payu_paisa";
        this.disablePaymentButton = false;
      }, error1 => {
        console.log(error1);
      })
  }

  valuechange(event: any) {
    this.firstFormGroup.controls.check.setValue(false);
  }


  concatProductIds() {
    this.i = 0;
    for (let a of this.userCarts) {
      if (this.i == 0) {
        this.setConcatProductId = a.productId;
        this.i++;
      } else {
        this.setConcatProductId = this.setConcatProductId + "," + a.productId;
      }
    }

  }

  setPaymentForm() {
    this.concatProductIds();
    // this.payuform.email = this.shippingAddress.email;
    // this.payuform.firstname = this.shippingAddress.shippingName.concat(" ").concat(this.shippingAddress.shippingLastname);
    // this.payuform.phone = this.shippingAddress.shippingMobileNo;
    // this.payuform.productinfo = this.setConcatProductId;
    // this.payuform.amount = this.afterDiscountTotalCost;

    this.form.email = this.shippingAddress.email;
    this.form.name = this.shippingAddress.shippingName.concat(" ").concat(this.shippingAddress.shippingLastname);
    this.form.phone = this.shippingAddress.shippingMobileNo;   
    this.form.productinfo = this.setConcatProductId;
    this.form.amount = this.afterDiscountTotalCost;
    this.form.shippingFirstName = this.shippingAddress.shippingName;
    this.form.shippingLastName = this.shippingAddress.shippingLastname;
    this.form.shippingAddress = this.shippingAddress.shippingStreet;
    this.form.shippingCountry = this.shippingAddress.shippingCountry;
    this.form.shippingState = this.shippingAddress.shippingState;
    this.form.shippingPin = this.shippingAddress.shippingPinCode;   
    this.form.txnType = "RazPay";   
    this.enableRozpayButton =true;
    this.enableCODpayButton =false;   
  }

  setRozPaymentForm(evt) { 
    var target = evt.target;    
    if (target.checked) { 
    this.form.email = this.shippingAddress.email;
    this.form.name = this.shippingAddress.shippingName.concat(" ").concat(this.shippingAddress.shippingLastname);
    this.form.phone = this.shippingAddress.shippingMobileNo;   
    this.form.productinfo = this.setConcatProductId;
    this.form.amount = this.afterDiscountTotalCost;
    this.form.shippingFirstName = this.shippingAddress.shippingName;
    this.form.shippingLastName = this.shippingAddress.shippingLastname;
    this.form.shippingAddress = this.shippingAddress.shippingStreet;
    this.form.shippingCountry = this.shippingAddress.shippingCountry;
    this.form.shippingState = this.shippingAddress.shippingState;
    this.form.shippingPin = this.shippingAddress.shippingPinCode;   
    this.form.txnType = "RazPay";   
    this.enableRozpayButton =true;
    this.enableCODpayButton =false;   
    this.enableOTP =false;
    }
  }

  setPayUPaymentForm(evt) { 
    var target = evt.target;    
    if (target.checked) {   
    this.enableRozpayButton =false;
    this.disablePaymentButton = true;
    }
  }

//RAZORPAY
onSubmitRazPay(): void {
  this.paymentId = ''; 
  this.error = ''; 
  this.orderService.createOrder(this.form).subscribe(
  data => {
    this.options.key = data.secretKey;
    this.options.order_id = data.razorpayOrderId;
    this.options.amount = data.applicationFee; //paise     
    this.options.prefill.name = this.form.name;
    this.options.prefill.email = this.form.email;
    this.options.prefill.contact = this.form.phone;
    var rzp1 = new Razorpay(this.options);
    rzp1.open();
            
    rzp1.on('payment.failed', function (response){    
      // Todo - store this information in the server
      console.log(response.error.code);    
      console.log(response.error.description);    
      console.log(response.error.source);    
      console.log(response.error.step);    
      console.log(response.error.reason);    
      console.log(response.error.metadata.order_id);    
      console.log(response.error.metadata.payment_id);
      this.error = response.error.reason;
    }
    );
  }
  ,
  err => {
    this.error = err.error.message;
  }
  );
}

@HostListener('window:payment.success', ['$event']) 
onPaymentSuccess(event): void {  
  this.orderService.updateOrder(event.detail).subscribe(
  data => {
    this.paymentId = data.message;   
    this.paymentSuccessMobile = this.shippingAddress.shippingMobileNo;
    this.paymentSuceessEmail = this.shippingAddress.email;
    this.paymentSuccessTotalAmt =this.afterDiscountTotalCost;
    this.eventEmitterService.onFirstComponentButtonClick();
  }  
  ,
  err => {
    this.error = err.error.message;
  }
  );
 
}



public printInvoice(): void { 
    this.userService.generateInvoice(this.paymentId).subscribe(
      (data: Blob) => {
        //alert("Hi");
        var file = new Blob([data], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        var a = document.createElement('a');
        a.href = fileURL;
        a.target = '_blank';
        a.download = 'reports.pdf';
        document.body.appendChild(a);
        a.click();          
      },
      (error) => {
        console.log('getPDF error: ', error);
       
      }
    );
  }

  setCODForm(evt) { 
    var target = evt.target;    
    if (target.checked) {      
    this.codform.email = this.shippingAddress.email;
    this.codform.name = this.shippingAddress.shippingName.concat(" ").concat(this.shippingAddress.shippingLastname);
    this.codform.phone = this.shippingAddress.shippingMobileNo;   
    this.codform.productinfo = this.setConcatProductId;
    this.codform.amount = this.afterDiscountTotalCost;
    this.codform.shippingFirstName = this.shippingAddress.shippingName;
    this.codform.shippingLastName = this.shippingAddress.shippingLastname;
    this.codform.shippingAddress = this.shippingAddress.shippingStreet;
    this.codform.shippingCountry = this.shippingAddress.shippingCountry;
    this.codform.shippingState = this.shippingAddress.shippingState;
    this.codform.shippingPin = this.shippingAddress.shippingPinCode;   
    this.codform.txnType = "COD";   
    this.enableCODpayButton =true;
    this.enableRozpayButton =false;
    this.enableOTP =true;
    this.sendCodOtp();
    }
  }

  //COD CREATE ORDER
  onSubmitCODPay(): void {
  this.paymentId = ''; 
  this.error = '';   
  this.codform.codOtp = this.codOtp;
  this.orderService.createCODOrder(this.codform).subscribe(
  data => {       
    this.paymentId = data.message;                
      this.paymentSuccessMobile = this.shippingAddress.shippingMobileNo;
      this.paymentSuceessEmail = this.shippingAddress.email;
      this.paymentSuccessTotalAmt =this.afterDiscountTotalCost;
      this.eventEmitterService.onFirstComponentButtonClick();
    
  }
  ,
  err => {
    this.error = err.error.message;
  }
  );
}

fetchBack(): void {
  event.preventDefault();
  this.router.navigate(['/category'],
    {
      queryParams: {
        pvsu: btoa('BAS'),
        ssidn: btoa('product name should be display here as mentioed in craftsharbour.com'),
        subssidn: btoa('bishnupur product')
      }
    });
  //console.log(event);
};

sendCodOtp(): void {   
  //alert("Hi");
    this.authService.sendCodOTP().subscribe(
      data => {
        //console.log(data);          
      },
      err => {
        this.errorMessage = err.error.message;       
      }
    );
}


}


