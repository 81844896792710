import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { TokenStorageService } from '../services/token-storage.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    // constructor(private token: TokenStorageService, private router: Router) { }

    // intercept(req: HttpRequest<any>, next: HttpHandler) {        
    //     let authReq = req;
    //     const token = this.token.getToken();
    //     if (token != null) {
            
    //         authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
    //     }
    //     return next.handle(authReq);
    // }

    constructor(private tokenService: TokenStorageService, private router: Router) { }
    
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        const token = this.tokenService.getToken();
        //alert("Hi"+token);
        if (token) {
          // Check if the token is expired
          if (this.tokenService.isTokenExpired(token)) {
            this.tokenService.logout(); // Token expired, log out the user
            return new Observable(); // Stop the request
          }
    
          // If token is not expired, add it to the request header
          req = req.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`
            }
          });
        }
        return next.handle(req).pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.status === 401) {
              this.tokenService.logout(); // Handle unauthorized error (e.g., token expired)
            }
            throw error;
          })
        );
      }
}

 export const httpInterceptorProviders = [
     { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
 ];
