export class UserCart {

  id: number;
  productId: string;
  productName: string;
  productDescription: string;
  imageId: string;
  userName: string;
  emailId: string;
  productQty: number;
  unitPrice: number;
  discountCost: number;
  discountPercent: number;
  totalCost: number;
  giftWrap: number;
  currentStock: number;
  productColor: string;
  shippingAddress: string;
  shippingName: string;
  shippingMobileNo: string;
  shippingLandMark: string;
  shippingCity: string;
  shippingState: string;
  shippingPinCode: string;

}
export interface UserCartResolved {
  usercart: UserCart[];
  error?: any;
}