import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { pinCode } from "../models/PinCode.model";
import { User } from '../models/user.model';
import { UserCart } from '../models/UserCart.model';
import { Observable } from 'rxjs';
import { ProductDetails } from '../models/productDetails.model';
import { UrlComponent } from "../url.component";
import { UserComments } from '../models/UserComments.model';

import { Http } from '@angular/http';
import { ShippingAddress } from '../models/ShippingAddress.model';
import { UserProfile } from '../models/UserProfile.model';
import { placeOrder } from '../models/PlaceOrder.model';
import { PaymentStatusBean } from '../models/PaymentStatusBean.model';
import { catchError, retry } from 'rxjs/operators';
import { of } from 'rxjs';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class UserService {

  constructor(public httpp: Http, private http: HttpClient) { }

  private userUrl = UrlComponent.API_URL + '/users';
  private nonUserCartUrl = UrlComponent.API_URL + '/shoppingCart/userCart';
  private buyNowCartUrl = UrlComponent.API_URL + '/shoppingCart/buyNowUserCart';
  private photoUrl = UrlComponent.API_URL + '/users/getallfiles';
  private productDetailsUrl = UrlComponent.API_URL + '/users/getallDetailsfiles';
  private userCommeentsUrl = UrlComponent.API_URL + '/userComment/getComments';
  private bestSellingUrl = UrlComponent.API_URL + '/users/getBestSelling';
  private recommendedUrl = UrlComponent.API_URL + '/users/getRecommended';
  private checkPinUrl = UrlComponent.API_URL + '/checkpin';
  private addToCartUrl = UrlComponent.API_URL + '/shoppingCart/';
  private addToWishList = UrlComponent.API_URL + '/wishList/';
  private removeToWishList = UrlComponent.API_URL + '/users/';
  private addShippingAdd = UrlComponent.API_URL + '/order/';
  private getShippingAdd = UrlComponent.API_URL + '/order/getShippigAddress';
  private getPaymentStatusUrl = UrlComponent.API_URL + '/order/getPaymentStatus';
  private getInvoiceUrl = UrlComponent.API_URL + '/invoice/report';
  private getLiveInvoiceUrl = UrlComponent.API_URL + '/invoice/paymentInvoicePdf';

  private cartUrl = UrlComponent.API_URL + '/shoppingCart';
  private userProfileUrl = UrlComponent.API_URL + '/profile/getUserProfile';
  private discountDataUrl = UrlComponent.API_URL + '/users/getDiscountData';
  private finalOrder = UrlComponent.API_URL + '/order/';
  private addComments = UrlComponent.API_URL + '/userComment/saveComments'

  private justAddedUrl = UrlComponent.API_URL + '/users/getJustAdded';
  private fourImageUrl = UrlComponent.API_URL + '/users/getfourImage';
  

  public getUsers() {
    return this.http.get<User[]>(this.userUrl);
  }


  public getShoppingCart(email: string): Observable<any> {
    return this.http.get<UserCart[]>(this.nonUserCartUrl);
  }

  public getBuyNowShoppingCart(email: string, productId: string) {
    return this.http.get<UserCart[]>(this.buyNowCartUrl, { params: { emailId: email, productId: productId } });
  }

  public deleteUser(user) {
    return this.http.delete(this.userUrl + "/" + user.id);
  }

  public createUser(user) {
    return this.http.post<User>(this.userUrl, user);
  }

  public getFiles(): Observable<any> {
    //console.log('Called getFiles');
    return this.http.get(this.photoUrl + "/" + "BAS");
  }

  public getSssFiles(): Observable<any> {
    //console.log('Called getFiles');
    return this.http.get<ProductDetails[]>(this.photoUrl + "/" + "SSS");
  }

  public getProductDetailsFiles(prodDetails): Observable<any> {
    return this.http.get<ProductDetails[]>(this.productDetailsUrl + "/" + prodDetails);
  }

  public getFourImageFiles(prodDetails): Observable<any> {
    return this.http.get<ProductDetails[]>(this.fourImageUrl + "/" + prodDetails);
  }

  public getSssFiless(value: any, min: any, max: any): Observable<any> {
    //console.log('Called getFiles');
    return this.http.get<ProductDetails[]>(this.photoUrl + "/" + value + "/" + min + "/" + max);
  }


  public getUserComments(userComments): Observable<any> {

    return this.http.get<UserComments[]>(this.userCommeentsUrl + "/" + userComments);
  }

  public getBestSellingProduct(value): Observable<any> {
    //console.log('Called getFiles');
    return this.http.get<ProductDetails[]>(this.bestSellingUrl + "/" + value.substr(0, 3));
  }


  public deleteFromCart(userCart) {
    return this.http.delete(this.cartUrl + "/" + userCart.id + "/" + userCart.emailId);
  }

  public deleteFromCartPopup(userCart) {
    return this.http.delete(this.cartUrl + "/" + userCart.id + "/" + userCart.emailId);
  }


  addToCart(productDetail: ProductDetails, pinCode): Observable<any> {
    productDetail.pincode = pinCode;
    const body = JSON.stringify(productDetail);
    return this.http.post(this.addToCartUrl + 'addToCart', body, httpOptions);
  }

  addToCartProdCategory(productDetail: ProductDetails): Observable<any> {
    //productDetail.pincode = pinCode;
    const body = JSON.stringify(productDetail);
    return this.http.post(this.addToCartUrl + 'addToCartProdCategory', body, httpOptions);
  }

  addToWishLst(productDetail: ProductDetails): Observable<any> {
    const body = JSON.stringify(productDetail);
    return this.http.post(this.addToWishList + 'addToWishList', body, httpOptions);
  }

  removeToWishLst(productDetail: ProductDetails): Observable<any> {
    const body = JSON.stringify(productDetail);
    return this.http.post(this.removeToWishList + 'deleteFromWishList', body, httpOptions);
  }


  checkPinCode(pinCode: pinCode): Observable<any> {
    const body = JSON.stringify(pinCode);
    return this.http.post(this.checkPinUrl + '/pincode', {
      pinCode: pinCode.pincode
    }, httpOptions);
  }

  addShippingAddress(shippingAdd: ShippingAddress): Observable<any> {
    const body = JSON.stringify(shippingAdd);
    return this.http.post(this.addShippingAdd + 'addShippingAddress', body, httpOptions);
  }

  public getUserProfile(username: string): Observable<any> {
    return this.http.get<UserProfile[]>(this.userProfileUrl);
  }


  public getDiscountData(value: any, min: any, max: any, markedBelow25: any, markedUpto25: any, markedAbove30: any, shortBy: any, color: any): Observable<any> {
    //console.log('Called getFiles');
    return this.http.get<ProductDetails[]>(this.discountDataUrl + "/" + value + "/" + min + "/" + max + "/" + markedBelow25 + "/" + markedUpto25 + "/" + markedAbove30 + "/" + shortBy + ":" + color);
  }


  public getShippigAddress(username: string): Observable<any> {
    return this.http.get<ShippingAddress[]>(this.getShippingAdd);
  }

  public getPaymentStatus(txnId: string): Observable<any> {
    const params = new HttpParams()
      .set('TXNID', txnId);
    return this.http.get<PaymentStatusBean[]>(this.getPaymentStatusUrl, { params: params });
  }

  placeOrder(finalOrder: placeOrder): Observable<any> {
    const body = JSON.stringify(finalOrder);
    return this.http.post(this.finalOrder + 'placeOrder', body, httpOptions);
  }

  addUserComments(comments, products): Observable<any> {
    return this.http.post(this.addComments, {
      userRatings: comments.userRatings,
      userComment: comments.userComment,
      productId: products.productId,
      productName: products.productName
    }, httpOptions);
  }

  // public getRecommondedItems(): Observable<any> {
  //   console.log('Called getFiles');
  //   return this.http.get<ProductDetails[]>(this.recommendedUrl);
  // }

  public getRecommondedItems(): Observable<ProductDetails[]> {
    return this.http.get<ProductDetails[]>(this.recommendedUrl)
      .pipe(
        retry(2),  // Retry the request 2 times in case of failure
        catchError(this.handleError<ProductDetails[]>('getJustAddedItems', []))  // Provide a fallback value in case of error
      );
  }

  // public getJustAddedItems(): Observable<any>{
  //   console.log('Called getFilesJustAdded');
  //   return this.http.get<ProductDetails[]>(this.justAddedUrl);
  // }

  public getJustAddedItems(): Observable<ProductDetails[]> {
    return this.http.get<ProductDetails[]>(this.justAddedUrl)
      .pipe(
        retry(2),  // Retry the request 2 times in case of failure
        catchError(this.handleError<ProductDetails[]>('getJustAddedItems', []))  // Provide a fallback value in case of error
      );
  }

  public generateInvoice(txnId: string): Observable<Blob> {
    const params = new HttpParams()
      .set('TXNID', txnId);
    //return this.http.get(this.getInvoiceUrl, { params: params, responseType: 'blob' });
    return this.http.get(this.getLiveInvoiceUrl, { params: params, responseType: 'blob' });
    
  }  

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // Customize your error handling based on your needs
      console.error(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

}