import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { User } from '../models/user.model';
import { UserCart, UserCartResolved } from '../models/UserCart.model';
import { UserService } from './user.service';
import { TokenStorageService } from '../services/token-storage.service';
import { ModalContainerComponent, ToastService } from 'ng-uikit-pro-standard';
import { EventEmitterService } from '../services/event-emitter.service';


@Component({
  selector: 'app-user',
  templateUrl: './usercart.component.html',
  styleUrls: ['./usercart.component.scss'],
})
export class UserCartComponent implements OnInit {

  users: User[];
  userCarts: UserCart[];
  currentUser: User = new User();
  totalCost: number;
  totalDiscountCost: number;
  afterDiscountTotalCost: number;
  loading: boolean = false;
  isLoggedIn = false;
  username: string;
  toBeDeleteuserCarts: UserCart = new UserCart();
  prodId: number;
  mailId: string;
  @ViewChild('frame') frame: ModalContainerComponent;
  errorMessage: string;

  constructor(private router: Router, private userService: UserService,
    private tokenStorage: TokenStorageService, private eventEmitterService: EventEmitterService,
    private toastrService: ToastService, private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.totalCost = 0;
    this.totalDiscountCost = 0;
    this.afterDiscountTotalCost = 0;
    this.isLoggedIn = !!this.tokenStorage.getToken();
    if (this.isLoggedIn) {
      const user = this.tokenStorage.getUser();
      this.username = user.username;
    }
    const resolvedData: UserCartResolved =
      this.route.snapshot.data['resolvedData'];

    this.userCarts = resolvedData.usercart;

    for (let a of this.userCarts) {
      this.totalCost = this.totalCost + a.discountCost;
    }

    for (let a of this.userCarts) {
      this.afterDiscountTotalCost = this.afterDiscountTotalCost + a.unitPrice;
    }

    this.totalDiscountCost = this.totalCost - this.afterDiscountTotalCost;

  };

  deleteUser(user: User): void {
    this.userService.deleteUser(user)
      .subscribe(data => {
        this.users = this.users.filter(u => u !== user);
      })
  };

  deleteFromCart(userCarts: UserCart): void {
    this.userService.deleteFromCart(userCarts)
      .subscribe(data => {
        this.userCarts = this.userCarts.filter(u => u !== userCarts);
      })
  };


  fetchDetailss(productId) {
    event.preventDefault();
    this.router.navigate(['/product'],
      {
        queryParams: {
          pvsu: btoa(productId),
          ssidn: btoa('product name should be display here as mentioed in craftsharbour.com'),
          subssidn: btoa('bishnupur product')
        }
      });
    //console.log(event);
  };

  deleteFromCartsss() {

    this.totalCost = 0;
    this.totalDiscountCost = 0;
    this.afterDiscountTotalCost = 0;

    this.userService.deleteFromCartPopup(this.toBeDeleteuserCarts)
      .subscribe(data => {
        this.userCarts = this.userCarts.filter(u => u !== this.toBeDeleteuserCarts);
        for (let a of this.userCarts) {
          this.totalCost = this.totalCost + a.discountCost;
        }
        for (let a of this.userCarts) {
          this.afterDiscountTotalCost = this.afterDiscountTotalCost + a.unitPrice;
        }
        this.totalDiscountCost = this.totalCost - this.afterDiscountTotalCost;
        this.eventEmitterService.onFirstComponentButtonClick();
        this.frame.hide();
        const options = { opacity: 1 };
        this.toastrService.info("Item Deleted Successfully", '', options);
      }, err => {
        console.log(err);
        this.errorMessage = "Try After Sometime";
        const options = { opacity: 1 };
        this.toastrService.error('Try after sometime', '', options);
      })
  }

  setProdId(userCarts: UserCart) {
    this.toBeDeleteuserCarts = userCarts;
  }

  fetchBack(): void {
    event.preventDefault();
    this.router.navigate(['/category'],
      {
        queryParams: {
          pvsu: btoa('BAS'),
          ssidn: btoa('product name should be display here as mentioed in craftsharbour.com'),
          subssidn: btoa('bishnupur product')
        }
      });
    //console.log(event);
  };


}


