import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import { Observable } from 'rxjs/Observable';
import { ProductDetails, ProductResolved } from '../models/productDetails.model';
import { ActivatedRoute } from '@angular/router';
import { EventEmitterService } from '../services/event-emitter.service';
import { TokenStorageService } from '../services/token-storage.service';
import { ToastService } from 'ng-uikit-pro-standard';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';



@Component({
  templateUrl: './displaylist.component.html',
  styleUrls: ['./displaylist.component.scss'],
})

export class DisplaylistSss implements OnInit {

  private prodDetails: ProductDetails[];
  productFullDetails: Observable<ProductDetails[]>;
  selectedId: string;
  productId: Observable<string>;
  min: Observable<string>;
  max: Observable<string>;
  minValue: number;
  maxValue: number;
  markedBelow25 = false;
  markedUpto25 = false;
  markedAbove30 = false;
  shortBy: string = "nothing";
  PopshortBy: string = "nothing";
  colorFilter: string = "All";
  popminValue: number;
  popmaxValue: number;
  popmarkedBelow25 = false;
  popmarkedUpto25 = false;
  popmarkedAbove30 = false;
  public loading: boolean = false;
  isLoggedIn = false;
  errorMessage: string;
  element: HTMLElement;
  toggle2 = false;
  toggle3 = false;
  public selectedIndex: number[] = new Array();
  public selectedIndexCart: number[] = new Array();
  imageWidth: number = 165;
  imageMargin: number = 1;
  imageWidthDesk: number = 220;

  private noOfItemsToShowInitially: number = 9;
  // itemsToLoad - number of new items to be displayed
  private itemsToLoad: number = 9;
  // List that is going to be actually displayed to user
  public itemsToShow: ProductDetails[];
  //public itemsToShow;
  // No need to call onScroll if full list has already been displayed
  public isFullListDisplayed: boolean = false;

  constructor(private router: Router, private userService: UserService, private route: ActivatedRoute,
    private eventEmitterService: EventEmitterService, private tokenStorage: TokenStorageService, private toastrService: ToastService,
    public sanitizer: DomSanitizer) {
    this.minValue = 500;
    this.maxValue = 25000;
    this.popminValue = 500;
    this.popmaxValue = 25000;

  }
  fileUploads: Observable<string[]>


  loginComponentFunction() {
    this.eventEmitterService.loginComponentButtonClick();
  }


  ngOnInit() {
    this.loading = true;
    this.isLoggedIn = !!this.tokenStorage.getToken();
    this.selectedId = atob(this.route.snapshot.queryParamMap.get('pvsu'));
    const resolvedData: ProductResolved =
      this.route.snapshot.data['resolvedData'];

    this.prodDetails = resolvedData.productDetails;
    this.noOfItemsToShowInitially = 9;
    this.itemsToShow = this.prodDetails.slice(0, this.noOfItemsToShowInitially);

    this.eventEmitterService.subsVar = this.eventEmitterService.
      invokeMenuComponentFunction.subscribe((id: string) => {
        this.selectedId = id;
        this.userService.getSssFiless(id, this.minValue, this.maxValue)
          .subscribe(data => {
            this.prodDetails = data;
            this.noOfItemsToShowInitially = 9;
            this.itemsToShow = this.prodDetails.slice(0, this.noOfItemsToShowInitially);
            window.scroll(0, 0);
          });
      });
    this.loading = false;
    //alert(this.itemsToShow);
  };

  fetchDetailss(ProdDetails: ProductDetails): void {
    event.preventDefault();
    this.router.navigate(['/product'],
      {
        queryParams: {
          pvsu: btoa(ProdDetails.productId),
          ssidn: btoa('product name should be display here as mentioed in craftsharbour.com'),
          subssidn: btoa('bishnupur product')
        }
      });
    //console.log(event);
  };

  onRangeValueChange(event: any) {
    const value = event.value;
    this.minValue = event.first;
    this.maxValue = event.second;

    this.userService.getSssFiless(this.selectedId, this.minValue, this.maxValue)
      .subscribe(data => {
        this.prodDetails = data;
        this.noOfItemsToShowInitially = 9;
        this.itemsToShow = this.prodDetails.slice(0, this.noOfItemsToShowInitially);
      });
  }

  fetchProducts(value): void {
    this.userService.getSssFiless(value, 1000, 25000)
      .subscribe(data => {
        this.prodDetails = data;
        this.noOfItemsToShowInitially = 9;
        this.itemsToShow = this.prodDetails.slice(0, this.noOfItemsToShowInitially);
      });
    //console.log(event);
  };

  fetchProductsType(value): void {
    this.selectedId = value;
    this.markedBelow25 = false;
    this.markedUpto25 = false;
    this.markedAbove30 = false;
    this.shortBy = "nothing";
    this.userService.getSssFiless(value, 1000, 25000)
      .subscribe(data => {
        this.prodDetails = data;
        this.noOfItemsToShowInitially = 9;
        this.itemsToShow = this.prodDetails.slice(0, this.noOfItemsToShowInitially);
      });
    //console.log(event);
  };



  toggleVisibilityBelow25(e) {
    this.markedBelow25 = e.checked;
    this.fetchDiscount();
  }

  toggleVisibilityUpto25(e) {
    this.markedUpto25 = e.checked;
    this.fetchDiscount();
  }

  toggleVisibilityAbove30(e) {
    this.markedAbove30 = e.checked;
    this.fetchDiscount();
  }

  fetchDiscount() {
    this.userService.getDiscountData(this.selectedId, this.minValue, this.maxValue, this.markedBelow25, this.markedUpto25, this.markedAbove30, this.shortBy, this.colorFilter)
      .subscribe(data => {
        this.prodDetails = data;
        this.noOfItemsToShowInitially = 9;
        this.itemsToShow = this.prodDetails.slice(0, this.noOfItemsToShowInitially);
        window.scrollTo(0, 0);
      });
  }

  onItemChange(value) {
    this.shortBy = value;
    this.fetchDiscount();
    //console.log(" Value is : ", value);
  }

  fetchColorFilter(value) {
    //alert(value);
    this.colorFilter = value;
    this.fetchDiscount();
    //console.log(" Value is : ", value);
  }

  onItemChangePopup() {
    this.userService.getDiscountData(this.selectedId, this.minValue, this.maxValue, this.markedBelow25, this.markedUpto25, this.markedAbove30, this.PopshortBy, this.colorFilter)
      .subscribe(data => {
        this.prodDetails = data;
        this.noOfItemsToShowInitially = 9;
        this.itemsToShow = this.prodDetails.slice(0, this.noOfItemsToShowInitially);
        window.scrollTo(0, 0);
      });
  }


  onRangeValueChangePopup(event: any) {
    this.popminValue = event.first;
    this.popmaxValue = event.second;
  }

  toggleVisibilityBelow25pop(e) {
    this.popmarkedBelow25 = e.checked;
  }

  toggleVisibilityUpto25pop(e) {
    this.popmarkedUpto25 = e.checked;
  }

  toggleVisibilityAbove30pop(e) {
    this.popmarkedAbove30 = e.checked;
  }

  onFilterChangePopup() {
    this.userService.getDiscountData(this.selectedId, this.popminValue, this.popmaxValue, this.popmarkedBelow25, this.popmarkedUpto25, this.popmarkedAbove30, this.PopshortBy, this.colorFilter)
      .subscribe(data => {
        this.prodDetails = data;
        this.noOfItemsToShowInitially = 9;
        this.itemsToShow = this.prodDetails.slice(0, this.noOfItemsToShowInitially);
        window.scrollTo(0, 0);
      });
  }

  addToWishList(productDetail: ProductDetails, i) {
    const user = this.tokenStorage.getUser();
    productDetail.username = user.username;
    this.userService.addToWishLst(productDetail).subscribe(data => {
      //console.log(data);
      //this.resetData();
      this.toggle3 = true;
      //this.selectedIndex = i;
      this.selectedIndex.push(i);
      const options = { opacity: 1 };
      this.toastrService.success('Added to WishList Successfully', '', options);
    }, err => {
      console.log(err);
      this.errorMessage = err.error.message;
      const options = { opacity: 1 };
      this.toastrService.error(this.errorMessage, '', options);
    }
    )
  }

  removeToWishList(productDetail: ProductDetails, i) {
    const user = this.tokenStorage.getUser();
    productDetail.username = user.username;
    this.userService.removeToWishLst(productDetail).subscribe(data => {
      //console.log(data);
      //this.resetData();
      this.toggle3 = true;
      //this.selectedIndex = i;origScores.push( 40 );
      this.selectedIndex.push(i);
      const options = { opacity: 1 };
      this.toastrService.success('Removed from WishList Successfully', '', options);
    }, err => {
      console.log(err);
      this.errorMessage = err.error.message;
      const options = { opacity: 1 };
      this.toastrService.error(this.errorMessage, '', options);
    }
    )
  }

  resetData() {
    this.userService.getDiscountData(this.selectedId, this.popminValue, this.popmaxValue, this.popmarkedBelow25, this.popmarkedUpto25, this.popmarkedAbove30, this.PopshortBy, this.colorFilter)
      .subscribe(data => {
        this.prodDetails = data;
        this.noOfItemsToShowInitially = 9;
        this.itemsToShow = this.prodDetails.slice(0, this.noOfItemsToShowInitially);
      });
  }


  addToCartProdCategory(productDetail: ProductDetails, i) {
    const user = this.tokenStorage.getUser();
    productDetail.username = user.username;
    this.userService.addToCartProdCategory(productDetail).subscribe(data => {
      //console.log(data);
      this.eventEmitterService.onFirstComponentButtonClick();
      this.toggle2 = true;
      //this.selectedIndex = i;
      this.selectedIndexCart.push(i);
      const options = { opacity: 1 };
      this.toastrService.success('Product added Successfully', '', options);
    }, err => {
      console.log(err);
      this.errorMessage = err.error.message;
      const options = { opacity: 1 };
      this.toastrService.error(this.errorMessage, '', options);
    }
    )
  }

  clicked(event) {
    event.target.classList.add('grey-text'); // To ADD
    event.target.classList.remove('red-text'); // To Remove

  }

  @HostListener('scroll', ['$event'])
  onScroll(event) {
    // alert("hello");
    if (this.noOfItemsToShowInitially <= this.prodDetails.length) {
      // Update ending position to select more items from the array
      this.noOfItemsToShowInitially += this.itemsToLoad;
      this.itemsToShow = this.prodDetails.slice(0, this.noOfItemsToShowInitially);
      console.log("scrolled");
      //alert("hiiiiiii");
    } else {
      this.isFullListDisplayed = true;
    }
  }

  buyNow(productDetail: ProductDetails) {
    const user = this.tokenStorage.getUser();
    productDetail.username = user.username;
    this.userService.addToCartProdCategory(productDetail).subscribe(data => {
      //console.log(data);
      this.router.navigate(['/orderplace', { buyNowId: productDetail.productId }]);
      this.eventEmitterService.onFirstComponentButtonClick();
    }, err => {
      console.log(err);
      this.errorMessage = err.error.message;
      const options = { opacity: 1 };
      this.toastrService.error(this.errorMessage, '', options);
    }
    )
  }

  fetchBack(): void {
    event.preventDefault();
    this.router.navigate(['/home']);
    //console.log(event);
  };

}






