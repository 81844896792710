import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { UrlComponent } from './url.component';
import { UserCartComponent } from './user/usercart.component';
import { AppRoutingModule } from './app.routing.module';
import { UserService } from './user/user.service';
import { OrderService } from './OrderDetails/order.service';
import { adminOrderService } from './services/adminOrder.service';
import { HttpClientModule } from "@angular/common/http";
import { AddUserComponent } from './user/add-user.component';
import { DisplaylistSss } from './user/displaylist.componentSss';
import { DisplaylistProductDetails } from './user/displaylist.componentProductDetails';
import { DetailsUploadComponent } from './details-upload/details-upload.component';
import { LoginPopupComponent } from './components/login-popup.component';
import { CustomerService } from './CustomerModule/customer.service';
import { PlaceOrder } from './PlaceOrder/placeOrder.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { httpInterceptorProviders } from './auth/auth-interceptor';
import { AuthService } from './services/auth.service';
import { HttpModule } from '@angular/http';
import { AccountService } from './services/account.service';
import { UrlPermission } from './urlPermission/url.permission';
import { DialogComponent } from './dialog/dialog.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { MDBBootstrapModulesPro, ToastModule } from 'ng-uikit-pro-standard';
import { HttpClient } from "@angular/common/http";
import { LoadingScreenComponent } from "./loading-screen.component";
import { LazyImgDirective } from "./Directive/LazyImgDirective";
import { PageNotFoundComponent } from './page-not-found.component';
import { RouterModule } from '@angular/router';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { Injectable } from '@angular/core';
import { NotFoundComponent } from './not-found.component';



declare var Hammer: any;

@Injectable({
  providedIn: 'root' // or 'any', depending on your use case
})
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any> {
    'pan': { direction: Hammer.DIRECTION_All },
    'swipe': { direction: Hammer.DIRECTION_VERTICAL },
  };

  buildHammer(element: HTMLElement) {
    const mc = new Hammer(element, {
      touchAction: 'auto',
          inputClass: Hammer.SUPPORT_POINTER_EVENTS ? Hammer.PointerEventInput : Hammer.TouchInput,
          recognizers: [
            [Hammer.Swipe, {
              direction: Hammer.DIRECTION_HORIZONTAL
            }]
          ]
    });
    return mc;
  }
}

@NgModule({
  declarations: [
    AppComponent,
    UrlComponent,
    UserCartComponent,
    AddUserComponent,
    DetailsUploadComponent,
    DisplaylistSss,
    DisplaylistProductDetails,
    DialogComponent,
    PlaceOrder,
    LoginPopupComponent,
    LoadingScreenComponent,
    LazyImgDirective,
    PageNotFoundComponent,
    NotFoundComponent
    //PaymentStatus

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    HttpModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastModule.forRoot(),
    MDBBootstrapModulesPro.forRoot(),
    RouterModule,
    

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],

  providers: [UserService, OrderService, adminOrderService, CustomerService, AuthService, AccountService, UrlPermission, httpInterceptorProviders,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    }],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
} DetailsUploadComponent