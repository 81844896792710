import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  constructor(private router: Router) { }

  signOut() {
    window.sessionStorage.clear();
  }

  public saveToken(token: string) {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string {
    return sessionStorage.getItem(TOKEN_KEY);
  }

  public saveUser(user) {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser() {
    return JSON.parse(sessionStorage.getItem(USER_KEY));
  }

   // Check if the JWT token exists in local storage
  //  getToken() {
  //   return localStorage.getItem('jwt_token');
  // }

  // Log out the user
  logout() {
    sessionStorage.removeItem(TOKEN_KEY); // Clear token
    sessionStorage.removeItem(USER_KEY); // Clear user data (if any)
    // this.router.navigate(['/home']); // Redirect to login page
    // window.location.reload();
    this.router.navigate(['/home'])
      .then(() => {
        window.location.reload();
      });
  }

  // Check if the JWT token is expired
  isTokenExpired(token: string): boolean {
    const decoded = this.decodeToken(token);
    if (decoded && decoded.exp) {
      return decoded.exp < Date.now() / 1000;
    }
    return false;
  }

  // Decode the JWT token
  decodeToken(token: string) {
    const payload = token.split('.')[1];
    return JSON.parse(atob(payload));
  }


}
