<div class="my-5"></div>
<mdb-card>
  <mdb-card-body>
    <link rel="canonical" href="https://www.craftsharbour.com/" />
    <!--Grid row-->
    <div class="row" *ngIf="!paymentId">
      <!--Grid column-->
      <div class="col-lg-8 mb-4">
        <!-- Nav Tabs -->
        <mdb-stepper #stepper [linear]="true">
          <mdb-step name="Shipping Address" [stepForm]="firstFormGroup">
            <form class="container" name="form" (ngSubmit)="f.form.valid" #f="ngForm" novalidate>
              <!--Grid row-->
              <div class="row">
                <!--Grid column-->
                <div class="col-md-6">
                  <!--firstName-->
                  <div class="md-form">
                    <label for="shippingName" class="">First name</label>
                    <input mdbInput type="text" id="shippingName" name="shippingName" (keyup)="valuechange($event)"
                      class="form-control form-control-sm" [(ngModel)]="shippingAddress.shippingName" required
                      minlength="3" maxlength="20" #shippingName="ngModel" autocomplete="off">
                  </div>
                </div>
                <!--Grid column-->

                <!--Grid column-->
                <div class="col-md-6">
                  <!--lastName-->
                  <div class="md-form">
                    <label for="shippingLastname" class="">Last name</label>
                    <input mdbInput type="text" id="shippingLastname" name="shippingLastname"
                      (keyup)="valuechange($event)" class="form-control form-control-sm"
                      [(ngModel)]="shippingAddress.shippingLastname" required minlength="3" maxlength="20"
                      #shippingLastname="ngModel" autocomplete="off">
                  </div>
                </div>
                <!--Grid column-->
              </div>
              <!--Grid row-->

              <!--Grid row-->
              <div class="row">
                <!--Grid column-->
                <div class="col-md-6">
                  <!--firstName-->
                  <div class="md-form">
                    <label for="email" class="">Email</label>
                    <input mdbInput type="email" disabled id="email" name="email" (keyup)="valuechange($event)"
                      class="form-control disabled form-control-sm" [(ngModel)]="shippingAddress.email" required
                      minlength="3" maxlength="200" #email="ngModel" autocomplete="off">
                  </div>
                </div>
                <!--Grid column-->

                <!--Grid column-->
                <div class="col-md-6">
                  <!--lastName-->
                  <div class="md-form">
                    <label for="shippingMobileNo" class="">Mobile No.</label>
                    <input mdbInput type="number" id="shippingMobileNo" name="shippingMobileNo"
                      (keyup)="valuechange($event)" class="form-control form-control-sm"
                      [(ngModel)]="shippingAddress.shippingMobileNo" required mdbValidate minlength="10" maxlength="10"
                      #shippingMobileNo="ngModel" autocomplete="off">
                  </div>
                </div>
                <!--Grid column-->

              </div>
              <!--Grid row-->

              <!--address-->
              <div class="md-form">
                <label for="shippingStreet" class="">Full Address</label>
                <input mdbInput type="text" id="shippingStreet" name="shippingStreet" (keyup)="valuechange($event)"
                  class="form-control mb-4 form-control-sm" [(ngModel)]="shippingAddress.shippingStreet" required
                  minlength="10" maxlength="100" #shippingStreet="ngModel" autocomplete="off">
              </div>

              <!--Grid row-->

              <!--Grid row-->
              <div class="row">
                <!--Grid column-->
                <div class="col-lg-4 col-md-6">
                  <div class="md-form mt-3">
                    <mdb-select [options]="optionsSelectCountry" (selected)="optionSelectCountry($event)"
                      placeholder="Select Country" name="shippingCountry" [(ngModel)]="shippingAddress.shippingCountry"
                      #shippingCountry>
                    </mdb-select>
                  </div>
                </div>
                <!--Grid column-->

                <!--Grid column-->
                <div class="col-lg-4 col-md-6">
                  <div class="md-form mt-3">
                    <mdb-select [options]="optionsSelectState" placeholder="Select State" name="shippingState"
                      (selected)="optionSelectState($event)" [(ngModel)]="shippingAddress.shippingState" #shippingState>
                    </mdb-select>
                  </div>
                </div>
                <!--Grid column-->

                <!--Grid column-->
                <div class="col-lg-4 col-md-6">
                  <div class="md-form mt-3">
                    <label for="shippingPinCode" class="">Pin</label>
                    <input mdbInput type="number" class="form-control form-control-sm" id="shippingPinCode"
                      (keyup)="valuechange($event)" name="shippingPinCode" [(ngModel)]="shippingAddress.shippingPinCode"
                      required mdbValidate minlength="6" maxlength="6" #shippingPinCode="ngModel" autocomplete="off">
                  </div>
                </div>
                <!--Grid column-->
              </div>

              <div class="row">
                <div class="col-md-6"></div>
                <div class="col-md-6">
                  <div class="md-form mt-3">
                    <input mdbInput type="text" hidden class="form-control" id="check" name="check"
                      required autocomplete="off">
                    <mdb-error *ngIf="check.invalid && (check.dirty || check.touched)"><strong>Press Continue to
                        proceed</strong></mdb-error>
                  </div>
                </div>
              </div>

              <button mdbBtn type="submit" size="sm" color="primary"
                (click)="onSubmitShippingAddress()">CONTINUE</button>

              <button mdbBtn id="nextId" style="display:none" size="sm" color="primary"
                (click)="stepper.next()">NEXT</button>
              <div>
              </div>

              <div class="alert alert-warning" *ngIf="isSignUpFailed">
                {{ errorMessage }}
              </div>
              <br />
              <br />

            </form>
          </mdb-step>

          <!-- Step 2-->
          <mdb-step name="Product Summary" [stepForm]="secondFormGroup">

            <form class="container">
              <div class="row">
                <div class="col-12" *ngFor="let cart of userCarts">
                  <!--Grid row-->
                  <div class="row">
                    <!--Grid column-->
                    <div class="col-md-3 mb-4">
                      <img src={{cart.imagePath}} alt="" class="img-fluid z-depth-0" style="width:60px; height:auto;" />
                    </div>
                    <!--Grid column-->

                    <!--Grid column-->
                    <div class="col-md-7 mb-4">
                      <h5 class="mb-3 h5">{{cart.productName}}</h5>

                      <span class="text-muted">Quantity: {{cart.productQty}}</span>
                      <p class="d-inline mx-2 indigo-text">{{cart.unitPrice | currency:'INR'}} &nbsp;&nbsp;
                        <del class="text-muted">{{cart.discountCost | currency:'INR'}}</del> &nbsp;
                        <strong class="green-text font-weight-bold">{{cart.discountPercent}}% Off</strong>
                      </p>

                    </div>
                    <!-- <div class="col-md-2 mb-4">
                      <button type="button" mdbBtn color="primary" size="sm" data-toggle="tooltip" data-placement="top"
                        title="Remove item" (click)="setProdId(cart);frame.show()">X
                      </button>
                    </div> -->
                    <div class="col-md-1 col-lg-1 col-xl-1 text-end">
                      <a (click)="setProdId(cart);frame.show()" title="Remove item" style="color: #cecece;"><i class="fas fa-trash fa-lg"></i></a>
                    </div>                    
                    <!--Grid column-->

                  </div>

                  <hr>

                </div>
                <button mdbBtn size="sm" type="button" color="primary" mdbWavesEffect
                  (click)="setPaymentForm();stepper.next()">CONTINUE</button>
                <button mdbBtn size="sm" type="button" color="secondary" mdbWavesEffect
                  (click)="stepper.previous()">BACK</button>
              </div>
              <br />
              <br />
            </form>
          </mdb-step>


          <!-- STEP 3-->
          <mdb-step name="Payment" label="Step 3 label">

            <div class="row">
              <div class="col-12">
                <div class="d-block my-3">
                  <div>
                    <h5>
                      <p>Pay securely by Credit or Debit card or internet banking through Razorpay.</p>
                    </h5>
                  </div>
                  <br />
                  

                  <div class="mb-3">
                    <input name="group2" type="radio" (change)="setRozPaymentForm($event)" [checked]='true'
                      class="form-check-input with-gap" id="radioWithGap8" />
                    <label class="form-check-label" for="radioWithGap8">Payment processing partner Razorpay
                    </label>
                    <p><img src="./assets/img/razorpay.jpg" alt=""></p>
                  </div>

                  <br />

                  <div class="mb-2">
                    <input name="group2" type="radio" (change)="setCODForm($event)" class="form-check-input with-gap"
                      id="radioWithGap7" />
                    <label class="form-check-label" for="radioWithGap7">Cash on delivery (Please expect a call from our
                      team shortly to confirm your order)
                    </label>
                  </div>

                 <div *ngIf="enableOTP">
                  <p class="green-text">OTP sent to your registered email ID. Enter the code below to proceed.</p>
                  <form class="form-inline">
                      <div class="form-group md-form mb-2">
                        <input type="number" class="form-control form-control-sm" mdbInput mdbValidate id="otp"
                          name="otp" [(ngModel)]="codOtp" required minlength="4" #signupEmail="ngModel"
                          autocomplete="off" />
                        <label for="otp" class="">Enter OTP</label>                        
                      </div>                       
                      <a (click)="sendCodOtp()" class="blue-text ml-1"> Resend OTP</a>
                    </form>
                  </div>

                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <!--PAYU Form-->
                <div class="col-md-4">
                  <div class="form-group">
                    <button mdbBtn *ngIf="disablePaymentButton && !enableRozpayButton && !enableCODpayButton"
                      class="btn btn-primary btn-block" type="button" color="primary" block="false" mdbWavesEffect>
                      Place Order
                    </button>

                    <button mdbBtn *ngIf="!disablePaymentButton && !enableRozpayButton && !enableCODpayButton"
                      class="btn btn-primary btn-block" type="submit" id="confirmBtn" color="primary" block="false"
                      mdbWavesEffect>PAY</button>
                  </div>
                </div>

                <!--Razorpay Form-->
                <div class="col-md-4">
                  <!-- <div class="card card-container"> -->
                  <!-- <form name="form" (ngSubmit)="f.form.valid && onSubmitRazPay()" #f="ngForm" novalidate> -->
                    <form name="form" (ngSubmit)="onSubmitRazPay()" #f="ngForm" novalidate>
                    <div class="form-group">
                      <!--<div class="alert alert-danger" role="alert" *ngIf="error">Payment failed: {{ error }}</div>-->
                      <div class="alert alert-success" role="alert" *ngIf="paymentId">Payment Success. Payment ID: {{
                        paymentId }}</div>
                    </div>
                    <div class="form-group">
                      <!-- <label for="name">Name</label>  -->
                      <input type="text" class="form-control" hidden  name="name" [(ngModel)]="form.name" required
                        minlength="3" maxlength="20" #name="ngModel" />

                    </div>
                    <div class="form-group">
                      <!-- <label for="email">Email</label>  -->
                      <input type="text" class="form-control" hidden name="email" [(ngModel)]="form.email" required
                        #email="ngModel" />
                      <!-- <div class="alert alert-danger" role="alert" *ngIf="f.submitted && email.invalid">Email is required!</div> -->
                    </div>
                    <div class="form-group">
                      <!-- <label for="phone">Phone</label>  -->
                      <input type="number" class="form-control" hidden name="phone" [(ngModel)]="form.phone" required
                        minlength="10" maxlength="10" #phone="ngModel" />

                    </div>
                    <div class="form-group">
                      <!-- <label for="amount">Amount</label>  -->
                      <input type="number" class="form-control" hidden name="productinfo" [(ngModel)]="form.productinfo"
                        required #amount="ngModel" />

                    </div>
                    <div class="form-group">
                      <!-- <label for="amount">Amount</label>  -->
                      <input type="number" class="form-control" hidden name="amount" [(ngModel)]="form.amount" required
                        #amount="ngModel" />

                    </div>
                    <div class="form-group">
                      <button *ngIf="disablePaymentButton && enableRozpayButton" class="btn btn-primary btn-block">Razor
                        Pay</button>
                    </div>
                  </form>
                  <!-- </div> -->
                </div>
                <!-- END Razorpay Form-->


                <!--COD FORM-->
                <div class="col-md-4">
                  <form name="codform" (ngSubmit)="onSubmitCODPay()" #f="ngForm" novalidate>
                    <div class="form-group1">
                      <div class="alert alert-danger" role="alert" *ngIf="error">Order failed: {{ error }}</div>
                      <div class="alert alert-success" role="alert" *ngIf="paymentId">Payment Success. Payment ID: {{
                        paymentId }}</div>
                    </div>
                    <div class="form-group1">
                      <input type="text" class="form-control" hidden name="name" [(ngModel)]="codform.name" required
                        minlength="3" maxlength="20" #name="ngModel" />
                    </div>
                    <div class="form-group1">
                      <input type="text" class="form-control" hidden name="email" [(ngModel)]="codform.email" required
                        #email="ngModel" />
                    </div>
                    <div class="form-group1">
                      <input type="number" class="form-control" hidden name="phone" [(ngModel)]="codform.phone" required
                        minlength="10" maxlength="10" #phone="ngModel" />
                    </div>
                    <div class="form-group1">
                      <input type="number" class="form-control" hidden name="productinfo"
                        [(ngModel)]="codform.productinfo" required #amount="ngModel" />
                    </div>
                    <div class="form-group1">
                      <input type="number" class="form-control" hidden name="amount" [(ngModel)]="codform.amount"
                        required #amount="ngModel" />
                    </div>
                    <div class="form-group1">
                      <button *ngIf="disablePaymentButton && enableCODpayButton" class="btn btn-primary btn-block">COD
                        Pay</button>
                    </div>
                  </form>
                </div>
                <!--END COD FORM-->

                <hr class="mb-4" />
                <br />
                <br />

              </div>
            </div>
          </mdb-step>
        </mdb-stepper>

      </div>
      <!--Grid column-->

      <!--Grid column-->
      <div class="col-lg-4 mb-4 my-5">
        <!--Card-->
        <mdb-card>

          <!--Card content-->
          <mdb-card-body class="bg-light rounded-3">
            <h4 class="mb-4 mt-1 h5 text-center font-weight-bold">Summary</h4>

            <hr>
            <dl class="row">
              <dd class="col-sm-8">
                Total Product Price
                <tr class="text-muted" *ngFor="let cartsss of userCarts" class="small">
                  {{cartsss.productName}}&nbsp; (1)
                </tr>
              </dd>

              <dd class="col-sm-4">
                {{afterDiscountTotalCost | currency:'INR'}}
                <del class="text-muted small">{{totalCost | currency:'INR'}}</del>
              </dd>
            </dl>
            <hr>

            <dl class="row">
              <dd class="col-sm-8">
                Total Discount
              </dd>
              <dd class="col-sm-4">
                {{totalDiscountCost | currency:'INR'}}
              </dd>
            </dl>
            <hr>

            <dl class="row">
              <dd class="col-sm-8">
                Gift Wrap Any
              </dd>
              <dd class="col-sm-4">
                {{0 | currency:'INR'}}
              </dd>
            </dl>
            <hr>

            <dl class="row d-flex justify-content-between light-blue lighten-5 p-2">
              <dt class="col-sm-8">
                Total Payble Amount
              </dt>
              <dt class="col-sm-4">
                {{afterDiscountTotalCost | currency:'INR'}}
              </dt>
            </dl>
          </mdb-card-body>

        </mdb-card>
        <!--/.Card-->
      </div>
      <!--Grid column-->
    </div>


    <div class="row" *ngIf="paymentId">
      <div class="modal-dialog modal-notify modal-success" role="document">
        <!--Content-->
        <div class="modal-content">
          <!--Header-->
          <div class="modal-header justify-content-center">
            <p class="heading lead font-weight-bold">Payment Successful</p>
          </div>
          <!--Body-->
          <div class="modal-body">
            <div class="text-center">
              <mdb-icon fas icon="check" size="4x" class="mb-3 animated rotateIn"></mdb-icon>
            </div>
            <div class="container-fluid bd-example-row">
              <div class="row">
                <div class="col-md-6 ms-auto col-example">Transaction Id</div>
                <div class="col-md-6 ms-auto col-example">{{paymentId}}</div>
              </div>
              <br>
              <div class="row">
                <div class="col-md-6 ms-auto col-example">Mobile No</div>
                <div class="col-md-6 ms-auto col-example">{{paymentSuccessMobile}}</div>
              </div>
              <br>
              <div class="row">
                <div class="col-md-6 ms-auto col-example">Email Id</div>
                <div class="col-md-6 ms-auto col-example">{{paymentSuceessEmail}}</div>
              </div>
              <br>
              <div class="row">
                <div class="col-md-6 ms-auto col-example">Amount Paid</div>
                <div class="col-md-6 ms-auto col-example">{{paymentSuccessTotalAmt | currency:'INR'}}</div>
              </div>
              <br>

            </div>
          </div>
          <!--Footer-->
          <div class="modal-footer justify-content-center">
            <a type="button" mdbBtn color="success" (click)="printInvoice()" class="waves-effect" mdbWavesEffect>Invoice
            </a>
            <a type="button" mdbBtn color="success" outline="true" class="waves-effect" mdbWavesEffect
              (click)="fetchBack()">Continue Shopping</a>
          </div>
        </div>
        <!--/.Content-->
      </div>

    </div>

  </mdb-card-body>
</mdb-card>


<!-- Popup Delete Grid column -->
<div mdbModal #frame="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-notify modal-info" role="document">
    <form name="form" id="form">
      <!--Content-->
      <div class="modal-content text-center">
        <!--Header-->
        <div class="modal-header d-flex justify-content-center">
          <p class="heading">Are you sure ?</p>
        </div>

        <!--Body-->
        <div class="modal-body">
          <mdb-icon fas icon="trash" size="4x" class="animated rotateIn mb-4"></mdb-icon>
          <p>Do you want to delete this poduct from Cart ?</p>
        </div>

        <!--Footer-->
        <div class="modal-footer justify-content-center">
          <button (click)="deleteFromCartsss()" mdbBtn color="primary" class="waves-light" mdbWavesEffect>Yes</button>
          <a type="button" mdbBtn color="primary" outline="true" class="waves-light" mdbWavesEffect data-dismiss="modal"
            (click)="frame.hide()">No</a>
        </div>
      </div>
      <!--/.Content-->
    </form>
  </div>
</div>
<!-- Popup Delete Grid column -->


<router-outlet></router-outlet>