<span class="fa fa-spinner spinner" *ngIf="routerLoading"></span>

<body class="mdb-skin">
  <!--Double navigation-->
  <header>
    <!-- <link rel="canonical" href="https://www.craftsharbour.com/#/home" /> -->

    <!-- Sidebar navigation -->
    <mdb-side-nav #sidenav class="sn-bg-1" [fixed]="false">

      <mdb-navbar-brand>
        <li>
          <div class="logo-wrapper waves-light">
            <a class="nav-link" (click)="navigateHome();sidenav.hide()">
              <img src="./assets/img/chlogor.png" class="img-fluid flex-center">
              <!--<img src="./assets/img/chlogorQA.png" alt="card label" class="img-fluid flex-center">-->
            </a>

          </div>
        </li>
        <!--/. Logo -->
      </mdb-navbar-brand>

      <links>
        <!-- Side navigation links -->
        <li>
          <ul class="collapsible collapsible-accordion">
            <mdb-accordion #collapses [multiple]="false" aria-multiselectable="false">

              <!-- Simple link -->
              <mdb-accordion-item *ngIf="!isLoggedIn" class="no-collase" (click)="resetLoginPopup(); frame.show()">
                <mdb-accordion-item-head mdbWavesEffect>
                  <i class="fas fa-unlock-alt"></i> Sigin
                </mdb-accordion-item-head>
                <mdb-accordion-item-body></mdb-accordion-item-body>
              </mdb-accordion-item>

              <mdb-accordion-item *ngIf="isLoggedIn" class="no-collase" (click)="logout()">
                <mdb-accordion-item-head mdbWavesEffect>
                  <i class="fas fa-unlock-alt"></i> Logout
                </mdb-accordion-item-head>
                <mdb-accordion-item-body></mdb-accordion-item-body>
              </mdb-accordion-item>

              <!-- Simple link -->
              <mdb-accordion-item *ngIf="!isLoggedIn" class="no-collase" (click)="frame.show()">
                <mdb-accordion-item-head mdbWavesEffect>
                  <i class="far fa-user-circle"></i> My Profile
                </mdb-accordion-item-head>
                <mdb-accordion-item-body></mdb-accordion-item-body>
              </mdb-accordion-item>

              <mdb-accordion-item *ngIf="isLoggedIn" class="no-collase" routerLink="/userprofile"
                (click)="sidenav.hide()">
                <mdb-accordion-item-head mdbWavesEffect>
                  <i class="far fa-user-circle"></i> My Profile
                </mdb-accordion-item-head>
                <mdb-accordion-item-body></mdb-accordion-item-body>
              </mdb-accordion-item>

              <!-- Simple link -->
              <mdb-accordion-item *ngIf="!isLoggedIn" class="no-collase" (click)="frame.show()">
                <mdb-accordion-item-head mdbWavesEffect>
                  <i class="fas fa-shopping-basket"></i> Recent Orders
                </mdb-accordion-item-head>
                <mdb-accordion-item-body></mdb-accordion-item-body>
              </mdb-accordion-item>

              <mdb-accordion-item *ngIf="isLoggedIn" class="no-collase" routerLink="/currentOrderRouting"
                (click)="sidenav.hide()">
                <mdb-accordion-item-head mdbWavesEffect>
                  <i class="fas fa-shopping-basket"></i> Recent Orders
                </mdb-accordion-item-head>
                <mdb-accordion-item-body></mdb-accordion-item-body>
              </mdb-accordion-item>

              <!-- Simple link -->
              <mdb-accordion-item *ngIf="!isLoggedIn" class="no-collase" (click)="frame.show()">
                <mdb-accordion-item-head mdbWavesEffect>
                  <i class="fas fa-shopping-basket"></i> Previous Orders
                </mdb-accordion-item-head>
                <mdb-accordion-item-body></mdb-accordion-item-body>
              </mdb-accordion-item>

              <mdb-accordion-item *ngIf="isLoggedIn" class="no-collase" routerLink="/previousOrder"
                (click)="sidenav.hide()">
                <mdb-accordion-item-head mdbWavesEffect>
                  <i class="fas fa-shopping-basket"></i> Previous Orders
                </mdb-accordion-item-head>
                <mdb-accordion-item-body></mdb-accordion-item-body>
              </mdb-accordion-item>

              <!-- Simple link -->
              <mdb-accordion-item *ngIf="!isLoggedIn" class="no-collase" (click)="frame.show()">
                <mdb-accordion-item-head mdbWavesEffect>
                  <i class="far fa-heart"></i> Wishlist
                </mdb-accordion-item-head>
                <mdb-accordion-item-body></mdb-accordion-item-body>
              </mdb-accordion-item>

              <mdb-accordion-item *ngIf="isLoggedIn" class="no-collase" routerLink="/wishList" (click)="sidenav.hide()">
                <mdb-accordion-item-head mdbWavesEffect>
                  <i class="far fa-heart"></i> Wishlist
                </mdb-accordion-item-head>
                <mdb-accordion-item-body></mdb-accordion-item-body>
              </mdb-accordion-item>

              <hr>

              <!-- Collapsible link -->
              <mdb-accordion-item>
                <mdb-accordion-item-head mdbWavesEffect>
                  <i class="fas fa-chevron-right"></i>{{ 'Handloom Products' | translate}}
                </mdb-accordion-item-head>
                <mdb-accordion-item-body>
                  <ul>
                    <li>
                      <a (click)="fetchDetailss('BAS');sidenav.hide()" mdbWavesEffect>{{'Baluchari Sarees' |
                        translate}}</a>
                    </li>
                    <li>
                      <a (click)="fetchDetailss('SAS');sidenav.hide()" mdbWavesEffect>{{'Swarnachari Sarees' |
                        translate}}</a>
                    </li>
                    <li>
                      <a (click)="fetchDetailss('BBS'); sidenav.hide()" mdbWavesEffect>{{'Bishnupur Silk' |
                        translate}}</a>
                    </li>
                    <li>
                      <a (click)="fetchDetailss('BST'); sidenav.hide()" mdbWavesEffect>{{'Bishnupur Silk Than' |
                        translate}}</a>
                    </li>
                    <li>
                      <a (click)="fetchDetailss('GTS'); sidenav.hide()" mdbWavesEffect>{{'Tussar Print Saree' |
                        translate }}</a>
                    </li>
                    <li>
                      <a (click)="fetchDetailss('SSS'); sidenav.hide()" mdbWavesEffect>{{'Silk Stoles' | translate}}</a>
                    </li>
                    <li>
                      <a (click)="fetchDetailss('BKP'); sidenav.hide()" mdbWavesEffect>{{'Silk Kurti Piece' |
                        translate}}</a>
                    </li>
                    <!-- <li>
                      <a (click)="fetchDetailss('SJS'); sidenav.hide()" mdbWavesEffect>{{'Soft Jamdani Sarees' |
                        translate}}</a>
                    </li>
                    <li>
                      <a (click)="fetchDetailss('SHS'); sidenav.hide()" mdbWavesEffect>{{'Handloom Cotton Sarees' |
                        translate}}</a>
                    </li>
                    <li>
                      <a (click)="fetchDetailss('KHS'); sidenav.hide()" mdbWavesEffect>{{'Kantha Stitch Sarees' |
                        translate}}</a>
                    </li> -->
                  </ul>
                </mdb-accordion-item-body>
              </mdb-accordion-item>

              <!-- Collapsible link -->
              <mdb-accordion-item>
                <mdb-accordion-item-head mdbWavesEffect>
                  <i class="fas fa-chevron-right"></i> {{'Handicraft Products' | translate}}
                </mdb-accordion-item-head>
                <mdb-accordion-item-body>
                  <ul>
                    <li>
                      <a (click)="fetchDetailss('TR2');sidenav.hide()" mdbWavesEffect>{{'Terracotta Decoration' |
                        translate}}</a>
                    </li>
                    <li>
                      <a (click)="fetchDetailss('TR1');sidenav.hide()" mdbWavesEffect>{{'Terracotta Art' |
                        translate}}</a>
                    </li>
                    <li>
                      <a (click)="fetchDetailss('TR3');sidenav.hide()" mdbWavesEffect>{{'Terracotta Sculpture' |
                        translate}}</a>
                    </li>
                  </ul>
                </mdb-accordion-item-body>
              </mdb-accordion-item>

              <!-- Simple link -->
              <mdb-accordion-item class="no-collase" (click)="navigatePage('aboutUs');sidenav.hide()">
                <mdb-accordion-item-head mdbWavesEffect>
                  <i class="far fa-question-circle"></i> About Us
                </mdb-accordion-item-head>
                <mdb-accordion-item-body></mdb-accordion-item-body>
              </mdb-accordion-item>


              <!-- Simple link -->
              <mdb-accordion-item class="no-collase" (click)="navigatePage('helpfaqs');sidenav.hide()">
                <mdb-accordion-item-head mdbWavesEffect>
                  <i class="far fa-question-circle"></i> FAQs
                </mdb-accordion-item-head>
                <mdb-accordion-item-body></mdb-accordion-item-body>
              </mdb-accordion-item>

              <!-- Simple link -->
              <mdb-accordion-item class="no-collase" (click)="navigatePage('refudCancel');sidenav.hide()">
                <mdb-accordion-item-head mdbWavesEffect>
                  <i class="fas fa-exchange-alt"></i> Return Policy
                </mdb-accordion-item-head>
                <mdb-accordion-item-body></mdb-accordion-item-body>
              </mdb-accordion-item>

              <!-- Simple link -->
              <mdb-accordion-item class="no-collase" (click)="navigatePage('privacypolicy');sidenav.hide()">
                <mdb-accordion-item-head mdbWavesEffect>
                  <i class="fas fa-shield-alt"></i> Privacy policy
                </mdb-accordion-item-head>
                <mdb-accordion-item-body></mdb-accordion-item-body>
              </mdb-accordion-item>

              <!-- Simple link -->
              <mdb-accordion-item class="no-collase" (click)="navigatePage('termofuse');sidenav.hide()">
                <mdb-accordion-item-head mdbWavesEffect>
                  <i class="far fa-file-alt"></i> Tersm of Use
                </mdb-accordion-item-head>
                <mdb-accordion-item-body></mdb-accordion-item-body>
              </mdb-accordion-item>

                <!-- Simple link -->
                <mdb-accordion-item class="no-collase" (click)="navigatePage('contactUs');sidenav.hide()">
                  <mdb-accordion-item-head mdbWavesEffect>
                    <i class="far fa-file-alt"></i> Contact Us
                  </mdb-accordion-item-head>
                  <mdb-accordion-item-body></mdb-accordion-item-body>
                </mdb-accordion-item>

            </mdb-accordion>
          </ul>
        </li>
        <!--/. Side navigation links -->
      </links>
      <div class="sidenav-bg mask-strong"></div>
    </mdb-side-nav>
    <!--/. Sidebar navigation -->

    <!-- Navbar -->
    <mdb-navbar SideClass="navbar fixed-top navbar-toggleable-md navbar-expand-lg scrolling-navbar double-nav"
      [containerInside]="false">

      <navlinks class="navbar-container d-block d-xl-none">
        <!-- SideNav slide-out button -->
        <div class="float-left">
          <a (click)="sidenav.show()" class="button-collapse hidden-nav-button-collapse">
            <i class="fas fa-bars"></i>
          </a>
        </div>
        <!--/. SideNav slide-out button -->
      </navlinks>

      <mdb-navbar-brand>
        <!-- Breadcrumb-->
        <div class="breadcrumbs breadcrumbs-hidden-nav breadcrumb-dn mr-auto">
          <a class="nav-link" (click)="navigateHome()">
            <img src="./assets/img/chlogor.png" class="img-fluid flex-center">
            <!--<img src="./assets/img/chlogorQA.png" alt="test" class="img-fluid flex-center">-->
          </a>
        </div>

        <!--/. Breadcrumb-->
      </mdb-navbar-brand>


      <navlinks>
        <ul class="nav navbar-nav float-left">
          <!-- Dropdown -->
          <li class="nav-item dropdown d-lg-block d-md-block d-none" dropdown>
            <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
              {{'Handloom Products' | translate }}<span class="caret"></span></a>
            <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
              <a class="dropdown-item waves-light" mdbWavesEffect (click)="fetchDetailss('BAS')">{{
                'Baluchari Sarees' | translate }}</a>
              <a class="dropdown-item waves-light" mdbWavesEffect (click)="fetchDetailss('SAS')">{{
                'Swarnachari Sarees' | translate }}</a>
              <a class="dropdown-item waves-light" mdbWavesEffect (click)="fetchDetailss('BBS')">{{
                'Bishnupur Silk' | translate }}</a>
              <a class="dropdown-item waves-light" mdbWavesEffect (click)="fetchDetailss('BST')">{{
                'Bishnupur Silk Than' | translate }}</a>
              <a class="dropdown-item waves-light" mdbWavesEffect (click)="fetchDetailss('GTS')">{{
                'Tussar Print Saree' | translate }}</a>
              <a class="dropdown-item waves-light" mdbWavesEffect (click)="fetchDetailss('SSS')">{{ 'Silk
                Stoles' | translate }}</a>
              <a class="dropdown-item waves-light" mdbWavesEffect (click)="fetchDetailss('BKP')">{{ 'Silk Kurti Piece' |
                translate }}</a>
              <!-- <a class="dropdown-item waves-light" mdbWavesEffect (click)="fetchDetailss('SJS')">{{ 'Soft
                Jamdani Sarees' | translate }}</a>
              <a class="dropdown-item waves-light" mdbWavesEffect (click)="fetchDetailss('SHS')">{{ 'Handloom
                Cotton Sarees' | translate }}</a>
              <a class="dropdown-item waves-light" mdbWavesEffect (click)="fetchDetailss('KHS')">{{ 'Kantha
                Stitch Sarees' | translate }}</a> -->

            </div>
          </li>

          <li class="nav-item dropdown d-lg-block d-md-block d-none" dropdown>
            <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
              {{'Handicraft Products' | translate}}<span class="caret"></span></a>
            <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
              <a class="dropdown-item waves-light" mdbWavesEffect (click)="fetchDetailss('TR2')">{{'Terracotta
                Decoration' | translate}}</a>
              <a class="dropdown-item waves-light" mdbWavesEffect (click)="fetchDetailss('TR1')">{{'Terracotta Art' |
                translate}}</a>
              <a class="dropdown-item waves-light" mdbWavesEffect (click)="fetchDetailss('TR3')">{{'Terracotta
                Sculpture' | translate}}</a>

            </div>
          </li>



        </ul>
        <!-- Links -->
        <!-- <div class="dropdown" (mouseenter)="isHovered = true" (mouseleave)="isHovered = false">
          <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" aria-expanded="false">
            Hover to open menu
          </button>
          <ul class="dropdown-menu" [class.show]="isHovered" aria-labelledby="dropdownMenuButton">
            <li><a class="dropdown-item waves-light" href="#">Action</a></li>
            <li><a class="dropdown-item waves-light" href="#">Another action</a></li>
            <li><a class="dropdown-item waves-light" href="#">Something else here</a></li>
          </ul>
        </div> -->
        <!-- Links -->
        <ul class="navbar-nav ml-auto nav-flex-icons">

          <!--Search form-->
          <li class="nav-item d-lg-block d-md-block d-none" style="padding-right: 20px; margin-top: -28px;
    margin-bottom: -25px;">
                        
            <div class="input-group">             
                <!-- <input #input hidden style="width: 100px;" type="search" id="form1" class="form-control"
                placeholder="Search for Silk Sarees, Stole and more" />                 -->
              
              <mdb-autocomplete [placeholder]="searchPlaceholder" name="productCatagory1" style="width: 400px; color: black;"
              [(ngModel)]="searchValue"  class="custom-autocomplete" 
              [datasource]="dataService" [minSearchLength]="1" (search)="onSearch($event)" >
            </mdb-autocomplete>
              <button type="button" class="btn btn-light btn-sm" style="margin-top: 35px; margin-bottom: 30px;" mdbRipple 
              (click)="search()">
                <i class="fas fa-search"></i>
              </button>
            </div>
            <!-- <a class="nav-link">
              <div class="dropdown" color="secondary" style="padding-top: 6px" mdbDropdown [(autoClose)]="isSelect">
                <mdb-icon fas size="sm" icon="search" (click)="openSelect()" class="dropdown-toggle" mdbDropdownToggle
                  class="waves-effect" mdbWavesEffect>
                </mdb-icon>
                <div class="dropdown-menu dropdown-primary dropdown-menu-left " style="width: 330px;">
                  <div class="row d-flex justify-content-end">
                    <div style="padding-right: 10px">
                      <mdb-icon fas icon="window-close" (click)="closeSelect()" aria-hidden="true"></mdb-icon>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-inline" style="width: 230px; padding-left: 15px">
                      <mdb-autocomplete placeholder="Search Products" class="grey-text d-flex" name="productCatagory"
                        [appendToBody]="true" [(ngModel)]="selectedProductType" #productCatagory="ngModel"
                        [datasource]="dataService" [minSearchLength]="0" required>
                      </mdb-autocomplete>
                    </div>
                    <div style="margin-left: 40px;">&nbsp;</div>
                    <div style="margin-left: 20px; padding-top: 40px">
                      <mdb-icon fas icon="search" aria-hidden="true"></mdb-icon>
                    </div>
                  </div>
                </div>
              </div>
            </a> -->


          </li>

          <!-- <li class="nav-item d-lg-none d-md-none">
            <a class="nav-link">
              <div class="dropdown" color="secondary" style="padding-top: 3px" mdbDropdown [(autoClose)]="isSelect">
                <mdb-icon fas icon="search" (click)="openSelect()" class="dropdown-toggle" mdbDropdownToggle
                  class="waves-effect" mdbWavesEffect>
                </mdb-icon>
                <div class="dropdown-menu dropdown-primary dropdown-menu-right " style="width: 330px;">
                  <div class="row d-flex justify-content-end">
                    <div style="padding-right: 10px">
                      <mdb-icon fas icon="window-close" (click)="closeSelect()" aria-hidden="true"></mdb-icon>
                     
                    </div>
                  </div>
                  <div class="row">
                    
                    <div class="form-inline" style="width: 230px; padding-left: 15px">
                     
                      <mdb-autocomplete placeholder="Search Products" class="grey-text d-flex" name="productCatagory"
                        [appendToBody]="true" [(ngModel)]="selectedProductType" #productCatagory="ngModel"
                        [datasource]="dataService" [minSearchLength]="0" required>
                      </mdb-autocomplete>
                    </div>
                    <div style="margin-left: 40px;">&nbsp;</div>
                    <div style="margin-left: 20px; padding-top: 40px">
                      <mdb-icon fas icon="search" aria-hidden="true"></mdb-icon>
                    </div>

                  
                  </div>
                </div>
              </div>

            </a>
          </li> -->


          <!-- Dropdown -->
          <li class="nav-item dropdown" dropdown>
            <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
              {{ 'Language' | translate }}<span class="caret"></span></a>
            <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
              <a class="nav-link dropdown-item waves-light" (click)="switchLanguage('en')">English</a>
              <div class="divider dropdown-divider"></div>
              <a class="nav-link dropdown-item waves-light" (click)="switchLanguage('bn')">Bengali-বাংলা</a>
              <div class="divider dropdown-divider"></div>
              <a class="nav-link dropdown-item waves-light" (click)="switchLanguage('hn')">Hindi-हिंदी</a>
            </div>
          </li>

          <li class="nav-item">
            <a class="nav-link" (click)="navigateHome();sidenav.hide()" aria-label="Craftsharbour Home Page">
            <!-- <a class="nav-link" routerLink="/home" aria-label="Craftsharbour Home Page"> -->
              <mdb-icon fas icon="home"></mdb-icon>
            </a>
          </li>



          <li class="nav-item" *ngIf="!isLoggedIn">
            <a class="nav-link" (click)="frame.show()">
              <mdb-icon fas icon="shopping-cart"></mdb-icon>
              <mdb-badge pill="true" color="green" class="animated pulse infinite"
                style="position: relative;top: -12px;margin-left: -14px;">
                {{cartCount}}
              </mdb-badge>
            </a>
          </li>

          <li class="nav-item" *ngIf="isLoggedIn">
            <a class="nav-link" routerLink="/cart">
              <mdb-icon fas icon="shopping-cart"></mdb-icon>
              <mdb-badge pill="true" color="green" class="animated pulse infinite"
                style="position: relative;top: -12px;margin-left: -14px;">
                {{cartCount}}
              </mdb-badge>
            </a>
          </li>

          <li class="nav-item d-lg-block d-md-block d-none" *ngIf="isLoggedIn">
            <a routerLink="/userprofile" (click)="sidenav.hide()" class="nav-link waves-light"
              mdbWavesEffect>{{firstName}}</a>
          </li>


          <!-- <li><div>
          {{ 'Intro' | translate }}
        </div>
         </li>-->

          <li class="nav-item">
            <a class="nav-link">
              <div class="btn-group" mdbDropdown>
                <mdb-icon fas icon="user-circle" classInside="dropdown-toggle" mdbDropdownToggle class="waves-effect"
                  mdbWavesEffect>
                </mdb-icon>
                <div class="dropdown-menu dropdown-menu-right dropdown-primary">
                  <!--<a class="nav-link dropdown-item waves-light" mdbWavesEffect routerLink="/logins">Login</a>-->
                  <a *ngIf="!isLoggedIn" class="nav-link dropdown-item waves-light" mdbWavesEffect
                    (click)="resetLoginPopup(); frame.show()">Login</a>
                  <a *ngIf="isLoggedIn" class="nav-link dropdown-item waves-light" mdbWavesEffect
                    routerLink="/userprofile">My
                    Profile</a>
                  <div *ngIf="isLoggedIn" class="divider dropdown-divider"></div>
                  <a *ngIf="isLoggedIn" class="nav-link dropdown-item waves-light" mdbWavesEffect
                    routerLink="/currentOrderRouting">Recent
                    Orders</a>
                  <div *ngIf="isLoggedIn" class="divider dropdown-divider"></div>
                  <a *ngIf="isLoggedIn" class="nav-link dropdown-item waves-light" mdbWavesEffect
                    routerLink="/previousOrder">Previous
                    Orders</a>
                  <div *ngIf="isLoggedIn" class="divider dropdown-divider"></div>
                  <a *ngIf="isLoggedIn" class="nav-link dropdown-item waves-light" mdbWavesEffect
                    routerLink="/wishList">Wishlist</a>


                  <div *ngIf="isLoggedIn" class="divider dropdown-divider"></div>
                  <a *ngIf="showAdminBoard" class="nav-link dropdown-item waves-light" mdbWavesEffect
                    routerLink="/userReview">User
                    Reviews</a>
                  <a *ngIf="showAdminBoard" class="nav-link dropdown-item waves-light" mdbWavesEffect
                    routerLink="/adminOrder">Admin
                    Order</a>
                  <a *ngIf="showAdminBoard" class="nav-link dropdown-item waves-light" mdbWavesEffect
                    routerLink="/adminProducts">
                    adminProducts</a>
                  <a *ngIf="showAdminBoard || showModeratorBoard" class="nav-link dropdown-item waves-light"
                    mdbWavesEffect routerLink="/vendorProduct">Vendor
                    ProductList</a>
                  <div *ngIf="isLoggedIn" class="divider dropdown-divider"></div>
                  <a *ngIf="isLoggedIn" class="nav-link dropdown-item waves-light" mdbWavesEffect
                    (click)="logout()">Logout</a>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </navlinks>
    </mdb-navbar>
    <!--/. Navbar -->

  </header>
  <!--/.Double navigation-->

  <!-- Hidden Button-->
  <button mdbBtn id="OpenLoginPopup" style="display:none" (click)="frame.show()"></button>
  <!-- End Hidden Button-->
  <!--/.Login POPUP-->

  <div class="fixed-action-btn">
    <a href="https://wa.me/919339067053/?text=Hi! Thanks for reaching out to Craftsharbour. Our team will work to respond to you within the next 6 hours."
      target="_blank">
      <button class="btn btn-floating light-green waves-light"
        style="bottom: 45px; right: 24px; background-color: #25D366" mdbWavesEffect size="sm" id="whatsApp"
        aria-label="Name">
        <mdb-icon fab icon="whatsapp"></mdb-icon>
      </button>
    </a>
  </div>


  <div style="overflow: auto" mdbModal #frame="mdbModal" [config]="{backdrop: 'static'}" class="modal fade top"
    id="frameModalTop" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <!--Content-->

      <div class="modal-content form-elegant">
        <!--<mdb-spinner spinnerColor="blue" *ngIf="loading" id="spinnerloading" spinnerType="small" class="float-right"></mdb-spinner>-->
        <!--<mdb-spinner spinnerColor="white" *ngIf="loading" id="spinnerloading" spinnerType="small"></mdb-spinner>-->
        <!--Header-->
        <div class="modal-header text-center">
          <h3 *ngIf="!show && !forgotpwd" class="modal-title w-100 dark-grey-text font-weight-bold my-3"
            id="myModalLabel">
            <img src="./assets/img/Chatlogo.png" alt="avatar" height="35" width="35" class="mr-2 ml-0">
            <strong>Login</strong>
          </h3>

          <h3 *ngIf="show && !forgotpwd" class="modal-title w-100 dark-grey-text font-weight-bold my-3"
            id="myModalLabel2">
            <strong>Sign up to get started</strong>
          </h3>

          <h3 *ngIf="forgotpwd" class="modal-title w-100 dark-grey-text font-weight-bold my-3" id="myModalLabel3">
            <strong>Forgot Password</strong>
          </h3>


          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <!--Body-->
        <div class="modal-body mx-4" *ngIf="!show && !forgotpwd">
          <!--Body-->

          <form *ngIf="!isLoggedIn && !isContinue" #aForm name="form" (ngSubmit)="f.form.valid && onSubmit()"
            #f="ngForm" novalidate>
            <div class="form-group md-form mb-5">

              <input type="email" class="form-control form-control-sm" id="username" mdbInput mdbValidate
                name="username" [(ngModel)]="form.username" required email #username="ngModel" autocomplete="off" />
              <label for="username" class="">Your Email</label>

              <div class="alert alert-danger" role="alert" *ngIf="f.submitted && username.invalid">
                <div *ngIf="username.errors.required">Email is required</div>
              </div>
            </div>
            <div class="form-group md-form pb-3">

              <input type="password" id="password" class="form-control form-control-sm" mdbInput mdbValidate
                name="password" [(ngModel)]="form.password" required minlength="6" #password="ngModel" />
              <label for="password" class="">Your Password</label>

              <div class="alert alert-danger" role="alert" *ngIf="f.submitted && password.invalid">
                <div *ngIf="password.errors.required">Password is required</div>
                <div *ngIf="password.errors.minlength">
                  Password must be at least 6 characters
                </div>
              </div>



              <p class="font-small blue-text d-flex justify-content-end">Forgot
                <a (click)="setFocus('username'); toggleForgotPwd()" class="blue-text ml-1"> Password?</a>

              </p>
            </div>


            <div class="form-group text-center mb-3">
              <button class="z-depth-1a waves-light" mdbBtn gradient="blue" block="true" rounded="true" mdbWavesEffect>
                <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Login
                <!--<mdb-spinner spinnerColor="white" *ngIf="loading" id="spinnerloading" spinnerType="small"></mdb-spinner>
                <span *ngIf="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>-->

              </button>
            </div>
            <div class="form-group ">
              <div class="alert alert-danger" role="alert" *ngIf="f.submitted && isLoginFailed">
                {{ errorMessage }}
              </div>
            </div>
            <div class="form-group ">
              <div class="alert alert-danger" role="alert" *ngIf="isInvalidForgotMail">
                {{ errorMessage }}
              </div>
            </div>
          </form>

          <div class="alert alert-success" *ngIf="isLoggedIn">
            <!--  Logged in as {{ roles }}.-->
            Welcome to craftsharbour.com
          </div>

          <!--End Body-->



          <!--Footer-->
          <br />
          <div *ngIf="!forgotpwd" class="modal-footer mx-5 pt-3 mb-1">
            <p class="font-small grey-text d-flex justify-content-end">Not a member?
              <a (click)="setFocus('username'); toggle1()" class="blue-text ml-1"> Sign Up</a>
            </p>
          </div>



        </div>


        <!--Panel 8-->
        <div class="modal-body mx-4" *ngIf="show && !forgotpwd">
          <form name="form" *ngIf="!isContinue" #bForm (ngSubmit)="f.form.valid" #f="ngForm" novalidate>
            <div>
              <div class="form-group md-form mb-5">
                <input type="email" class="form-control form-control-sm" mdbInput mdbValidate id="signupEmail"
                  name="signupEmail" [(ngModel)]="form.signupEmail" required email #signupEmail="ngModel"
                  autocomplete="off" />
                <label for="signupEmail" class="">Enter Email</label>
                <div class="alert alert-danger" role="alert" *ngIf="f.submitted && signupEmail.invalid">
                  <div *ngIf="signupEmail.errors.required">Email is required</div>
                </div>
              </div>

              <span class="font-small grey-text d-flex justify-content-end">
                By continuing, you agree to craftsharbour's
              </span>
              <span class="font-small grey-text d-flex justify-content-end">
                <a (click)="navigatePage('termofuse');frame.hide()" class="blue-text">&nbsp;Terms of Use &nbsp;</a> &
                <a (click)="navigatePage('privacypolicy');frame.hide()" class="blue-text">&nbsp;Privacy
                  Policy</a></span>
              <br />
              <div class="text-center mb-3">
                <button mdbBtn gradient="blue" (click)="setFocusB('signupEmail');continueClick()" block="true"
                  rounded="true" class="z-depth-1a waves-light" mdbWavesEffect>Continue</button>
              </div>
            </div>
          </form>
          <!--Body
          <form *ngIf="!isSuccessful" name="form" (ngSubmit)="f.form.valid && onSubmitSignup()" #f="ngForm" novalidate>-->

          <form *ngIf="isContinue" name="form" (ngSubmit)="f.form.valid && onSubmitSignup()" #f="ngForm" novalidate>

            <div class="form-group md-form mb-5">
              <input type="email" class="form-control disabled" [disabled]="true" mdbInput name="signupEmail"
                [(ngModel)]="form.signupEmail" email #signupEmail="ngModel" autocomplete="off" />
              <label for="signupEmail">Enter Email</label>
            </div>

            <div class="row">

              <div class="form-group md-form col-md-6">
                <input type="password" class="form-control form-control-sm" mdbInput mdbValidate id="signupOTP"
                  name="signupOTP" [(ngModel)]="form.signupOTP" required minlength="6" maxlength="6"
                  #signupOTP="ngModel" autocomplete="off" />
                <label for="signupOTP" class="">&nbsp; &nbsp; OTP</label>
              </div>

              <div class="form-group md-form col-md-6">
                <input type="password" class="form-control form-control-sm" mdbInput mdbValidate id="Npassword"
                  name="password" [(ngModel)]="form.password" required minlength="6" #password="ngModel" />
                <label for="Npassword" class=""> &nbsp; &nbsp;Set Password</label>
              </div>

            </div>

            <div class="row">
              <div class="form-group md-form col-md-6">
                <input type="text" class="form-control form-control-sm" mdbInput mdbValidate id="firstName"
                  name="firstName" [(ngModel)]="form.firstName" required #firstName="ngModel" autocomplete="off" />
                <label for="firstName" class="">&nbsp; &nbsp; Name</label>
              </div>

              <div class="form-group md-form col-md-6">
                <input type="text" class="form-control form-control-sm" mdbInput mdbValidate id="mobileNo"
                  name="mobileNo" [(ngModel)]="form.mobileNo" required minlength="10" maxlength="10" #mobileNo="ngModel"
                  autocomplete="off" />
                <label for="mobileNo" class=""> &nbsp; &nbsp; Mobile No</label>
              </div>
            </div>

            <div class="row">
            </div>


            <div class="text-center mb-3">
              <button mdbBtn gradient="blue" block="true" rounded="true" class="z-depth-1a waves-light"
                mdbWavesEffect>Sign
                up</button>
            </div>

            <div class="alert alert-warning" *ngIf="f.submitted && isSignUpFailed">
              {{ errorMessage }}
            </div>
          </form>

          <div class="modal-footer">
            <div class="options text-right">
              <p class="font-small grey-text d-flex justify-content-end">Already have an account?
                <a (click)="backtoLoginPopup()" class="blue-text">&nbsp; Log In</a>
              </p>
            </div>
          </div>


          <div class="alert alert-success" *ngIf="isSuccessful">
            Your registration is successful!
          </div>

        </div>
        <!--Pannel8-->

        <!--ForgotPassword-->
        <div class="modal-body mx-4" *ngIf="forgotpwd">
          <!--Body-->

          <form *ngIf="!isLoggedIn" name="form" (ngSubmit)="f.form.valid && resetForgotPwd()" #f="ngForm" novalidate>

            <div class="form-group md-form mb-5">
              <input type="text" class="form-control disabled" mdbInput mdbValidate name="username"
                [(ngModel)]="form.username" required email #username="ngModel" autocomplete="off" />
              <label for="username">Your Email</label>
            </div>
            <div class="form-group md-form mb-5">
              <input type="password" class="form-control form-control-sm" mdbInput mdbValidate id="forgotOTP"
                name="forgotOTP" [(ngModel)]="form.forgotOTP" required minlength="6" maxlength="6" #forgotOTP="ngModel"
                autocomplete="off" />
              <label for="forgotOTP" class="">OTP</label>
            </div>

            <div class="form-group md-form mb-5">
              <input type="password" class="form-control form-control-sm" mdbInput mdbValidate id="forgotNewPassword"
                name="forgotNewPassword" [(ngModel)]="form.forgotNewPassword" required minlength="5"
                #forgotNewPassword="ngModel" autocomplete="off" />
              <label for="forgotNewPassword" class="">Set New Password</label>
            </div>

            <div class="form-group text-center mb-3">
              <button class="z-depth-1a waves-light" mdbBtn gradient="blue" block="true" rounded="true" mdbWavesEffect>
                Submit
              </button>
            </div>
            <div class="form-group ">
              <div class="alert alert-danger" role="alert" *ngIf="f.submitted && isNewPasswordFail">
                Validation failed: {{ errorMessage }}
              </div>
            </div>
          </form>

          <div class="alert alert-success" *ngIf="isLoggedIn">
            Logged in as {{ roles }}.
          </div>

          <!--End Body-->

          <!--<p class="font-small dark-grey-text text-right d-flex justify-content-center mb-3 pt-2"> or Sign in
            with:</p>-->
          <p class="font-small dark-grey-text text-right d-flex justify-content-center mb-3 pt-2">
            Get connected with us on social networks!</p>

          <div class="row my-3 d-flex justify-content-center">
            <!--Facebook-->
            <button type="button" mdbBtn color="white" rounded="true" class="mr-md-3 z-depth-1a waves-light"
              mdbWavesEffect>
              <mdb-icon fab icon="facebook-f" class="text-center"></mdb-icon>
            </button>
            <!--Twitter-->
            <button type="button" mdbBtn color="white" rounded="true" class="mr-md-3 z-depth-1a waves-light"
              mdbWavesEffect>
              <mdb-icon fab icon="twitter"></mdb-icon>
            </button>
            <!--Google +-->
            <button type="button" mdbBtn color="white" rounded="true" class="z-depth-1a waves-light" mdbWavesEffect>
              <mdb-icon fab icon="google-plus"></mdb-icon>
            </button>
          </div>

          <!--Footer-->
          <br />
          <div *ngIf="!forgotpwd" class="modal-footer mx-5 pt-3 mb-1">
            <p class="font-small grey-text d-flex justify-content-end">Not a member?
              <a (click)="toggle();setFocusB('signupEmail')" class="blue-text ml-1"> Sign Up</a>
            </p>
          </div>

          <div *ngIf="forgotpwd" class="modal-footer mx-5 pt-3 mb-1">
            <p class="font-small grey-text d-flex justify-content-end">Back to
              <a (click)="backtoLoginPopup()" class="blue-text ml-1"> Login</a>
            </p>
          </div>

        </div>

        <!--EndForgotPasword-->

        <!--TermsAnDConditio-->
        <div class="modal-body mx-4" *ngIf="termsCond">

        </div>
        <!--EndT&C-->

      </div>
      <!--/.Content-->
    </div>
  </div>


  <!-- Chat Window -->

  <div mdbModal #frameChat="mdbModal" class="modal fade top" id="chatModel" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel" aria-hidden="true">
    <main class="mt-n5">
      <div class="container">

        <!-- Grid row -->
        <div class="row d-flex flex-row-reverse">

          <!-- Grid column -->
          <div class="col-md-6 mb-4 d-flex flex-row-reverse">

            <mdb-card class="chat-room small-chat wide" id="myForm">
              <mdb-card-header class="white d-flex justify-content-between p-2" id="toggle" style="cursor: pointer;">
                <div class="heading d-flex justify-content-start">
                  <div class="profile-photo">
                    <img src="./assets/img/Chatlogo.png" alt="avatar" class="avatar rounded-circle mr-2 ml-0">
                    <span class="state"></span>
                  </div>
                  <div class="data">
                    <p class="name mb-0"><strong>Craftsharbour </strong></p>
                    <p class="activity text-muted mb-0">Active now</p>
                  </div>
                </div>
                <div class="icons grey-text">
                  <a class="feature">
                    <mdb-icon fas icon="video" class="mr-2"></mdb-icon>
                  </a>
                  <a class="feature">
                    <mdb-icon fas icon="phone" class="mr-2"></mdb-icon>
                  </a>
                  <a class="feature">
                    <mdb-icon fas icon="cog" class="mr-2"></mdb-icon>
                  </a>
                  <a type="button" id="closeButton" (click)="frameChat.hide()">
                    <mdb-icon fas icon="times" class="mr-2"></mdb-icon>
                  </a>
                </div>
              </mdb-card-header>
              <div class="my-custom-scrollbar">
                <mdb-card-body class="p-3">
                  <div class="chat-message">
                    <div class="media mb-3">
                      <img class="avatar rounded-circle mr-2 ml-0" src="./assets/img/Vabna.png"
                        alt="Generic placeholder image">

                      <div class="media-body">
                        <!--<p class="my-0">Hi!</p>-->
                        <p class="mb-0 text-muted">Hi, I am Vabna, your personal assistant to help you with
                          craftsharbour related queries.</p>
                        <p class="mb-0 text-muted">Lives in India</p>
                      </div>
                    </div>

                    <mdb-card bgColor="bg-light" class="rounded w-75 z-depth-0 mb-2 message-text">
                      <mdb-card-body class="p-2">
                        <mdb-card-text class="black-text">Hey! Welcome to Craftsharbour
                        </mdb-card-text>
                      </mdb-card-body>
                    </mdb-card>



                  </div>
                </mdb-card-body>
              </div>
              <mdb-card-footer class="text-muted white pt-1 pb-2 px-3">
                <input type="text" id="exampleForm2" class="form-control" placeholder="Type a message..."
                  autocomplete="off">
                <div>
                  <a>
                    <mdb-icon far icon="file-image" class="mr-2"></mdb-icon>
                  </a>
                  <a>
                    <mdb-icon far icon="laugh" class="mr-2"></mdb-icon>
                  </a>
                  <a>
                    <mdb-icon fas icon="gamepad" class="mr-2"></mdb-icon>
                  </a>
                  <a>
                    <mdb-icon fas icon="paperclip" class="mr-2"></mdb-icon>
                  </a>
                  <a>
                    <mdb-icon fas icon="camera" class="mr-2"></mdb-icon>
                  </a>
                  <a *ngIf="!isLoggedIn">
                    <button type="button" mdbBtn color="info" (click)="frame.show();frameChat.hide()" rounded="true"
                      size="sm" class="waves-effect waves-light float-right">Send</button>
                  </a>
                  <a *ngIf="isLoggedIn">
                    <button type="button" mdbBtn color="info" rounded="true" size="sm"
                      class="waves-effect waves-light float-right">Send</button>
                  </a>
                  <a>
                    <mdb-icon fas icon="thumbs-up" class="float-right"></mdb-icon>
                  </a>
                </div>
              </mdb-card-footer>

            </mdb-card>

          </div>
          <!-- Grid column -->

        </div>
        <!-- Grid row -->
      </div>
    </main>
  </div>

</body>
<router-outlet (activate)="onActivate($event)"></router-outlet>

<!-- Footer -->
<footer class="page-footer font-small blue-grey lighten-5 d-none d-sm-block my-5">

  <div style="background-color: #21d192;">
    <div class="container">

      <!-- Grid row-->
      <div class="row py-4 d-flex align-items-center">

        <!-- Grid column -->
        <div class="col-md-6 col-lg-5 text-center text-md-left mb-4 mb-md-0">
          <h6 class="mb-0">Get connected with us on social networks!</h6>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-md-6 col-lg-7 text-center text-md-right">

          <!-- Facebook -->
          <a class="fb-ic" href="https://www.facebook.com/CraftsHarbour-225930369212155/?modal=admin_todo_tour"
            target="_blank" aria-label="Craftsharbour Facebook Link">
            <mdb-icon fab icon="facebook" class="white-text mr-4"></mdb-icon>
          </a>
          <!-- Twitter -->
          <a class="tw-ic">
            <mdb-icon fab icon="twitter" class="white-text mr-4"></mdb-icon>
          </a>
          <!-- Google +-->
          <a class="gplus-ic">
            <mdb-icon fab fab icon="google-plus" class="white-text mr-4"></mdb-icon>
          </a>
          <!--Linkedin -->
          <a class="li-ic">
            <mdb-icon fab icon="linkedin" class="white-text mr-4"></mdb-icon>
          </a>
          <!--Instagram-->
          <a class="ins-ic">
            <mdb-icon fab icon="instagram" class="white-text mr-4"></mdb-icon>
          </a>

        </div>
        <!-- Grid column -->

      </div>
      <!-- Grid row-->

    </div>
  </div>

  <!-- Footer Links -->
  <div class="container text-center text-md-left mt-5">

    <!-- Grid row -->
    <div class="row mt-3 dark-grey-text">
      <!-- Grid column -->
      <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">

        <!-- Links -->
        <h6 class="text-uppercase font-weight-bold">Products</h6>
        <hr class="teal accent-3 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
        <p>
          <a class="dark-grey-text" (click)="fetchDetailss('SAS')">Swarnachari Sarees</a>
        </p>
        <p>
          <a class="dark-grey-text" (click)="fetchDetailss('BAS')">Baluchari Sarees</a>
        </p>
        <p>
          <a class="dark-grey-text" (click)="fetchDetailss('SSS')">Silk Stoles</a>
        </p>
        <p>
          <a class="dark-grey-text" (click)="fetchDetailss('TR2')">Terracota Decorations</a>
        </p>
        <p>
          <a class="dark-grey-text" (click)="fetchDetailss('TR3')">Terracota Sculpture</a>
        </p>

        <p>
          <a class="dark-grey-text" (click)="fetchDetailss('TR1')">Terracota Art</a>
        </p>

      </div>
      <!-- Grid column -->

      <!-- Grid column -->
      <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">

        <!-- Links -->
        <h6 class="text-uppercase font-weight-bold">Useful links</h6>
        <hr class="teal accent-3 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
        <p>
          <a class="dark-grey-text" (click)="navigatePage('aboutUs')">About Us</a>
        </p>
        <p>
          <a class="dark-grey-text" (click)="navigatePage('privacypolicy')">Privacy Policy</a>
        </p>
        <p>
          <a class="dark-grey-text" (click)="navigatePage('termofuse')">Terms of Use</a>
        </p>

        <!--<p>
          <a class="dark-grey-text" (click)="navigatePage('helpch')">Help</a>
        </p>-->

        <p>
          <a class="dark-grey-text" (click)="navigatePage('helpfaqs')">FAQs</a>
        </p>

        <p>
          <a class="dark-grey-text" (click)="navigatePage('craftsstory')">crafts Story</a>
        </p>

        <p>
          <a class="dark-grey-text" (click)="navigatePage('refudCancel')">Refund & Cancellation</a>
        </p>

      </div>
      <!-- Grid column -->

      <!-- Grid column -->
      <div class="col-md-4 col-lg-4 col-xl-4 mx-auto mb-md-0 mb-4">

        <!-- Links -->
        <h6 class="text-uppercase font-weight-bold">Contact</h6>
        <hr class="teal accent-3 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
        <p>
          <mdb-icon fas icon="home" class="mr-3"></mdb-icon> Bishnupur College Road, Pin-722122, INDIA
        </p>
        <p>
          <mdb-icon fas icon="envelope" class="mr-3"></mdb-icon> contactus@craftsharbour.com
        </p>
        <p>
          <mdb-icon fas icon="phone" class="green-text mr-3"></mdb-icon> + 91-9339067053
        </p>
        <!--<p>
          <mdb-icon fas icon="print" class="mr-3"></mdb-icon> + 01 234 567 88
        </p>-->
        <p>
          <mdb-icon fas icon="credit-card" class="mr-3"></mdb-icon> Payment Secured by
        </p>
        <p>
          <img src="./assets/img/visaCard.jpg" alt="visa card" class="img-fluid flex-center">
        </p>

      </div>
      <!-- Grid column -->

    </div>
    <!-- Grid row -->

  </div>
  <!-- Footer Links -->

  <!-- Copyright -->
  <div class="footer-copyright text-center text-black-50 py-3 font-weight-bold">© 2020 Copyright: Craftsharbour.com
  </div>

  <!-- Copyright -->

</footer>

<footer class="page-footer font-small blue-grey lighten-5 d-lg-none d-md-none my-5">
  <div style="background-color: #005a87;">
    <div class="container">

      <!-- Grid row-->
      <div class="row py-4 d-flex align-items-center">

        <!-- Grid column -->
        <div class="col-md-6 col-lg-5 text-center text-md-left mb-4 mb-md-0">
          <h6 class="mb-0">Get connected with us on social networks!</h6>
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-md-6 col-lg-7 text-center text-md-right">

          <!-- Facebook -->
          <a class="fb-ic" href="https://www.facebook.com/CraftsHarbour-225930369212155/?modal=admin_todo_tour"
            target="_blank">
            <mdb-icon fab icon="facebook" class="white-text mr-4"></mdb-icon>
          </a>
          <!-- Twitter -->
          <a class="tw-ic">
            <mdb-icon fab icon="twitter" class="white-text mr-4"></mdb-icon>
          </a>
          <!-- Google +-->
          <a class="gplus-ic">
            <mdb-icon fab fab icon="google-plus" class="white-text mr-4"></mdb-icon>
          </a>
          <!--Linkedin -->
          <a class="li-ic">
            <mdb-icon fab icon="linkedin" class="white-text mr-4"></mdb-icon>
          </a>
          <!--Instagram-->
          <a class="ins-ic">
            <mdb-icon fab icon="instagram" class="white-text mr-4"></mdb-icon>
          </a>
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row-->
    </div>
  </div>
  <div>
    <div class="text-center">
      <img src="./assets/img/visaCard2.jpg" alt="visa card" class="rounded">
    </div>
  </div>

  <div class="footer-copyright text-center text-black-50 py-3 font-weight-bold">© 2020 Copyright: craftsharbour.com
  </div>
</footer>
<!-- Footer -->