<div class="container-fluid" style="padding-top: 60px;">
  <link rel="canonical" href="https://www.craftsharbour.com/" />
  <!-- Section: Blog v.1 -->
  <section class="my-1">
    <!-- Grid row -->
    <div class="row" *ngFor="let prodDetail of productFullDetails">

      <!--<div class="fixed-action-btn d-lg-none d-md-none">-->
      <div class="fixed-action-btn">
        <button class="btn btn-floating blue waves-light float-left" mdbWavesEffect>
          <!--<mdb-icon fas icon="arrow-left" (click)="fetchBack(prodDetail.productId)"></mdb-icon>-->
          <mdb-icon fas icon="arrow-left" (click)="fetchBackPage()"></mdb-icon>
        </button>
      </div>

      <div class="container-fluid d-lg-none d-md-none">
        <mdb-carousel [animation]="'slide'">
          <div *ngFor="let slidesImg of slideThree">
            <mdb-carousel-item>
              <!--<img class="d-block w-100" src="https://mdbootstrap.com/img/Photos/Slides/img%20(130).jpg" alt="First slide">-->
              <img class="d-block w-100 h-100" src="{{slidesImg.img}}" alt="First slide">
            </mdb-carousel-item>
          </div>

        </mdb-carousel>
      </div>

      <!-- Grid column -->
      <div class="col-12 col-md-7 col-lg-7 d-lg-block d-md-block d-none">
        <div class="row">
          <div class="row ml-3 mr-3 pb-4 pt-1">
            <div class="col-md-12">
              <mdb-image-modal [modalImages]="slidesTwo"></mdb-image-modal>
            </div>

          </div>
        </div>
      </div>


      <!-- Grid column -->

      <!-- Grid column -->
      <div class="col-12 col-md-4 col-lg-4 border-left border-light pr-5">
        <!-- Post title -->
        <br />
        <h6 class="h5-responsive"><strong>{{prodDetail.productName}}</strong></h6>

        <h6 class="text-muted mb-2">Sold by: Craftsharbour <img src="./assets/img/freeDelivery2.jpg" class="rounded">
        </h6>

        <ul class="list-inline">
          <li class="list-inline-item h5-responsive">
            <h5 class="font-weight-bolder">{{prodDetail.productCost | currency:'INR'}}</h5>
          </li>
          <li class="list-inline-item text-muted"><del>{{prodDetail.discountCost | currency:'INR'}}</del></li>
          <li class="list-inline-item green-text">{{prodDetail.discountPercent}}% off</li>
        </ul>

        <h6 class="h6-responsive"><strong>About this item</strong></h6>
        {{prodDetail.productDescription | slice:0:100}}
        <h6><a mdbPageScroll href="#moredetails" class="small">More Details</a></h6>

        <hr>
        <h6 class="text-muted mb-2">
          <mdb-icon fas icon="info-circle">
          </mdb-icon> The order quantity for this product is limited to 1 unit per customer
        </h6>
        <div *ngIf="prodDetail.currentStock === 0">
          <h2>
            <mdb-badge color="red">OUT OF STOCK</mdb-badge>
          </h2>
        </div>
        <!-- Material auto-sizing form -->
        <div *ngIf="prodDetail.currentStock !== 0">
          <form>
            <!-- Grid row -->
            <div class="form-row align-items-center">
              <!-- Grid column -->
              <div class="col-auto">
                <!-- Material input -->
                <div class="md-form">
                  <i class="fas fa-map-marker-alt prefix"></i>
                  <input mdbInput type="number" maxlength="6" max="999999" class="form-control mb-2" autocomplete="off"
                    #inlineFormInputMD [(ngModel)]="form.pincode" name="inlineFormInputMD" placeholder="eg.110010">
                  <label for="inlineFormInputMD">Check Pincode</label>
                </div>
              </div>
              <div class="col-auto">
                <a class="stretched-link text-primary" (click)="checkPin()">Check</a>
              </div>
              <!-- Grid column -->
              <!-- Grid column -->
            </div>
            <!-- Grid row -->
          </form>
          <!-- <button *ngIf="isLoggedIn" class="btn-sm" mdbBtn color="primary" gradient="peach" rounded="true"
            data-toggle="modal" data-target="#basicExample" (click)="addToCart(prodDetail)" mdbWavesEffect>
            <mdb-icon fas icon="cart-plus" class="mr-1"></mdb-icon>Add to Cart
          </button> -->

          <button *ngIf="isLoggedIn" class="btn-sm text-white" mdbBtn color="primary" gradient="blue" 
          data-toggle="modal" data-target="#basicExample" (click)="addToCart(prodDetail)" mdbWavesEffect>
          <mdb-icon fas icon="cart-plus" class="mr-1"></mdb-icon>Add to Cart
        </button>


           <button *ngIf="!isLoggedIn" class="btn-sm" mdbBtn color="primary" gradient="peach" 
            data-toggle="modal" data-target="#basicExample" (click)="loginComponentFunction()" mdbWavesEffect>
            <mdb-icon fas icon="cart-plus" class="mr-1"></mdb-icon>Add to Cart
          </button> 
         

          <!-- <a class="btn text-white" style="background-color: #55acee;" href="#!" role="button" mdbRipple>
            <i class="fab fa-twitter me-2"></i>
            Twitter
          </a> -->

          <button *ngIf="isLoggedIn" class="btn-sm" mdbBtn (click)="firstComponentFunction()" gradient="blue"
             mdbWavesEffect (click)="buyNow(prodDetail)">
            <mdb-icon fas icon="heart" class="mr-1"></mdb-icon>Buy Now
          </button>
          <button *ngIf="!isLoggedIn" class="btn-sm" mdbBtn (click)="firstComponentFunction()" gradient="blue"
            mdbWavesEffect (click)="loginComponentFunction()">
            <mdb-icon fas icon="heart" class="mr-1"></mdb-icon>Buy Now
          </button>
        </div>
      </div>
      <!-- Grid column -->
    </div>
    <!-- Grid row -->
    <!-- <hr class="my-5">-->
    <hr>
  </section>
</div>

<!---->


<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-md-7">
      <div id="moredetails" class="my-5">
        <div class="col-12 col-md-12 my-0">
          <div class="row">

            <mdb-tabset #staticTabs [buttonClass]="'md-tabs tabs-3'" [contentClass]="'card'">
              <!--Panel 1-->
              <mdb-tab heading="Details">
                <div class="row" *ngFor="let prodDesc of productFullDetails">
                  <div class="col-12">
                    <br>
                    <span>ABOUT THIS ITEM</span>
                    <p class="text-muted">{{prodDesc.productDescription}}</p>

                    <p>PATTERN: <span class="text-muted"> {{prodDesc.productType}} </span></p>

                    <span>PRODUCT CARE</span>
                    <p class="text-muted" *ngIf="!prodDesc.productId.includes('TR')">Dry Clean Only. Avoid twisting and wringing. Always air dry the saree in shade. 
                      Iron in medium heat only on reverse. Do not bleach and expose this product to excessive heat and sunlight for long.</p>

                    <p class="text-muted" *ngIf="prodDesc.productId.includes('TR')">Do not use acids, vinegar, ammonia
                      contain solutions as it may
                      break down the sealant and not good for the surface.
                      Make use of neutral cleaner specially formulated for clay surfaces.
                      Avoid applying to much water to the surface.</p>

                    <span>PRODUCT DETAILS</span>
                    <hr>
                    <div class="row">
                      <ul class="list-inline">
                        <li class="list-inline-item text-muted">&nbsp;&nbsp;&nbsp;&nbsp;{{ 'Fabric' | translate }} :
                        </li>
                        <li class="list-inline-item ">{{prodDesc.productFebric}}</li>
                      </ul>
                      <ul class="list-inline">
                        <li class="list-inline-item text-muted">&nbsp;&nbsp;&nbsp;&nbsp;{{ 'Color' | translate }} :
                        </li>
                        <li class="list-inline-item ">{{prodDesc.productColor}}</li>
                      </ul>
                      <ul class="list-inline">
                        <li class="list-inline-item text-muted">&nbsp;&nbsp;&nbsp;&nbsp;{{ 'Dimensions' | translate }}
                          :</li>
                        <li class="list-inline-item ">{{prodDesc.productLength}}</li>
                      </ul>
                      <ul class="list-inline"
                        *ngIf="prodDesc.includedComponent !==null && prodDesc.includedComponent !== ''">
                        <li class="list-inline-item text-muted">&nbsp;&nbsp;&nbsp;&nbsp;{{ 'Included Components' |
                          translate }} :</li>
                        <li class="list-inline-item ">{{prodDesc.includedComponent}}</li>
                      </ul>
                      <ul class="list-inline">
                        <li class="list-inline-item text-muted">&nbsp;&nbsp;&nbsp;&nbsp;{{ 'Region' | translate }} :
                        </li>
                        <li class="list-inline-item ">{{prodDesc.productRegion}}</li>
                      </ul>
                    </div>
                    <span class="chip">
                      <mdb-icon fas icon="info-circle">
                      </mdb-icon> The colours may vary slightly.
                    </span>
                  </div>
                </div>
              </mdb-tab>
              <!--Panel 2-->
              <mdb-tab heading="Reviews"><span class="badge badge-danger ml-2">Total Review
                  {{userCommentLength}}</span>
                <div class="row" *ngFor="let commentList of userComment">
                  <div class="col-12">
                    <br>
                    <ul class="list-unstyled">
                      <li class="media">
                        <img class="d-flex mr-3" src="https://mdbootstrap.com/img/Photos/Others/avatar-min1.jpg"
                          alt="Generic placeholder image">
                        <div class="media-body">
                          <h5 class="mt-0 mb-2 font-weight-bold">{{commentList.commentTitle}}</h5>
                          <!--Review-->
                          <div *ngIf="commentList.userRatings == '5' ">
                            <i class="fas fa-star blue-text"> </i>
                            <i class="fas fa-star blue-text"> </i>
                            <i class="fas fa-star blue-text"> </i>
                            <i class="fas fa-star blue-text"> </i>
                            <i class="fas fa-star blue-text"> </i>
                          </div>
                          <div *ngIf="commentList.userRatings == '4' ">
                            <i class="fas fa-star blue-text"> </i>
                            <i class="fas fa-star blue-text"> </i>
                            <i class="fas fa-star blue-text"> </i>
                            <i class="fas fa-star blue-text"> </i>
                            <i class="far fa-star blue-text"> </i>
                          </div>
                          <div *ngIf="commentList.userRatings == '3' ">
                            <i class="fas fa-star blue-text"> </i>
                            <i class="fas fa-star blue-text"> </i>
                            <i class="fas fa-star blue-text"> </i>
                            <i class="far fa-star blue-text"> </i>
                            <i class="far fa-star blue-text"> </i>
                          </div>
                          <div *ngIf="commentList.userRatings == '2' ">
                            <i class="fas fa-star blue-text"> </i>
                            <i class="fas fa-star blue-text"> </i>
                            <i class="far fa-star blue-text"> </i>
                            <i class="far fa-star blue-text"> </i>
                            <i class="far fa-star blue-text"> </i>
                          </div>
                          <div *ngIf="commentList.userRatings == '1' ">
                            <i class="fas fa-star blue-text"> </i>
                            <i class="far fa-star blue-text"> </i>
                            <i class="far fa-star blue-text"> </i>
                            <i class="far fa-star blue-text"> </i>
                            <i class="far fa-star blue-text"> </i>
                          </div>

                          <p>{{commentList.userComment}}</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </mdb-tab>
            </mdb-tabset>
          </div>
        </div>
      </div>

    </div>

    <div class="col-12 col-md-5 col-lg-5">
      <!-- Section: Testimonials v.3 -->
      <section class="team-section text-center my-0">
        <!-- Section description -->
        <!--Grid row-->
        <div class="row text-center">
          <!--Grid column-->
          <div class="col-md-12 mb-md-0 mb-1">
            <div class="testimonial">
              <!--Avatar-->
              <div class="avatar mx-auto">
                <i class="fas fa-5x fa-trophy orange-text"></i>
              </div>
              <!--Review-->
              <div class="orange-text">
                <i class="fas fa-star"> </i>
                <i class="fas fa-star"> </i>
                <i class="fas fa-star"> </i>
                <i class="fas fa-star"> </i>
                <i class="fas fa-star"> </i>
              </div>
              <h6 class="font-weight-bold dark-grey-text mt-0">
                {{userCommentLength}} customer reviews</h6>

              <!--Content-->
              <button *ngIf="isLoggedIn" mdbBtn type="button" gradient="purple" rounded="true" mdbWavesEffect
                (click)="frame.show()">Write a Review</button>

              <button *ngIf="!isLoggedIn" mdbBtn type="button" gradient="purple" rounded="true" mdbWavesEffect
                (click)="loginComponentFunction()">Write a Review</button>
            </div>
          </div>
          <!--Grid column-->
        </div>
        <!--Grid row-->
      </section>
      <!-- Section: Testimonials v.3 -->
      <div class="col-12 col-md-12 col-lg-12">
        <!-- Section: Testimonials v.2 -->
        <section class="text-center my-0">
          <!-- Section heading -->
          <h5 class="h5-responsive font-weight-bold my-2">Testimonials</h5>
          <!--Carousel Wrapper-->
          <mdb-carousel [isControls]="true" [animation]="'slide'" class="testimonial-carousel">
            <!--First slide-->
            <mdb-slide>
              <div class="testimonial">
                <!--Content-->
                <p>
                  <i class="fas fa-quote-left"></i> The handwoven saree I purchased from CraftsHarbour is absolutely stunning! 
                  The intricate design and the craftsmanship are unlike anything I've seen before. It's not just a saree; 
                  it's a work of art. I feel so elegant and unique wearing it. Highly recommend to anyone who appreciates true craftsmanship.
                </p>
                <h4 class="font-weight-bold">Anna </h4>
                <h6 class="font-weight-bold my-3">Founder at ABC Company</h6>
                <!--Review-->
                <i class="fas fa-star blue-text"> </i>
                <i class="fas fa-star blue-text"> </i>
                <i class="fas fa-star blue-text"> </i>
                <i class="fas fa-star blue-text"> </i>
                <i class="fas fa-star-half blue-text"> </i>
              </div>
            </mdb-slide>
            <!--/First slide-->
            <!--Second slide-->
            <mdb-slide>
              <div class="testimonial">
                <!--Content-->
                <p>
                  <i class="fas fa-quote-left"></i> I was looking for a saree that was unique and reflected my personal style, 
                  and I found it with CraftsHarbour. The handwoven fabric feels amazing, and the design is simply beautiful. 
                  I love that it's different from anything you find in regular stores. Wearing this saree makes me feel confident 
                  and elegant!
                <h4 class="font-weight-bold">Maria </h4>
                <h6 class="font-weight-bold my-3">Photographer at ABC Studio LA</h6>
                <!--Review-->
                <i class="fas fa-star blue-text"> </i>
                <i class="fas fa-star blue-text"> </i>
                <i class="fas fa-star blue-text"> </i>
                <i class="fas fa-star blue-text"> </i>
                <i class="fas fa-star blue-text"> </i>
              </div>
            </mdb-slide>
            <!--/Second slide-->
            <!--Third slide-->
            <mdb-slide>
              <div class="testimonial">
                <!--Content-->
                <p>
                  <i class="fas fa-quote-left"></i> This handwoven saree is a perfect fusion of tradition and modern aesthetics. 
                  The rich, textured fabric and intricate weaving are truly a testament to the heritage of craftsmanship. 
                  I can’t wait to wear it on special occasions and show it off to my friends and family!
                </p>
                <h4 class="font-weight-bold">John </h4>
                <h6 class="font-weight-bold my-3">Front-end Developer in NY</h6>
                <!--Review-->
                <i class="fas fa-star blue-text"> </i>
                <i class="fas fa-star blue-text"> </i>
                <i class="fas fa-star blue-text"> </i>
                <i class="fas fa-star blue-text"> </i>
                <i class="far fa-star blue-text"> </i>
              </div>
            </mdb-slide>
            <!--/Third slide-->
          </mdb-carousel>
          <!--/.Carousel Wrapper-->
        </section>
        <!-- Section: Testimonials v.2 -->
      </div>
    </div>
  </div>
</div>


<div class="container-fluid mt-6">
  <!--Section: Products v.5-->
  <!-- <section class="text-center pb-3 my-5" id="test1"> -->
  <section class="my-3" id="test11">
    <!--Section heading-->
    <h2 class="h1 py-5 text-center">Our best sellers</h2>
    <!--Section description-->
    <p class="grey-text pb-5 text-center">" I got lucky because I never gave up the search. Are you quitting too soon?
      Or are
      you willing to pursue luck with a vengeance?" -Jill Konrath</p>


    <!--Carousel Wrapper-->
    <mdb-carousel [animation]="'slide'" [isControls]="true" [ngSwitch]="carouselDisplayMode" [isControls]="false"
      class="carousel-multi-item multi-animation " [type]="'carousel-thumbnails'">
      <ng-container *ngSwitchCase="'single'" style="width:12rem; min-height:10rem;">
        <mdb-carousel-item *ngFor="let item of slidesBestSelling2">
          <ul class="list-unstyled list-inline font-small mt-3 " style="text-align: center;">
            <li class="list-inline-item" *ngFor="let card of item; let cardIndex = index"
              style="width:10rem; min-height:8rem; padding-left: 10px;">
              <mdb-card>
                <a (click)="clickBestSelling(card.productId)">
                  <mdb-card-img [src]="card.imageFilePath" lazyload="on" alt="Card image cap"></mdb-card-img>
                </a>
                <mdb-card-body>
                  <mdb-card-title>
                    <h9 class="card-title text-muted font-weight-normal text-start text-nowrap">{{card.productName}}
                      &nbsp;
                    </h9>
                  </mdb-card-title>
                  <ul class="list-inline text-nowrap">
                    <li class="list-inline-item font-weight-bold fs-8 text-start"><strong>{{card.productCost |
                        currency:'INR' : 'symbol' : '1.0-0'}}</strong></li>
                    <li class="list-inline-item text-muted fs-8 text-start"><del>{{card.discountCost |
                        currency:'INR' : 'symbol' : '1.0-0'}}</del></li>
                  </ul>
            <li class="list-inline-item green-text">{{card.discountPercent}}% off</li>
            </mdb-card-body>
            </mdb-card>
            </li>
          </ul>

        </mdb-carousel-item>
      </ng-container>

      <ng-container *ngSwitchCase="'multiple'">
        <mdb-carousel-item *ngFor="let item of slides; let i = index">
          <ul class="list-unstyled list-inline font-small mt-2" style="text-align: center;">
            <li class="list-inline-item" *ngFor="let card of item; let cardIndex = index"
              style="width:15rem; min-height:8rem; padding-left: 25px;">
              <mdb-card>
                <a (click)="clickBestSelling(card.productId)">
                  <mdb-card-img [src]="card.imageFilePath" lazyload="on" alt="Card image cap"></mdb-card-img>
                </a>
                <mdb-card-body>
                  <mdb-card-title>
                    <h6 class="card-title text-muted font-weight-normal" style="padding-top: 5px">{{card.productName}}
                      &nbsp;
                    </h6>
                  </mdb-card-title>

                  <ul class="list-inline">
                    <li class="list-inline-item font-weight-bold"><strong>{{card.productCost | currency:'INR' :
                        'symbol' : '1.0-0'}}</strong></li>
                    <li class="list-inline-item text-muted"><del>{{card.discountCost | currency:'INR' : 'symbol' :
                        '1.0-0'}}</del></li>
                    <li class="list-inline-item green-text">{{card.discountPercent}}% off</li>
                  </ul>

                </mdb-card-body>
              </mdb-card>
            </li>
          </ul>

        </mdb-carousel-item>
      </ng-container>
    </mdb-carousel>


    <!--/.Carousel Wrapper-->

  </section>
</div>
<!--Section: Products v.5-->

<div mdbModal #frame="mdbModal" class="modal fade top modal-scrolling" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
  <div class="modal-dialog modal-frame modal-top modal-notify modal-info" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row d-flex justify-content-center align-items-center">
          <p class="pt-3 mx-4">Product successfully added to your shopping cart</p>
          <a type="button" mdbBtn color="success" class="waves-light" mdbWavesEffect routerLink="/users">Go to cart
            <i class="fas fa-cart-arrow-down ml-1"></i>
          </a>
          <a type="button" mdbBtn color="success" outline="true" class="waves-light" mdbWavesEffect data-dismiss="modal"
            (click)="frame.hide()">Ok, thanks</a>
        </div>
      </div>
    </div>
  </div>
</div>


<div mdbModal #frame2="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">

  <div class="modal-dialog modal-full-height modal-right modal-notify modal-info" role="document">
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <p class="heading lead">Feedback request
        </p>
        <button type="button" id="closeComments" class="close" data-dismiss="modal" aria-label="Close"
          (click)="frame2.hide()">
          <span aria-hidden="true" class="white-text">×</span>
        </button>
      </div>
      <!--Body-->
      <div class="modal-body">
        <form class="container" style="display: block;">
          <div *ngFor="let prodDetailComment of productFullDetails">
            <div class="text-center">
              <mdb-icon far icon="file-alt" size="1x" class="mb-3 animated rotateIn"></mdb-icon>
              <p>
                <strong>Your opinion matters! Your rating</strong>
              </p>
            </div>
            <hr>

            <div class="form-check mb-4">
              <input class="form-check-input" name="userRatings" type="radio" id="radio-179"
                [(ngModel)]="commentForm.userRatings" value="5" mdbInput>
              <label class="form-check-label" for="radio-179">Very good</label>
            </div>

            <div class="form-check mb-4">
              <input class="form-check-input" name="userRatings" type="radio" id="radio-279"
                [(ngModel)]="commentForm.userRatings" value="4" mdbInput>
              <label class="form-check-label" for="radio-279">Good</label>
            </div>

            <div class="form-check mb-4">
              <input class="form-check-input" name="userRatings" type="radio" id="radio-379"
                [(ngModel)]="commentForm.userRatings" value="3" mdbInput>
              <label class="form-check-label" for="radio-379">Mediocre</label>
            </div>
            <div class="form-check mb-4">
              <input class="form-check-input" name="userRatings" type="radio" id="radio-479"
                [(ngModel)]="commentForm.userRatings" value="2" mdbInput>
              <label class="form-check-label" for="radio-479">Bad</label>
            </div>
            <div class="form-check mb-4">
              <input class="form-check-input" name="userRatings" type="radio" id="radio-579"
                [(ngModel)]="commentForm.userRatings" value="1" mdbInput>
              <label class="form-check-label" for="radio-579">Very bad</label>
            </div>

            <!--Basic textarea-->
            <div class="md-form">
              <textarea type="text" id="form79textarea" name="userComment" #userComment="ngModel" autocomplete="off"
                [(ngModel)]="commentForm.userComment" class="md-textarea form-control" rows="1" mdbInput></textarea>
              <label for="form79textarea">Your message</label>
            </div>

            <a type="button" mdbBtn color="primary" type="submit" (click)="onAddSubmit(prodDetailComment)"
              class="waves-light" mdbWavesEffect>Send
              <mdb-icon fas icon="paper-plane" class="ml-1"></mdb-icon>
            </a>
            <a type="button" mdbBtn color="primary" outline="true" class="waves-effect" data-dismiss="modal"
              mdbWavesEffect (click)="frame2.hide()">Cancel</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- </div> -->



<router-outlet></router-outlet>