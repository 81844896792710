import { User } from './models/user.model';
import { AuthService } from "./services/auth.service";
import { Router, Event, NavigationStart, NavigationEnd, NavigationError, NavigationCancel } from '@angular/router';
import { Component, OnInit, ElementRef, QueryList, ViewChild } from '@angular/core';
import { AccountService } from "./services/account.service";
import { TranslateService } from '@ngx-translate/core';
import { TokenStorageService } from './services/token-storage.service';
import { AuthLoginInfo } from './auth/login-info';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { ToastService, SBItemComponent, ModalContainerComponent, CompleterService, CompleterData } from 'ng-uikit-pro-standard';
import { EventEmitterService } from './services/event-emitter.service';
import { Title, Meta } from '@angular/platform-browser';
import Fuse from "fuse.js";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],

})
export class AppComponent implements OnInit {
  title = 'Craftsharbour | Handicrafts | Terracotta | Baluchari | Swarnachari | Pure Silk Sharee | Bishnupur silk Saree';
  user: User = new User();
  errorMessage: string;
  cartCount: number = 0;
  private loginInfo: AuthLoginInfo;
  form: any = {};
  isLoggedIn = false;
  isLoginFailed = false;
  roles: string[] = [];
  public forgotPwd: boolean = false;
  public show: boolean = false;
  isSuccessful = false;
  isSignUpFailed = false;
  showAdminBoard = false;
  showModeratorBoard = false;
  username: string;
  firstName: string;
  public loading: boolean = false;
  public forgotpwd: boolean = false;
  public termsCond: boolean = false;
  public isInvalidForgotMail = false;
  public isNewPasswordFail = false;
  public isContinue = false;

  public isSelect = false;
  dataService: CompleterData;
 // optionsSelectProduct: Array<any>;
  selectedProductType: any;
  searchValue: any;
  searchPlaceholder: string = "Search for Products";
  searchItems: any;
  fuse: Fuse<{ value: string; label: string }>;

  marked = 0;
  element: HTMLElement;
  routerLoading = true;
  isHovered: boolean = false;
  @ViewChild(SBItemComponent) collapses: QueryList<SBItemComponent>;

  @ViewChild('aForm') aForm: ElementRef;
  @ViewChild('bForm') bForm: ElementRef;

  modalFormLoginEmail = new FormControl('', Validators.email);
  modalFormLoginPassword = new FormControl('', Validators.required);
  modalFormRegisterEmail = new FormControl('', Validators.email);
  modalFormRegisterPassword = new FormControl('', Validators.required);
  modalFormRegisterRepeatPassword = new FormControl('', Validators.required);
  validatingForm: FormGroup;
  @ViewChild('frame') frame: ModalContainerComponent;

  currentUser: User = new User();

  private optionsSelectProduct = [
    { value: 'BAS', label: 'Baluchari Saree' },
    { value: 'SAS', label: 'Swarnachari Saree' },
    { value: 'BBS', label: 'Bishnupur Silk' },
    { value: 'SSS', label: 'Silk Stole' },
    { value: 'SHS', label: 'Handloom Saree' },
    { value: 'SJS', label: 'Jamdani Saree' }
  ];

  constructor(private authService: AuthService, public accountService: AccountService,
    private router: Router, private translate: TranslateService, private completerService: CompleterService,
    private tokenStorage: TokenStorageService, private toastrService: ToastService,
    private eventEmitterService: EventEmitterService, private titleService: Title,
    private metaService: Meta) {

    router.events.subscribe((routerEvent: Event) => {
      this.checkRouterEvent(routerEvent);
    });

    this.router.events.subscribe(routerEvent => {
      if (routerEvent instanceof NavigationStart) {
        if (routerEvent.url == "/") {
          this.router.navigate(["home"], { skipLocationChange: true })          
        }
      }
    });


    if (sessionStorage.getItem("currentUser") === null) {
      this.currentUser.fullName = "";
    }
    else {
      this.currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    }
    translate.setDefaultLang('en');


    this.fuse = new Fuse(this.optionsSelectProduct, {
      keys: ['value', 'label'], // Search in both value and label
      threshold: 0.4, // Adjust sensitivity (lower = stricter match)
      minMatchCharLength: 3, // Minimum characters to start searching
      ignoreLocation: true, // Allows searching anywhere in the string
      useExtendedSearch: true, // Enables advanced search behavior
      findAllMatches: true, // Ensures multiple matches
      includeScore: true, // Gets better ranked results
    });
  }

  switchLanguage(language: string) {
    this.translate.use(language);
  }

  

  ngOnInit() {

   

    // const options = {
    //   keys: ['value', 'label'], // Search both label and value fields
    //   threshold: 0.4, // Adjust sensitivity (lower = stricter match)
    //   minMatchCharLength: 3, // Minimum characters to start searching
    //   ignoreLocation: true, // Allows searching anywhere in the string
    //   useExtendedSearch: true, // Enables advanced search behavior
    //   findAllMatches: true, // Ensures multiple matches
    // };

    // this.fuse = new Fuse(this.optionsSelectProduct, options);

   
    this.dataService = this.completerService.local(this.optionsSelectProduct, 'label', 'label');
    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      { name: 'keywords', content: 'crafts, craft, bishnupur, baluchari, swarnachari, silk, craftsharbour, terracota, terracotta, saree, panchmura, mandir, rashmancha, dhokra, docra, mallabhum' },
      { name: 'description', content: 'Craftsharbour.com is a marketplace to "Discover Unique Indian Products like baluchari, swarnachari, slilk stole, terracotta, dhokra" including Hand made, Ethnic and Artistic products from India.' },
      { name: 'robots', content: 'index, follow' }
    ]);

    window.scroll(0, 0);
    this.isLoggedIn = !!this.tokenStorage.getToken();

    if (this.isLoggedIn) {
      const user = this.tokenStorage.getUser();
      this.roles = user.roles;

      this.showAdminBoard = this.roles.includes('ROLE_ADMIN');
      this.showModeratorBoard = this.roles.includes('ROLE_MODERATOR');

      this.username = user.username;
      this.firstName = user.firstname;
    }

    if (this.isLoggedIn) {
      this.accountService.getCartCount(this.username)
        .subscribe(data => {
          this.cartCount = data;
        });
    }

    // }

    if (this.eventEmitterService.subsVar == undefined) {
      this.eventEmitterService.subsVar = this.eventEmitterService.
        invokeFirstComponentFunction.subscribe((name: string) => {
          this.accountService.getCartCount(this.username)
            .subscribe(data => {
              this.cartCount = data;
            });
        });

      this.eventEmitterService.subsVar = this.eventEmitterService.
        invokeLoginComponentFunction.subscribe((name: string) => {
          this.show = false;
          this.forgotPwd = false;
          this.element = document.getElementById('OpenLoginPopup') as HTMLElement;
          this.element.click();

        });


    }
  }

  firstFunction() {

  }

  forgotPassword() {
    this.forgotPwd = true;
  }

  reloadPage() {
    window.location.reload();
  }

  login() {

    this.authService.logIn(this.user)
      .subscribe(data => {
        window.location.reload();
      }, err => {
        this.errorMessage = "error :  Username or password is incorrect";
      }
      )
  }



  logout() {
    this.tokenStorage.signOut();
    const options = { opacity: 1 };
    this.toastrService.success('Logout Successful', '', options);
    this.router.navigate(['/home'])
      .then(() => {
        window.location.reload();
      });

  }


  onActivate(event) {
    window.scroll(0, 0);
  }

  get modalFormElegantEmail() {
    return this.validatingForm.get('modalFormElegantEmail');
  }

  get modalFormElegantPassword() {
    return this.validatingForm.get('modalFormElegantPassword');
  }


  toggle1() {
    this.show = !this.show;
    this.forgotpwd = false;
    this.termsCond = false;
    this.isContinue = false;
    this.form.signupEmail = "";
  }

  toggle() {

    if (typeof this.form.username === "undefined" || this.form.username === "") {
      const options = { opacity: 1 };
      this.toastrService.error('Enter Your Email to Signup', '', options);
    } else {

      this.loading = true;
      this.authService.sendSignUpOTP(this.form).subscribe(
        data => {
          //console.log(data);
          this.show = !this.show;
          this.forgotpwd = false;
          this.termsCond = false;

          const options = { opacity: 1 };
          this.toastrService.success('OTP Sent Successfully', '', options);
          this.loading = false;
          //this.reloadPage();
        },
        err => {
          this.errorMessage = err.error.message;
          //this.isSignUpFailed = true;
          this.loading = false;
          this.isInvalidForgotMail = true;
          const options = { opacity: 1 };
          this.toastrService.error('Error! Try again', '', options);
        }
      );
    }

  }

  toggleTermsCond() {
    this.termsCond = !this.termsCond;
    this.forgotpwd = false;
  }

  toggleForgotPwd(): void {

    this.isInvalidForgotMail = false;
    if (typeof this.form.username === "undefined" || this.form.username === "") {
      const options = { opacity: 1 };
      this.toastrService.error('Enter Your Email', '', options);
    }
    else {
      this.loading = true;
      this.authService.sendOTP(this.form).subscribe(
        data => {
          //console.log(data);
          this.forgotpwd = !this.forgotpwd;
          this.termsCond = false;

          const options = { opacity: 1 };
          this.toastrService.success('OTP Sent Successful', '', options);
          this.loading = false;
          //this.reloadPage();
        },
        err => {
          this.errorMessage = err.error.message;
          this.loading = false;
          this.isInvalidForgotMail = true;
          const options = { opacity: 1 };
          this.toastrService.error('Error! Try again', '', options);
        }
      );
    }
  }

  onSubmit() {

    this.loading = true;
    this.authService.login(this.form).subscribe(
      data => {
        this.tokenStorage.saveToken(data.accessToken);
        this.tokenStorage.saveUser(data);

        this.isLoginFailed = false;
        this.isLoggedIn = true;
        this.roles = this.tokenStorage.getUser().roles;
        this.frame.hide();
        const options = { opacity: 1 };
        this.toastrService.success('Login Successful', '', options);
        this.loading = false;
        this.reloadPage();
      },
      err => {
        //this.errorMessage = err.error.message;
        this.errorMessage = "Your username or password is incorrect";
        this.isLoginFailed = true;
        const options = { opacity: 1 };
        this.toastrService.error('Login Failed! Try again', '', options);
        this.loading = false;
      }
    );
  }

  onSubmitSignup() {
    //this.form.email = this.form.username;
    this.form.email = this.form.signupEmail;
    this.form.username = this.form.signupEmail;
    //this.form.acceptTNC = this.marked;
    this.form.acceptTNC = true;
    this.loading = true;
    this.authService.register(this.form).subscribe(
      data => {
        //console.log(data);
        this.isSuccessful = true;
        this.isSignUpFailed = false;
        const options = { opacity: 1 };
        this.toastrService.success('Registration Successful', '', options);
        this.loading = false;
        this.reloadPage();
      },
      err => {
        this.errorMessage = err.error.message;
        this.isSignUpFailed = true;
        this.loading = false;
        const options = { opacity: 1 };
        this.toastrService.error('Error! Try again', '', options);
      }
    );
  }

  toggleVisibility(e) {
    this.marked = e.checked ? 1 : 0;
  }


  fetchDetailss(value): void {
    event.preventDefault();
    this.router.navigate(['/category'],
      {
        queryParams: {
          pvsu: btoa(value),
          ssidn: btoa('product name should be display here as mentioed in craftsharbour.com'),
          subssidn: btoa('bishnupur product')
        }
      });
    this.eventEmitterService.menuComponentButtonClick(value);
    //console.log(event);

  };


  // getSearchId(searchText: string): string {
  //   const searchMap: { [key: string]: string } = {
  //     'Baluchari': 'BAS',
  //     'Swarnachari': 'SAS'
  //   };  
  //   return searchMap[searchText] || ''; 
  // }

  // search(): void {
  //   console.log(this.searchValue);
  // }


  search(): void {
    event.preventDefault();
    // const foundItem = this.optionsSelectProduct.find(item => item.label.toLowerCase() === this.searchValue.toLowerCase());
    // let assignedId = foundItem ? foundItem.value : undefined;
    const lowerQuery = this.searchValue.toLowerCase();
    const result = this.fuse.search(`${lowerQuery}`);

    //const result = this.fuse.search(this.searchValue);
    let assignedId =result.length > 0 ? result[0].item.value : null;
    //alert(assignedId);
    this.router.navigate(['/category'],
      {
        queryParams: {
          pvsu: btoa(assignedId),
          ssidn: btoa('product name should be display here as mentioed in craftsharbour.com'),
          subssidn: btoa('bishnupur product')
        }
      });
    this.eventEmitterService.menuComponentButtonClick(assignedId);
    //console.log(event);

  };

  navigateHome(): void {
    this.router.navigate(['/home']);
  };

  navigatePage(path): void {
    //event.preventDefault();
    this.router.navigate(['/' + path]);

  };

  resetLoginPopup() {
    this.forgotPwd = false;
    this.show = false;
    this.isSuccessful = false;
    this.isInvalidForgotMail = false;
  }



  backtoLoginPopup() {
    this.authService.sendfakedata(this.form).subscribe(
      data => {
        this.show = true;
        this.show = !this.show;
        this.forgotpwd = false;
        this.termsCond = false;
      }
    );


  }

  setFocus(name): void {
    const ele = this.aForm.nativeElement[name];
    if (ele) {
      ele.focus();
    }
  }

  setFocusB(email): void {
    const elem = this.bForm.nativeElement[email];
    if (elem) {
      elem.focus();
    }
  }


  resetForgotPwd(): void {
    this.isNewPasswordFail = false;
    this.loading = true;
    this.authService.resetForgotPassword(this.form).subscribe(
      data => {
        //console.log(data);
        const options = { opacity: 1 };
        this.toastrService.success('Password Update Successful', '', options);
        this.loading = false;
        this.isNewPasswordFail = false;
        this.reloadPage();
      },
      err => {
        this.errorMessage = err.error.message;
        this.loading = false;
        this.isNewPasswordFail = true;
        const options = { opacity: 1 };
        this.toastrService.error('Error! Try again', '', options);
      }
    );

  }


  checkRouterEvent(routerEvent: Event): void {
    if (routerEvent instanceof NavigationStart) {
      this.routerLoading = true;
    }

    if (routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError) {
      this.routerLoading = false;
    }
  }

  continueClick() {
    if (typeof this.form.signupEmail === "undefined" || this.form.signupEmail === "") {
      const options = { opacity: 1 };
      this.toastrService.error('Enter Your Email', '', options);
    } else {

      this.loading = true;
      this.authService.sendSignUpOTP(this.form).subscribe(
        data => {
          //console.log(data);
          //this.show = !this.show;
          this.forgotpwd = false;
          this.termsCond = false;
          this.isContinue = true;
          const options = { opacity: 1 };
          this.toastrService.success('OTP Sent Successfully', '', options);
          this.loading = false;
        },
        err => {
          this.errorMessage = err.error.message;
          this.loading = false;
          this.isInvalidForgotMail = true;
          const options = { opacity: 1 };
          this.toastrService.error('Error! Try again', '', options);
        }
      );
    }
  }

  closeSelect(){
    this.isSelect= true;
    //console.log('Selected closed');
}

openSelect(){
  this.isSelect= false;
    //console.log('Selected Open');
}

onSearch(event: any) {
  console.log(event);
}




}




