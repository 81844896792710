<main>  
  <link rel="canonical" href="https://www.craftsharbour.com/" />
<div class="container-fluid">
    <!-- <h4>MY CART({{userCarts.length}})</h4> -->
    <mdb-spinner spinnerColor="blue" *ngIf="loading" spinnerType="small" class="float-right"></mdb-spinner>
    <div class="row">
      <div class="col-12 col-md-8">
        <div class="card card-cascade wider" *ngIf="userCarts?.length">
          <div class="table-responsive">
            <!-- <table class="table product-table" mdbTable mdbTableScroll scrollY="true">
              <thead class="mdb-color lighten-5">
                <tr>
                  <th width="15%"></th>
                  <th width="20%" class="font-weight-bold">
                    Product Name
                  </th>
                  <th width="10%" class="font-weight-bold">
                    <strong>Qty</strong>
                  </th>
                  <th width="25%" class="font-weight-bold">
                    <strong>Unit Price</strong>
                  </th>
                  <th width="15%" class="font-weight-bold">
                    <strong>Total Amount</strong>
                  </th>
                  <th width="10%"></th>
                </tr>
              </thead>
              <tbody>

                <tr *ngFor="let cart of userCarts">
                  <th scope="row">
                    <a>
                      <img src={{cart.imagePath}} class="img-fluid z-depth-0" (click)="fetchDetailss(cart.productId)"
                        style="width:60px; height:auto;" alt="" />
                    </a>
                  </th>
                  <td>
                    <h7 class="mb-3 h5">{{cart.productName}}</h7>
                    <p class="text-muted">
                      {{cart.productDescription | slice:0:50}}...
                    </p>
                  </td>
                  <td>
                    <input type="number" [disabled]="true" value="{{cart.productQty}}" aria-label="Search"
                      class="form-control" style="width: 70px">
                  </td>
                  <td>
                    <p> <strong class="indigo-text font-weight-bold">{{cart.unitPrice | currency:'INR'}}</strong> &nbsp;
                      <del class="text-muted"> {{cart.discountCost | currency:'INR'}}</del> &nbsp;
                      <strong class="green-text font-weight-bold">{{cart.discountPercent}}% Off</strong>
                    </p>
                  </td>
                  <td>
                    <p class="indigo-text font-weight-bold"> <strong>{{cart.unitPrice | currency:'INR'}}</strong></p>
                  </td>
                  <td>
                    <a mdbBtn floating="true" size="sm" (click)="setProdId(cart);frame.show()" gradient="peach"
                      mdbWavesEffect>
                      <mdb-icon fas icon="trash-alt"></mdb-icon>
                    </a>
                  </td>
                </tr>

                <div mdbModal #frame="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog"
                  aria-labelledby="myModalLabel" aria-hidden="true">
                  <div class="modal-dialog modal-notify modal-info" role="document">
                    <form name="form" id="form">
                      <div class="modal-content text-center">
                        <div class="modal-header d-flex justify-content-center">
                          <p class="heading">Are you sure ?</p>
                        </div>

                        <div class="modal-body">
                          <mdb-icon fas icon="trash" size="4x" class="animated rotateIn mb-4"></mdb-icon>
                          <p>Do you want to delete this poduct from Cart ?</p>
                        </div>

                        <div class="modal-footer justify-content-center">
                          <button (click)="deleteFromCartsss()" mdbBtn color="primary" class="waves-light"
                            mdbWavesEffect>Yes</button>
                          <a type="button" mdbBtn color="primary" outline="true" class="waves-light" mdbWavesEffect
                            data-dismiss="modal" (click)="frame.hide()">No</a>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </tbody>
            </table> -->

            <section class="h-100">
              <div class="container h-100">
                <div class="row d-flex justify-content-center align-items-center h-100">
                  <div class="col-12">    
                    
                    <div class="d-flex justify-content-between align-items-center mb-4">
                      <h4 class="fw-normal mb-0">You have {{userCarts.length}} items in your cart</h4>                      
                      <div>
                        <p class="mb-0"><span class="text-muted">Sort by:</span> <a class="text-body">price <i
                              class="fas fa-angle-down mt-1"></i></a></p>
                      </div>
                    </div>

                    <div *ngFor="let cart of userCarts">
                    <div class="card rounded-3 mb-4">
                      <div class="card-body p-4">
                        <div class="row d-flex justify-content-between align-items-center">
                          <div class="col-md-2 col-lg-2 col-xl-2">
                            <!-- <img
                              src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-shopping-carts/img1.webp"
                              class="img-fluid rounded-3" alt="Cotton T-shirt"> -->
                              <a>
                                <img src={{cart.imagePath}}  class="img-fluid rounded-3 custom-image" (click)="fetchDetailss(cart.productId)"
                                  alt="" />
                              </a>
                          </div>
                          <div class="col-md-3 col-lg-3 col-xl-3">
                            <p class="lead fw-normal mb-2">{{cart.productName}}</p>
                            <!--<p><span class="text-muted">Quantity: </span>{{cart.productQty}} <span class="text-muted">Color: </span>Grey</p>-->
                            <p><span class="text-muted">Color: </span>{{cart.productColor}}</p>
                          </div>
                          <div class="col-md-3 col-lg-2 col-xl-2 offset-lg-1">                            
                              <input type="number" [disabled]="true" value="{{cart.productQty}}" aria-label="Search"
                                class="form-control" style="width: 70px">                            
                          </div>
                          <div class="col-md-3 col-lg-3 col-xl-3">
                            <p class="lead fw-normal mb-2">{{cart.unitPrice | currency:'INR'}}</p>                            
                            <p><del class="text-muted"> {{cart.discountCost | currency:'INR'}}</del> &nbsp;
                              <strong class="green-text font-weight-bold">{{cart.discountPercent}}% Off</strong></p>
                            
                          </div>
                         
                          <div class="col-md-1 col-lg-1 col-xl-1 text-end">
                            <a (click)="setProdId(cart);frame.show()" style="color: #cecece;" title="Remove item"><i class="fas fa-trash fa-lg"></i></a>
                          </div>
                        </div>

                  <div mdbModal #frame="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog"
                  aria-labelledby="myModalLabel" aria-hidden="true">
                  <div class="modal-dialog modal-notify modal-info" role="document">
                    <form name="form" id="form">
                      <div class="modal-content text-center">
                        <div class="modal-header d-flex justify-content-center">
                          <p class="heading">Are you sure ?</p>
                        </div>

                        <div class="modal-body">
                          <mdb-icon fas icon="trash" size="4x" class="animated rotateIn mb-4"></mdb-icon>
                          <p>Do you want to delete this poduct from Cart ?</p>
                        </div>

                        <div class="modal-footer justify-content-center">
                          <button (click)="deleteFromCartsss()" mdbBtn color="primary" class="waves-light"
                            mdbWavesEffect>Yes</button>
                          <a type="button" mdbBtn color="primary" outline="true" class="waves-light" mdbWavesEffect
                            data-dismiss="modal" (click)="frame.hide()">No</a>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                      </div>
                    </div>   
                  </div>
            
                  </div>
                </div>
              </div>
            </section>

          </div>

          <div class="card-block text-center">
            <div class="border-top"></div>
            <div class="btn-group my-1" role="group">
              <button type="button" class="btn btn-primary btn-rounded" (click)="fetchBack()"><i
                  class="fas fa-angle-left mr-1"></i>Continue
                Shopping</button>
              <button type="button" class="btn btn-primary btn-rounded" routerLink="/orderplace">Complete Purchase
                <i class="fas fa-angle-right right"></i>
              </button>
            </div>
          </div>
        </div>

        <div class="card-block text-center" *ngIf="!userCarts?.length">
          <img src="./assets/img/emptycart.png" class="img-fluid flex-center" alt="">
          <div class="border-top"></div>
          <div class="btn-group my-1" role="group">
            <button type="button" class="btn btn-primary btn-rounded" (click)="fetchBack()"><i
                class="fas fa-angle-left mr-1"></i>Continue
              Shopping</button>
          </div>
        </div>
      </div>

      <div class="col-md-4 my-1" *ngIf="userCarts?.length">
        <mdb-card>
          <mdb-card-body class="bg-light rounded-3">
            <h4 class="mb-4 mt-1 h5 text-center font-weight-bold">Summary</h4>
            <hr>
            <dl class="row">
              <dd class="col-sm-8">
                Total Product Price
                <tr *ngFor="let cart of userCarts" class="small">
                  {{cart.productName}} &nbsp; (1)
                </tr>
              </dd>
              <dd class="col-sm-4">
                {{afterDiscountTotalCost | currency:'INR'}}
                <del class="text-muted small">{{totalCost | currency:'INR'}}</del>
              </dd>
            </dl>
            <hr>
            <dl class="row">
              <dd class="col-sm-8">
                Total Discount
              </dd>
              <dd class="col-sm-4">
                {{totalDiscountCost | currency:'INR'}}
              </dd>
            </dl>
            <hr>
            <dl class="row">
              <dd class="col-sm-8">
                Gift Wrap Any
              </dd>
              <dd class="col-sm-4">
                {{0 | currency:'INR'}}
              </dd>
            </dl>
            <hr>
            <dl class="row d-flex justify-content-between light-blue lighten-5 p-2">
              <dt class="col-sm-8">
                Total Payble Amount
              </dt>
              <dt class="col-sm-4">
                {{afterDiscountTotalCost | currency:'INR'}}
              </dt>
            </dl>
          </mdb-card-body>
          <span class="chip">
            <mdb-icon fas icon="certificate">
            </mdb-icon> Safe & Secure Payments. Authentic products.
          </span>
        </mdb-card>
      </div>
    </div>
  </div>


</main>



<router-outlet></router-outlet>