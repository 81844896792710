<!--For Mobile-->
<!-- <link rel="canonical" href="https://www.craftsharbour.com/#/home" /> -->
<div class="d-lg-none d-md-none mt-3" style="padding-top: 45px;">
  <mdb-carousel class="carousel slide" [animation]="'slide'">
    <mdb-carousel-item>
      <div class="view w-100">
        <img class="d-block w-100" src="./assets/img/bannerimg/sari_home1.jpg" loading="lazy" alt="First slide">
        <div class="waves-light" mdbWavesEffect></div>
      </div>

    </mdb-carousel-item>
    <mdb-carousel-item>
      <div class="view w-100">
        <img class="d-block w-100" src="./assets/img/bannerimg/silk_stoles_home1.jpg" loading="lazy" alt="Second slide">
        <div class="waves-light" mdbWavesEffect></div>
      </div>

    </mdb-carousel-item>
    <mdb-carousel-item>
      <div class="view w-100">
        <img class="d-block w-100" src="./assets/img/bannerimg/terra_art_home1.jpg" loading="lazy" alt="Third slide">
        <div class="waves-light" mdbWavesEffect></div>
      </div>

    </mdb-carousel-item>
    <mdb-carousel-item>
      <div class="view w-100">
        <img class="d-block w-100" src="./assets/img/bannerimg/home_decor_home1.jpg" loading="lazy" alt="Forth slide">
        <div class="waves-light" mdbWavesEffect></div>
      </div>

    </mdb-carousel-item>
  </mdb-carousel>
</div>
<!--End For Mobile-->


<!--For Desktop-->
<div class="container-fluid d-lg-block d-md-block d-none" style="padding-top: 70px;">
  <mdb-carousel class="carousel slide" [animation]="'slide'">
    <mdb-carousel-item>
      <div class="view w-100">
        <img class="d-block w-100" src="./assets/img/bannerimg/sari_home1.jpg" loading="lazy" alt="First slide">
        <div class="waves-light" mdbWavesEffect></div>
      </div>

    </mdb-carousel-item>
    <mdb-carousel-item>
      <div class="view w-100">
        <img class="d-block w-100" src="./assets/img/bannerimg/silk_stoles_home1.jpg" loading="lazy" alt="Second slide">
        <div class="waves-light" mdbWavesEffect></div>
      </div>

    </mdb-carousel-item>
    <mdb-carousel-item>
      <div class="view w-100">
        <img class="d-block w-100" src="./assets/img/bannerimg/terra_art_home1.jpg" loading="lazy" alt="Third slide">
        <div class="waves-light" mdbWavesEffect></div>
      </div>

    </mdb-carousel-item>
    <mdb-carousel-item>
      <div class="view w-100">
        <img class="d-block w-100" src="./assets/img/bannerimg/home_decor_home1.jpg" loading="lazy" alt="Forth slide">
        <div class="waves-light" mdbWavesEffect></div>
      </div>

    </mdb-carousel-item>
  </mdb-carousel>
</div>
<!--End For Desktop-->

<hr>

<div class="d-lg-none d-md-none">
  
  <ul class="list-unstyled list-inline font-small mt-6" style="text-align: center;">
    <li class="list-inline-item">
      <mdb-card>
        <div class="view overlay waves-light" mdbWavesEffect>
          <!-- Card img -->
          <img src="./assets/img/Categories/baluchari.jpg" alt="Card image cap" style="width: 80px; margin: 1px;" loading="lazy" class="img-fluid">
          <a>
            <div class="mask rgba-white-slight" (click)="fetchDetailss('BAS')"></div>
          </a>
        </div>
      </mdb-card>
    </li>
    <li class="list-inline-item">
      <mdb-card>
        <div class="view overlay waves-light" mdbWavesEffect>
          <!-- Card img -->
          <img src="./assets/img/Categories/swarnachari.jpg" alt="Card image cap" style="width: 80px; margin: 1px;" loading="lazy">
          <a>
            <div class="mask rgba-white-slight" (click)="fetchDetailss('SAS')"></div>
          </a>
        </div>
      </mdb-card>
    </li>
    <li class="list-inline-item">
      <mdb-card>
        <div class="view overlay waves-light" mdbWavesEffect>
          <!-- Card img -->
          <img src="./assets/img/Categories/bishnipurSilk.jpg" alt="Card image cap" style="width: 80px; margin: 1px;" loading="lazy">
          <a>
            <div class="mask rgba-white-slight" (click)="fetchDetailss('BBS')"></div>
          </a>
        </div>
      </mdb-card>
    </li>
    <li class="list-inline-item">
      <mdb-card>
        <div class="view overlay waves-light" mdbWavesEffect>         
          <img src="./assets/img/Categories/kurti.jpg" alt="Card image cap" style="width: 80px; margin: 1px;" loading="lazy">   
          <a>
            <div class="mask rgba-white-slight" (click)="fetchDetailss('BKP')"></div>
          </a>       
        </div>
      </mdb-card>
    </li>
  </ul>

</div>

<!-- Just Added-->
<div class="container-fluid mt-6">
  <section class="my-3" id="test11">
    <!--Section heading-->
    <h5 class="h5 py-0 text-center">Just Added</h5>
    <hr>
    <mdb-carousel [animation]="'slide'" [isControls]="true" [ngSwitch]="carouselDisplayMode" [isControls]="false" class="carousel-multi-item multi-animation "
      [type]="'carousel-thumbnails'">
      <ng-container *ngSwitchCase="'single'" style="width:12rem; min-height:10rem;">
        <mdb-carousel-item *ngFor="let item of slidesTwoJustAdded; let itemIndex = index">
          <ul class="list-unstyled list-inline font-small mt-3 " style="text-align: center;">
            <li class="list-inline-item" *ngFor="let card of item; let cardIndex = index" style="width:10rem; min-height:8rem; padding-left: 10px;">
              <mdb-card>
                <a (click)="fetchDetailsss(card)">
                  <mdb-card-img [src]="card.imageFilePath" [attr.width]="imageWidthJustAdded" 
                  [attr.height]="imageHeightJustAdded" 
                  [style.background-color]="backgroundColor" [attr.loading]="'lazy'" alt="Image"></mdb-card-img>
                </a>               
                <mdb-card-body>
                  <mdb-card-title>
                    <h9 class="card-title text-muted font-weight-normal text-start text-nowrap">{{card.productName}}
                      &nbsp;
                    </h9>
                  </mdb-card-title>
                  <ul class="list-inline text-nowrap">
                    <li class="list-inline-item font-weight-bold fs-8 text-start"><strong>{{card.productCost |
                        currency:'INR' : 'symbol' : '1.0-0'}}</strong></li>
                    <li class="list-inline-item text-muted fs-8 text-start"><del>{{card.discountCost |
                        currency:'INR' : 'symbol' : '1.0-0'}}</del></li>
                  </ul>
            <li class="list-inline-item green-text">{{card.discountPercent}}% off</li>
            </mdb-card-body>
            </mdb-card>
            </li>
          </ul>
        </mdb-carousel-item>
      </ng-container>

      <ng-container *ngSwitchCase="'multiple'">
        <mdb-carousel-item *ngFor="let itemMultiple of slidesJustAdded; let itemMultipleIndex = index">
          <ul class="list-unstyled list-inline font-small mt-2" style="text-align: center;">
            <li class="list-inline-item" *ngFor="let cardMultiple of itemMultiple; let cardMultipleIndex = index" style="width:15rem; min-height:8rem; padding-left: 25px;">
              <mdb-card>
                <a (click)="fetchDetailsss(cardMultiple)">
                  <mdb-card-img [src]="cardMultiple.imageFilePath" [attr.width]="imageWidthJustAdded" 
                  [attr.height]="imageHeightJustAdded" 
                  [style.background-color]="backgroundColor" [attr.loading]="'lazy'" alt="Image"></mdb-card-img>
                </a>                
                <mdb-card-body>
                  <mdb-card-title>
                    <h6 class="card-title text-muted font-weight-normal" style="padding-top: 5px">{{cardMultiple.productName}} &nbsp;
                    </h6>
                  </mdb-card-title>

                  <ul class="list-inline">
                    <li class="list-inline-item font-weight-bold"><strong>{{cardMultiple.productCost | currency:'INR' :
                        'symbol' : '1.0-0'}}</strong></li>
                    <li class="list-inline-item text-muted"><del>{{cardMultiple.discountCost | currency:'INR' : 'symbol' :
                        '1.0-0'}}</del></li>
                    <li class="list-inline-item green-text">{{cardMultiple.discountPercent}}% off</li>
                  </ul>

                </mdb-card-body>
              </mdb-card>
            </li>
          </ul>
        </mdb-carousel-item>
      </ng-container>
    </mdb-carousel>

  </section>
</div>

<!--End Just Added-->
<!--New-->

<!--FOR MOBILE-->
<div class="container-fluid">
  <br>
  <h5 class="h5 py-0 text-center">Featured categories</h5>
  <hr>
  <div class="d-lg-none d-md-none">
    <ul class="list-unstyled list-inline font-small mt-3" style="text-align: center;">
      <li class="list-inline-item">
        <mdb-card>
          <div class="view overlay waves-light" mdbWavesEffect>
           
            <img src="./assets/img/Categories/SilkThan.jpg" alt="Card image cap" [style.width.px]='imageWidthCategory'
              [style.margin.px]='imageMargin' loading="lazy">
              <a>
                <div class="mask rgba-white-slight" (click)="fetchDetailss('BST')"></div>
              </a>
          </div>
        </mdb-card>
      </li>
     
      <li class="list-inline-item">
        <mdb-card>
          <div class="view overlay waves-light" mdbWavesEffect>

            <img src="./assets/img/Categories/GhichaPrint.jpg" alt="Card image cap" [style.width.px]='imageWidthCategory'
              [style.margin.px]='imageMargin' loading="lazy">
              <a>
                <div class="mask rgba-white-slight" (click)="fetchDetailss('GTS')"></div>
              </a>
          </div>
        </mdb-card>
      </li>
      <li class="list-inline-item">
        <mdb-card>
          <div class="view overlay waves-light" mdbWavesEffect>

            <img src="./assets/img/Categories/SilkStole.jpg" alt="Card image cap" [style.width.px]='imageWidthCategory'
              [style.margin.px]='imageMargin' loading="lazy">
            <a>
              <div class="mask rgba-white-slight" (click)="fetchDetailss('SSS')"></div>
            </a>
          </div>
        </mdb-card>
      </li>
    </ul>
  </div>


  <!--END FOR MOBILE-->


  <div class="d-lg-block d-md-block d-none">
    <div class="card-deck mt-4">

      <mdb-card>
        <div class="view overlay waves-light" mdbWavesEffect>

          <mdb-card-img src="./assets/img/Categories/baluchari.jpg" alt="Card image cap" loading="lazy"></mdb-card-img>
          <a>
            <div class="mask rgba-white-slight" (click)="fetchDetailss('BAS')"></div>
          </a>
        </div>
      </mdb-card>

      <mdb-card>
        <div class="view overlay waves-light" mdbWavesEffect>

          <mdb-card-img src="./assets/img/Categories/swarnachari.jpg" alt="Card image cap" loading="lazy"></mdb-card-img>
          <a>
            <div class="mask rgba-white-slight" (click)="fetchDetailss('SAS')"></div>
          </a>
        </div>
      </mdb-card>

      <mdb-card>
        <div class="view overlay waves-light" mdbWavesEffect>

          <mdb-card-img src="./assets/img/Categories/bishnipurSilk.jpg" alt="Card image cap" loading="lazy"></mdb-card-img>
          <a>
            <div class="mask rgba-white-slight" (click)="fetchDetailss('BBS')"></div>
          </a>
        </div>
      </mdb-card>

      <mdb-card>
        <div class="view overlay waves-light" mdbWavesEffect>
          <mdb-card-img src="./assets/img/Categories/kurti.jpg" alt="Card image cap" loading="lazy"></mdb-card-img>
          <a><div class="mask rgba-white-slight" (click)="fetchDetailss('BKP')"></div></a>
        </div>
      </mdb-card>

    </div>
    <div class="card-deck mt-4">
      <mdb-card>
        <div class="view overlay waves-light" mdbWavesEffect>

          <mdb-card-img src="./assets/img/Categories/SilkThan.jpg" alt="Card image cap" loading="lazy"></mdb-card-img>
          <a>
            <div class="mask rgba-white-slight" (click)="fetchDetailss('BST')"></div>
          </a>
        </div>
      </mdb-card>

      
      <mdb-card>
        <div class="view overlay waves-light" mdbWavesEffect>
          <mdb-card-img src="./assets/img/Categories/SilkStole.jpg" alt="Card image cap" loading="lazy"></mdb-card-img>
          <a><div class="mask rgba-white-slight" (click)="fetchDetailss('SSS')"></div></a>
        </div>
      </mdb-card>

      <mdb-card>
        <div class="view overlay waves-light" mdbWavesEffect>

          <mdb-card-img src="./assets/img/Categories/TussarPrint.jpg" alt="Card image cap" loading="lazy"></mdb-card-img>

        </div>
      </mdb-card>
      <mdb-card>
        <div class="view overlay waves-light" mdbWavesEffect>

          <mdb-card-img src="./assets/img/Categories/GhichaPrint.jpg" alt="Card image cap" loading="lazy"></mdb-card-img>
          <a><div class="mask rgba-white-slight" (click)="fetchDetailss('GTS')"></div></a>
        </div>
      </mdb-card>
    </div>
  </div>
</div>

<!-- Recomanded For You-->
<div class="container-fluid">
  <section class="pb-1 my-3" id="test1">

    <h5 class="h5 py-0 text-center">Recommended for you</h5>
    <hr>
    <mdb-carousel [animation]="'slide'" [isControls]="true" [ngSwitch]="carouselDisplayMode" [isControls]="false" class="carousel-multi-item multi-animation "
      [type]="'carousel-thumbnails'">
      <ng-container *ngSwitchCase="'single'" style="width:9rem; min-height:10rem;">
        <mdb-carousel-item *ngFor="let itemRecom of slidesTwo; let itemRecomIndex = index">
          <ul class="list-unstyled list-inline font-small mt-3 " style="text-align: center;">
            <li class="list-inline-item" *ngFor="let cardRecom of itemRecom; let cardRecomIndex = index" style="width:10rem; min-height:8rem; padding-left: 10px;">
              <mdb-card>
                <a (click)="fetchDetailsss(cardRecom)">
                  <mdb-card-img [src]="cardRecom.imageFilePath" [attr.width]="imageWidthJustAdded" 
                  [attr.height]="imageHeightJustAdded" 
                  [style.background-color]="backgroundColor" [attr.loading]="'lazy'" alt="Image"></mdb-card-img>
                </a>
                <mdb-card-body>
                  <mdb-card-title>
                    <h8 class="card-title text-muted font-weight-normal text-start text-nowrap">{{cardRecom.productName}}
                      &nbsp;
                    </h8>
                  </mdb-card-title>
                  <ul class="list-inline text-nowrap">
                    <li class="list-inline-item font-weight-bold fs-6 text-start"><strong>{{cardRecom.productCost |
                        currency:'INR' : 'symbol' : '1.0-0'}}</strong></li>
                    <li class="list-inline-item text-muted fs-6 text-start"><del>{{cardRecom.discountCost |
                        currency:'INR' : 'symbol' : '1.0-0'}}</del></li>
                  </ul>
            <li class="list-inline-item green-text">{{cardRecom.discountPercent}}% off</li>
            </mdb-card-body>
            </mdb-card>
            </li>
          </ul>
        </mdb-carousel-item>
      </ng-container>

      <ng-container *ngSwitchCase="'multiple'">
        <mdb-carousel-item *ngFor="let itemRecomMultiple of slides; let itemRecomMultipleIndex = index">
          <ul class="list-unstyled list-inline font-small mt-3" style="text-align: center;">
            <li class="list-inline-item" *ngFor="let cardRecomMultiple of itemRecomMultiple; let cardRecomMultipleIndex = index" style="width:16rem; min-height:8rem; padding-left: 20px;">
              <mdb-card>
                <a (click)="fetchDetailsss(cardRecomMultiple)">
                  <mdb-card-img [src]="cardRecomMultiple.imageFilePath" [attr.width]="imageWidthJustAdded" 
                  [attr.height]="imageHeightJustAdded" 
                  [style.background-color]="backgroundColor"  [attr.loading]="'lazy'" alt="Image"></mdb-card-img>
                </a>
                <mdb-card-body>
                  <mdb-card-title>
                    <h6 class="card-title text-muted font-weight-normal">{{cardRecomMultiple.productName}} &nbsp;
                      <a class="badge green" mdbTooltip="User Review" placement="bottom">{{cardRecomMultiple.rating | number :
                        '1.1-1'}}
                        <mdb-icon fas icon="star" class="white-text"></mdb-icon>
                      </a></h6>
                  </mdb-card-title>

                  <ul class="list-inline">
                    <li class="list-inline-item font-weight-bold"><strong>{{cardRecomMultiple.productCost | currency:'INR' :
                        'symbol' : '1.0-0'}}</strong></li>
                    <li class="list-inline-item text-muted"><del>{{cardRecomMultiple.discountCost | currency:'INR' : 'symbol' :
                        '1.0-0'}}</del></li>
                    <li class="list-inline-item green-text">{{cardRecomMultiple.discountPercent}}% off</li>
                  </ul>

                </mdb-card-body>
              </mdb-card>
            </li>
          </ul>
        </mdb-carousel-item>
      </ng-container>
    </mdb-carousel>

  </section>
</div>

<!--End New-->

<div>
  <h5 class="h5 text-center">COLLECTIVES</h5>
  <hr>
</div>


<div class="card-deck mt-3 w-100 h-100">
  <mdb-card>
    <div class="view overlay waves-light" mdbWavesEffect>
      <mdb-card-img src="./assets/img/bas1.jpg" alt="Card image cap"></mdb-card-img>
      <a>
        <div class="mask rgba-white-slight" (click)="fetchDetailss('BAS')"></div>
      </a>
    </div>
  </mdb-card>

  <mdb-card>
    <div class="view overlay waves-light" mdbWavesEffect>
      <mdb-card-img src="./assets/img/sss1.jpg" alt="Card image cap"></mdb-card-img>
      <a>
        <div class="mask rgba-white-slight" (click)="fetchDetailss('SSS')"></div>
      </a>
    </div>
  </mdb-card>
</div>


<div class="card-deck mt-3 w-100 h-100">
  <mdb-card>
    <div class="view overlay waves-light" mdbWavesEffect>
      <mdb-card-img src="./assets/img/dhokraoffr.jpg" alt="Card image cap" loading="lazy"></mdb-card-img>
      <a>
        <div class="mask"></div>
      </a>
    </div>

  </mdb-card>

  <mdb-card>
    <div class="view overlay waves-light" mdbWavesEffect>
      <mdb-card-img src="./assets/img/macrameknots.jpg" alt="Card image cap" loading="lazy"></mdb-card-img>
      <a>
        <div class="mask"></div>
      </a>
    </div>
  </mdb-card>
</div>

<div class="card-deck mt-3 w-100 h-100">
  <mdb-card>
    <div class="view overlay waves-light" mdbWavesEffect>
      <mdb-card-img src="./assets/img/t1a.jpg" alt="Card image cap" loading="lazy"></mdb-card-img>
      <a>
        <div class="mask rgba-white-slight" (click)="fetchDetailss('TR1')"></div>
      </a>
    </div>
  </mdb-card>

  <mdb-card>
    <div class="view overlay waves-light" mdbWavesEffect>
      <mdb-card-img src="./assets/img/t2a.jpg" alt="Card image cap" loading="lazy"></mdb-card-img>
      <a>
        <div class="mask rgba-white-slight" (click)="fetchDetailss('TR2')"></div>
      </a>
    </div>
  </mdb-card>


  <mdb-card>
    <div class="view overlay waves-light" mdbWavesEffect>
      <mdb-card-img src="./assets/img/t3a.jpg" alt="Card image cap" loading="lazy"></mdb-card-img>
      <a>
        <div class="mask rgba-white-slight" (click)="fetchDetailss('TR3')"></div>
      </a>
    </div>
  </mdb-card>
</div>
<div class="mb-5"></div>

<!--/Main layout-->

<router-outlet></router-outlet>