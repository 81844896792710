<span class="fa fa-spinner spinner" *ngIf="loading"></span>
<main>
  <link rel="canonical" href="https://www.craftsharbour.com/" />
  <div id="top-section"></div>
  <div class="container-fluid">
    <div class="container-fluid d-lg-none d-md-none">
      <!-- <div class="fixed-top my-5 mb-5" style="background-color: #fff;"> -->
      <div class="fixed-top my-5 mb-5" style="background-color: #fff;">
        <button mdbBtn type="button" color="primary" size="sm" data-toggle="modal" data-target="#filterby"
          (click)="filterby.show()" mdbWavesEffect class="float-right">
          <mdb-icon fas icon="filter" class="mr-1"></mdb-icon>Filter
        </button>

        <button mdbBtn type="button" color="primary" size="sm" data-toggle="modal" data-target="#sortby"
          (click)="sortby.show()" mdbWavesEffect class="float-right">
          <mdb-icon fas icon="sliders-h" class="mr-1"></mdb-icon>Sort
        </button>
      </div>
    </div>


    <div class="row">
      <div class="col-12 col-md-3 d-none d-sm-block">
        <mdb-card class="mb-5">
          <mdb-card-body>
            <mdb-card-title>
              <h5 class="font-weight-bold mb-3">Filters</h5>
              <hr>
            </mdb-card-title>
            <mdb-accordion [multiple]="true">
              <mdb-accordion-item [collapsed]="false">
                <mdb-accordion-item-head>Price Range</mdb-accordion-item-head>
                <mdb-accordion-item-body>
                  <mdb-multi-range-input id="multi-range" step="500" min="500" max="25000"
                    (rangeValueChange)="onRangeValueChange($event)" [value]="{ first: 500, second: 25000 }">
                  </mdb-multi-range-input>
                </mdb-accordion-item-body>
              </mdb-accordion-item>

              <mdb-accordion-item [collapsed]="false">
                <mdb-accordion-item-head>Discount</mdb-accordion-item-head>
                <mdb-accordion-item-body>
                  <mdb-checkbox [(ngModel)]="markedBelow25" (change)="toggleVisibilityBelow25($event)">Below 25%
                    Discount</mdb-checkbox>
                  <mdb-checkbox [(ngModel)]="markedUpto25" (change)="toggleVisibilityUpto25($event)">Upto 25% Discount
                  </mdb-checkbox>
                  <mdb-checkbox [(ngModel)]="markedAbove30" (change)="toggleVisibilityAbove30($event)">Above 30%
                    Discount</mdb-checkbox>
                </mdb-accordion-item-body>
              </mdb-accordion-item>

              <mdb-accordion-item [collapsed]="false">
                <mdb-accordion-item-head>Color</mdb-accordion-item-head>
                <mdb-accordion-item-body>
                  <button type="button" size="sm" mdbBtn color="primary" mdbTooltip="Blue" mdbWavesEffect
                    (click)="fetchColorFilter('Blue')"></button>
                  <button type="button" size="sm" mdbBtn color="secondary" mdbTooltip="Purple" mdbWavesEffect
                    (click)="fetchColorFilter('Purple')"></button>
                  <button type="button" size="sm" mdbBtn color="success" mdbTooltip="Green" mdbWavesEffect
                    (click)="fetchColorFilter('Green')"></button>
                  <button type="button" size="sm" mdbBtn color="danger" mdbTooltip="Red" mdbWavesEffect
                    (click)="fetchColorFilter('Red')"></button>
                  <button type="button" size="sm" mdbBtn color="yellow" mdbTooltip="Yellow" mdbWavesEffect
                    (click)="fetchColorFilter('Yellow')"></button>
                  <button type="button" size="sm" mdbBtn color="dark" mdbTooltip="Black" mdbWavesEffect
                    (click)="fetchColorFilter('Black')"></button>
                  <button type="button" size="sm" mdbBtn color="light" mdbTooltip="White" mdbWavesEffect
                    (click)="fetchColorFilter('White')"></button>
                  <button type="button" size="sm" mdbBtn color="orange" mdbTooltip="Orange" mdbWavesEffect
                    (click)="fetchColorFilter('Orange')"></button>
                  <button type="button" size="sm" mdbBtn color="pink" mdbTooltip="Pink" mdbWavesEffect
                    (click)="fetchColorFilter('Pink')"></button>
                  <button mdbBtn type="button" size="sm" gradient="aqua" mdbTooltip="Multi Color" mdbWavesEffect
                    (click)="fetchColorFilter('Multi Color')"></button>

                  <button mdbBtn type="button" size="sm" color="default" mdbTooltip="All Colors" rounded="true"
                    outline="true" mdbWavesEffect (click)="fetchColorFilter('All')">All</button>
                </mdb-accordion-item-body>
              </mdb-accordion-item>

              <mdb-accordion-item [collapsed]="false">
                <mdb-accordion-item-head>Sort By</mdb-accordion-item-head>
                <mdb-accordion-item-body>

                  <input class="form-check-input" [(ngModel)]="shortBy" name="group1" type="radio" id="radio-279"
                    value="popularity" mdbInput (change)="onItemChange($event.target.value)">

                  <label class="form-check-label" for="radio-279">Popularity</label>

                  <input class="form-check-input" [(ngModel)]="shortBy" name="group1" type="radio" id="radio-379"
                    value="lowtohigh" mdbInput (change)="onItemChange($event.target.value)">
                  <label class="form-check-label" for="radio-379">Price-- Low to High</label>


                  <input class="form-check-input" [(ngModel)]="shortBy" name="group1" type="radio" id="radio-479"
                    value="hightolow" mdbInput (change)="onItemChange($event.target.value)">
                  <label class="form-check-label" for="radio-479">Price-- High to Low</label>

                  <input class="form-check-input" [(ngModel)]="shortBy" name="group1" type="radio" id="radio-579"
                    value="newestfirst" mdbInput (change)="onItemChange($event.target.value)">
                  <label class="form-check-label" for="radio-579">Newest First</label>

                </mdb-accordion-item-body>
              </mdb-accordion-item>
            </mdb-accordion>
          </mdb-card-body>
        </mdb-card>

      </div>

      <!--For Mobile-->
      <div class="d-lg-none d-md-none">
        <div class="row" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
          (window:scroll)="onScroll($event)">

          <div class="card-block text-center" *ngIf="!itemsToShow?.length">  
            <img src="./assets/img/noproduct.png" class="img-fluid flex-center" alt="">              
            <div class="btn-group my-1" role="group">
              <button type="button" class="btn btn-primary btn-rounded" (click)="fetchBack()"><i
                  class="fas fa-angle-left mr-1"></i>Continue
                Shopping</button>
            </div>
          </div>


          <div class="col-6 col-md-6 card-group" *ngFor="let product of itemsToShow; let i= index">
            <div *ngIf="product.currentStock === 0">
              <mdb-badge danger="true" style="position: absolute;margin-top: 20px;
                      left: 10px;z-index: 3;">OUT
                OF STOCK</mdb-badge>
            </div>
            <!--Card-->
            <mdb-card>
              <!--Card image-->
              <div class="view view-cascade overlay waves-light zoom"
                style="display: flex; justify-content: center; align-items: center;" mdbWavesEffect>
                <a>
                  <!--<details-upload [fileUpload]='product.imageFilePath' [style.width.px]='imageWidth' [style.margin.px]='imageMargin' (click)="fetchDetailss(product)"></details-upload>-->
                  <img src="{{product.imageFilePath}}" alt="image" [style.width.px]='imageWidth'
                    [style.margin.px]='imageMargin' (click)="fetchDetailss(product)">
                </a>
              </div>
              <!--/Card image-->

              <mdb-card-body cascade="true">
                <!--Card content-->
                <h6 class="text-muted font-weight-normal ">{{product.productName}}
                </h6>


                <ul class="list-inline text-nowrap">
                  <li class="list-inline-item font-weight-bold">
                    <h6 class="font-weight-bolder small">{{product.productCost | currency:'INR' : 'symbol' : '1.0-0'}}
                    </h6>
                  </li>
                  <li class="list-inline-item text-muted small"><del>{{product.discountCost | currency:'INR' : 'symbol'
                      : '1.0-0'}}</del></li>
                  <li class="list-inline-item green-text small">{{product.discountPercent}}% off</li>
                </ul>

                <hr>
                <div class="clearfix">
                  <span class="float-right">

                    <a class="small" data-toggle="tooltip" data-placement="top" placement="bottom" mdbWavesEffect>
                      CH{{product.productId | slice:4:10}}
                    </a>

                    <a data-toggle="tooltip" data-placement="top" (click)="fetchDetailss(product)"
                      mdbTooltip="Quick Look" placement="bottom" mdbWavesEffect>
                      <mdb-icon far icon="eye" class=" ml-2"></mdb-icon>
                    </a>

                    <a *ngIf="!isLoggedIn" data-toggle="tooltip" data-placement="top" mdbTooltip="Add to Cart"
                      (click)="loginComponentFunction()" placement="bottom" mdbWavesEffect>
                      <mdb-icon fas icon="shopping-cart" class="grey-text ml-2"></mdb-icon>
                    </a>
                    <a *ngIf="isLoggedIn && product.presentInCart" data-toggle="tooltip" data-placement="top"
                      mdbTooltip="Add to Cart" (click)="addToCartProdCategory(product)" placement="bottom"
                      mdbWavesEffect>
                      <mdb-icon fas icon="shopping-cart" class="red-text ml-2"></mdb-icon>
                    </a>
                    <a *ngIf="isLoggedIn && !product.presentInCart" data-toggle="tooltip" data-placement="top"
                      mdbTooltip="Add to Cart" (click)="addToCartProdCategory(product, i)" placement="bottom"
                      mdbWavesEffect>
                      <mdb-icon fas icon="shopping-cart" id="shopCart"
                        [ngClass]="toggle2 && selectedIndexCart.indexOf(i) > -1 ? 'red-text ml-2' : 'grey-text ml-2'">
                      </mdb-icon>
                    </a>

                    <a class="" *ngIf="isLoggedIn && !product.presentWishlist" data-toggle="tooltip"
                      data-placement="top" (click)="addToWishList(product, i)" mdbTooltip="Add to Wishlist"
                      placement="bottom" mdbWavesEffect>
                      <mdb-icon fas icon="heart"
                        [ngClass]="toggle3 && selectedIndex.indexOf(i) > -1 ? 'red-text ml-2' : 'grey-text ml-2'">
                      </mdb-icon>
                    </a>


                    <a class="" *ngIf="product.presentWishlist && isLoggedIn" data-toggle="tooltip" data-placement="top"
                      (click)="addToWishList(product, i)" mdbTooltip="Add to Wishlist" placement="bottom"
                      mdbWavesEffect>
                      <mdb-icon fas icon="heart" class="red-text ml-2"></mdb-icon>
                    </a>

                    <a class="" *ngIf="!isLoggedIn" data-toggle="tooltip" data-placement="top"
                      (click)="loginComponentFunction()" mdbTooltip="Add to Wishlist" placement="bottom" mdbWavesEffect>
                      <mdb-icon fas icon="heart" class="grey-text ml-2"></mdb-icon>
                    </a>
                  </span>
                </div>
              </mdb-card-body>
              <!--/.Card content-->
            </mdb-card>

          </div>
        </div>
      </div>
      <!--End for Mobile-->



      <div class="col-12 col-md-9 d-lg-block d-md-block d-none">

        <div class="row" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
          (window:scroll)="onScroll($event)">

          <div class="card-block text-center" *ngIf="!itemsToShow?.length">             
              <img src="./assets/img/noproduct.png" class="img-fluid flex-center" alt="">              
            <div class="btn-group my-1" role="group">
              <button type="button" class="btn btn-primary btn-rounded" (click)="fetchBack()"><i
                  class="fas fa-angle-left mr-1"></i>Continue
                Shopping</button>
            </div>
          </div>

          <mdb-spinner spinnerColor="white" *ngIf="loading" id="spinnerloading" spinnerType="small"></mdb-spinner>
          <div class="col-md-3 mb-4 " *ngFor="let product of itemsToShow; let i= index">
            <div *ngIf="product.currentStock === 0">
              <mdb-badge danger="true" style="position: absolute;margin-top: 20px;
                  left: 10px;z-index: 3;">OUT
                OF STOCK</mdb-badge>
            </div>

            <!--Card-->
            <mdb-card cascade="true" wider="true">
              <!--Card image-->
              <div class="view view-cascade overlay waves-light zoom"
                style="display: flex; justify-content: center; align-items: center;" mdbWavesEffect>
                <a>
                  <!--<details-upload [fileUpload]='product.imageFilePath' (click)="fetchDetailss(product)"></details-upload>-->
                  <img src="{{product.imageFilePath}}" alt="image" [style.width.px]='imageWidthDesk'
                    [style.margin.px]='imageMargin' (click)="fetchDetailss(product)">
                </a>
              </div>
              <!--/Card image-->

              <mdb-card-body cascade="true">
                <!--Card content-->
                <h6 class="card-title text-muted font-weight-normal">
                  <!-- <p class="text-primary">CH{{product.productId | slice:0:3}}{{product.productId | slice:4:10}} &nbsp; </p>  -->
                  {{product.productName}} &nbsp;
                </h6>



                <ul class="list-inline text-nowrap">
                  <li class="list-inline-item font-weight-bold">
                    <h6 class="font-weight-bolder">{{product.productCost | currency:'INR': 'symbol' : '1.0-0'}}</h6>
                  </li>
                  <li class="list-inline-item text-muted"><del>{{product.discountCost | currency:'INR' : 'symbol' :
                      '1.0-0'}}</del></li>
                  <li class="list-inline-item green-text small">{{product.discountPercent}}% off</li>
                </ul>

                <hr>
                <div class="clearfix">
                  <span class="float-right">

                    <!-- <p class="text-primary 6rem;">CH{{product.productId | slice:0:3}}{{product.productId | slice:4:10}} &nbsp; </p>  -->

                    <a class="" data-toggle="tooltip" data-placement="top" placement="bottom" mdbWavesEffect>
                      CH{{product.productId | slice:4:10}}
                    </a>

                    <a class="" data-toggle="tooltip" data-placement="top" (click)="fetchDetailss(product)"
                      mdbTooltip="Quick Look" placement="bottom" mdbWavesEffect>
                      <mdb-icon far icon="eye" class=" ml-3"></mdb-icon>
                    </a>

                    <a *ngIf="!isLoggedIn" data-toggle="tooltip" data-placement="top" mdbTooltip="Add to Cart"
                      (click)="loginComponentFunction()" placement="bottom" mdbWavesEffect>
                      <mdb-icon fas icon="shopping-cart" class="grey-text ml-2"></mdb-icon>
                    </a>
                    <a *ngIf="isLoggedIn && product.presentInCart" data-toggle="tooltip" data-placement="top"
                      mdbTooltip="Add to Cart" (click)="addToCartProdCategory(product)" placement="bottom"
                      mdbWavesEffect>
                      <mdb-icon fas icon="shopping-cart" class="red-text ml-2"></mdb-icon>
                    </a>
                    <a *ngIf="isLoggedIn && !product.presentInCart" data-toggle="tooltip" data-placement="top"
                      mdbTooltip="Add to Cart" (click)="addToCartProdCategory(product, i)" placement="bottom"
                      mdbWavesEffect>
                      <mdb-icon fas icon="shopping-cart" id="shopCart"
                        [ngClass]="toggle2 && selectedIndexCart.indexOf(i) > -1 ? 'red-text ml-2' : 'grey-text ml-2'">
                      </mdb-icon>
                    </a>

                    <a class="" *ngIf="isLoggedIn && !product.presentWishlist" data-toggle="tooltip"
                      data-placement="top" (click)="addToWishList(product, i)" mdbTooltip="Add to Wishlist"
                      placement="bottom" mdbWavesEffect>
                      <mdb-icon fas icon="heart"
                        [ngClass]="toggle3 && selectedIndex.indexOf(i) > -1 ? 'red-text ml-2' : 'grey-text ml-2'">
                      </mdb-icon>
                    </a>


                    <a class="" *ngIf="product.presentWishlist && isLoggedIn" data-toggle="tooltip" data-placement="top"
                      (click)="addToWishList(product, i)" mdbTooltip="Add to Wishlist" placement="bottom"
                      mdbWavesEffect>
                      <mdb-icon fas icon="heart" class="red-text ml-2"></mdb-icon>
                    </a>

                    <a class="" *ngIf="!isLoggedIn" data-toggle="tooltip" data-placement="top"
                      (click)="loginComponentFunction()" mdbTooltip="Add to Wishlist" placement="bottom" mdbWavesEffect>
                      <mdb-icon fas icon="heart" class="grey-text ml-2"></mdb-icon>
                    </a>
                  </span>
                </div>
              </mdb-card-body>
              <!--/.Card content-->
            </mdb-card>

          </div>
        </div>
      </div>

    </div>
  </div>

  <br><br>

  <div class="fixed-action-btn d-none d-sm-block" style="bottom: 45px; right: 24px;">
    <button class="btn btn-floating btn-large red waves-light" mdbPageScroll href="#top-section" mdbWavesEffect
      style="right:80px; bottom:45px;" [pageScrollDuration]="750">
      <i class="fas fa-arrow-up"></i>
    </button>
  </div>



  <div style="overflow: auto" mdbModal #filterby="mdbModal" class="modal fade right" tabindex="-1" role="dialog"
    aria-labelledby="myBasicModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close pull-right" aria-label="Close" (click)="filterby.hide()">
            <span aria-hidden="true">X</span>
          </button>
          <h4 class="modal-title w-100" id="myModalLabel">Filters</h4>
        </div>
        <div class="modal-body">
          <mdb-accordion [multiple]="true">

            <mdb-accordion-item [collapsed]="true">
              <mdb-accordion-item-head>Price Range</mdb-accordion-item-head>
              <mdb-accordion-item-body>
                <mdb-multi-range-input class="pt-4 pb-5" id="Popmulti-range" step="500" min="500" max="25000"
                  (rangeValueChange)="onRangeValueChangePopup($event)" [value]="{ first: 500, second: 25000 }">
                </mdb-multi-range-input>
              </mdb-accordion-item-body>
            </mdb-accordion-item>

            <mdb-accordion-item [collapsed]="false">
              <mdb-accordion-item-head>Discount</mdb-accordion-item-head>
              <mdb-accordion-item-body>
                <mdb-checkbox [(ngModel)]="popmarkedBelow25" (change)="toggleVisibilityBelow25pop($event)">Below 25%
                  Discount</mdb-checkbox>
                <mdb-checkbox [(ngModel)]="popmarkedUpto25" (change)="toggleVisibilityUpto25pop($event)">Upto 25%
                  Discount</mdb-checkbox>
                <mdb-checkbox [(ngModel)]="popmarkedAbove30" (change)="toggleVisibilityAbove30pop($event)">Above 30%
                  Discount</mdb-checkbox>
              </mdb-accordion-item-body>
            </mdb-accordion-item>


            <mdb-accordion-item [collapsed]="false">
              <mdb-accordion-item-head>Color</mdb-accordion-item-head>
              <mdb-accordion-item-body>
                <button type="button" size="sm" mdbBtn color="primary" mdbTooltip="Blue" mdbWavesEffect
                  (click)="fetchColorFilter('Blue')"></button>
                <button type="button" size="sm" mdbBtn color="secondary" mdbTooltip="Purple" mdbWavesEffect
                  (click)="fetchColorFilter('Purple')"></button>
                <button type="button" size="sm" mdbBtn color="success" mdbTooltip="Green" mdbWavesEffect
                  (click)="fetchColorFilter('Green')"></button>
                <button type="button" size="sm" mdbBtn color="danger" mdbTooltip="Red" mdbWavesEffect
                  (click)="fetchColorFilter('Red')"></button>
                <button type="button" size="sm" mdbBtn color="yellow" mdbTooltip="Yellow" mdbWavesEffect
                  (click)="fetchColorFilter('Yellow')"></button>
                <button type="button" size="sm" mdbBtn color="dark" mdbTooltip="Black" mdbWavesEffect
                  (click)="fetchColorFilter('Black')"></button>
                <button type="button" size="sm" mdbBtn color="light" mdbTooltip="White" mdbWavesEffect
                  (click)="fetchColorFilter('White')"></button>
                <button type="button" size="sm" mdbBtn color="orange" mdbTooltip="Orange" mdbWavesEffect
                  (click)="fetchColorFilter('Orange')"></button>
                <button type="button" size="sm" mdbBtn color="pink" mdbTooltip="Pink" mdbWavesEffect
                  (click)="fetchColorFilter('Pink')"></button>
                <button mdbBtn type="button" size="sm" gradient="aqua" mdbTooltip="Multi Color" mdbWavesEffect
                  (click)="fetchColorFilter('Multi Color')"></button>

                <button mdbBtn type="button" size="sm" color="default" mdbTooltip="All Colors" rounded="true"
                  outline="true" mdbWavesEffect (click)="fetchColorFilter('All')">All</button>
              </mdb-accordion-item-body>
            </mdb-accordion-item>

          </mdb-accordion>



        </div>
        <div class="modal-footer justify-content-center">
          <button type="button" mdbBtn color="secondary" class="waves-light" aria-label="Close"
            (click)="filterby.hide()" mdbWavesEffect>Close</button>
          <button type="button" (click)="onFilterChangePopup(); filterby.hide()" mdbBtn color="primary"
            class="relative waves-light" mdbWavesEffect>OK!</button>
        </div>
      </div>
    </div>
  </div>




  <div mdbModal #sortby="mdbModal" class="modal fade bottom" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
    aria-hidden="true" [config]="{backdrop: 'static'}">
    <div class="modal-dialog modal-fluid modal-frame modal-bottom" role="document">
      <!--Content-->
      <div class="modal-content">
        <!--Header-->
        <div class="modal-header">
          <p class="heading lead">SORT BY</p>

          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="sortby.hide()">
            <span aria-hidden="true" class="grey-text">&times;</span>
          </button>
        </div>

        <!--Body-->
        <div class="modal-body">
          <div class="">
            <div class="form-check mb-4">
              <input class="form-check-input" [(ngModel)]="PopshortBy" name="Popgroup1" type="radio" id="Popradio-279"
                value="popularity" mdbInput>
              <label class="form-check-label" for="Popradio-279">Popularity</label>
            </div>

            <div class="form-check mb-4">
              <input class="form-check-input" [(ngModel)]="PopshortBy" name="Popgroup1" type="radio" id="Popradio-379"
                value="lowtohigh" mdbInput>
              <label class="form-check-label" for="Popradio-379">Price-- Low to High</label>
            </div>
            <div class="form-check mb-4">
              <input class="form-check-input" [(ngModel)]="PopshortBy" name="Popgroup1" type="radio" id="Popradio-479"
                value="hightolow" mdbInput>
              <label class="form-check-label" for="Popradio-479">Price-- High to Low</label>
            </div>
            <div class="form-check mb-4">
              <input class="form-check-input" [(ngModel)]="PopshortBy" name="Popgroup1" type="radio" id="Popradio-579"
                value="newestfirst" mdbInput>
              <label class="form-check-label" for="Popradio-579">Newest First</label>
            </div>
          </div>
        </div>


        <!--Footer-->
        <div class="modal-footer justify-content-center">
          <a type="button" mdbBtn color="primary" outline="true" class="waves-effect" mdbWavesEffect
            (click)="sortby.hide()" data-dismiss="modal">No, thanks</a>

          <a type="button" (click)="onItemChangePopup(); sortby.hide()" mdbBtn color="primary" class="waves-light"
            mdbWavesEffect>Ok
          </a>

        </div>
      </div>
      <!--/.Content-->
    </div>
  </div>

  <hr class="my-5">

</main>

<router-outlet></router-outlet>